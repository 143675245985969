
.default{
    margin: 16px 0;
    height:100%;
    overflow:'auto';

    .login-mainbox{
        padding: 0;
        margin: 0; 
        position: relative;
    }
    .login-pic{
        width: 100%;
        text-align:center;
        padding: 14px 30px 30px;
    }
    .login-line{
        padding: 0 30px;
        min-height: 48px;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    .login-subline{
        display: block;
        margin-bottom: 4px;
        padding-bottom: 0;
        padding-right: 16px;
        position: relative;
        width: 100%;   
    }    
    .login-underline {
        width: 100%;
        position: relative;
        margin-bottom: 4px;
        min-height: 36px;
        border-bottom: 1px solid rgba(0,0,0,.12);
        display: inline-flex;        
    }    
    .login-input {
        border: none; 
        padding: 0;
        margin-left: 20px;
        height: 36px; 
        font-size: 14px;        
    }   
    .login-button{
        background-color: var(--theme-color);
        margin-top: 45px;
        height: 40px;
        border-radius: 8px;
        line-height: 40px;
        color: #fff;
        text-align: center;        
    }
    .login-trial{
        position: absolute;
        margin-left: -74px;
        margin-top: -20px;
        color: #fff;
        font-size: 30px;
        z-index:'1';
        width: 48px;
        height: 48px;
        border-radius: 24px;
        line-height: 45px;
        background-color: var(--theme-color)        
    }
    .login-icon {
        font-size:27px;
        margin-top: 8px;  
        margin-left: 10px;      
    }
    .login-code {
        font-size:27px;
        margin-left: 10px;      
    }
}  

.royal{
    height:100%;
    overflow:'auto';
    background-color: var(--theme-color);

    .login-mainbox{
        border-radius: 15px;
        position: absolute;
        top: 380px;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px 10px 10px 10px;
        background-color: white;
        width: 93%
    }
    .login-pic{
        width: 100%;
        text-align:center;
        padding: 14px 30px 30px;
    }
    .login-line{
        padding: 0 30px;
        min-height: 48px;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    .login-subline{
        display: block;
        margin-bottom: 4px;
        padding-bottom: 0;
        padding-right: 16px;
        position: relative;
        width: 100%;   
        background-color: #fef3d3;
        border-radius: 8px;
    }
    .login-underline {
        width: 100%;
        position: relative;
        margin-bottom: 4px;
        min-height: 42px;
        display: inline-flex;        
    } 
    .login-input {
        border: none; 
        padding: 0;
        margin-left: 20px;
        height: 42px; 
        font-size: 14px;        
        background-color: #fef3d3;
    }   
    .login-button{
        background-color: rgb(225 187 88);
        height: 50px;
        border-radius: 30px;
        line-height: 50px;
        color: #fff;
        text-align: center;        
    }
    .login-trial{
        position: absolute;
        margin-left: -74px;
        margin-top: -20px;
        color: #fff;
        font-size: 30px;
        z-index:'1';
        width: 48px;
        height: 48px;
        border-radius: 24px;
        line-height: 45px;
        background-color: rgb(225 187 88);      
    }
    .login-icon {
        font-size:27px;
        margin-top: 8px;        
        margin-left: 10px;      
    }
    .login-code {
        font-size:27px;
        margin-left: 10px;      
    }
}  