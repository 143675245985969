.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(255, 255, 255, .23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.btn-outline-secondary {
  color: rgb(241, 235, 233);
  border-color: rgb(241, 235, 233);
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  filter: brightness(85%);
}

.cr-sidebar--open {
  -webkit-transition: left 0.2s ease-in;
  transition: left 0.2s ease-in;
}

.cr-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -260px !important;
  z-index: 5;
  width: 260px !important;
  font-weight: 200;
  color: #fff;
  background-position: center center;
  background-size: cover;
  -webkit-transition: left 0.1s ease-in;
  transition: left 0.1s ease-in;
}

.cr-overlay{
  position: fixed;
  top: 0;
  left:0;
  background-color: rgba(0,0,0,.4);
  opacity: 0;
  transition: all 0.2s;
  width:100vw;
  height:100vh;
  z-index: 4;
  display: none;
}

.cr-overlay--open{
  opacity: 1;
  transition: all 0.2s;
  display: block;
}

.overflow-lock{
  overflow: hidden;
}

@media screen and (max-width: 767px){
  .cr-sidebar--open + .cr-content {
    margin: auto !important;
    -webkit-transition: margin 0.2s ease-in;
    transition: margin 0.2s ease-in;
  }
}

.game-nav{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  height: 44px;
  //z-index: 998;
}

.game-nav .left{
  position: absolute;
  line-height: 44px;
  z-index: 1;
}

.game-nav .center{
  position: absolute;
  line-height: 44px;
  width: 100%;
  justify-content: center;
  display: flex;
  height: 44px;
}

.game-nav .right{
  color: white;
  position: absolute;
  right: 0px;
  justify-content: center;
  display: flex;
  text-align: center;
  font-size: 11px;
  line-height: 11px;
  z-index: 1;
}

.award-period{
  background-color: white;
  margin:auto;
  z-index: 1;
  position: inherit;
  width: 100%;
  top: 94px;
  //top: 74px;
}

.award-period .open-award-list-num .award-list-num b{
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  margin: 0 2px;
}

.award-period .open-award-list-num .award-list-num .fifth-col{
  width:36px;
}

.award-period .open-award-list-num .lhc-text{

}

.award-period .open-award-list-num .award-list-num b .lhc-num{
  width:100%;
  text-align:center;
  border-radius: 50px;
  color:white;
}

.lhc-ball01,.lhc-ball02,.lhc-ball07,.lhc-ball08,.lhc-ball12,.lhc-ball13,.lhc-ball18,.lhc-ball19,.lhc-ball23,.lhc-ball24,.lhc-ball29,.lhc-ball30,.lhc-ball34,.lhc-ball35,.lhc-ball40,.lhc-ball45,.lhc-ball46 {
  background-color:#f44336;
}
.lhc-ball03,.lhc-ball04,.lhc-ball09,.lhc-ball10,.lhc-ball14,.lhc-ball15,.lhc-ball20,.lhc-ball25,.lhc-ball26,.lhc-ball31,.lhc-ball36,.lhc-ball37,.lhc-ball41,.lhc-ball42,.lhc-ball47,.lhc-ball48 {
  background-color:#2196f3;
}
.lhc-ball05,.lhc-ball06,.lhc-ball11,.lhc-ball16,.lhc-ball17,.lhc-ball21,.lhc-ball22,.lhc-ball27,.lhc-ball28,.lhc-ball32,.lhc-ball33,.lhc-ball38,.lhc-ball39,.lhc-ball43,.lhc-ball44,.lhc-ball49 {
  background-color:#4caf50;
}

.game-container{
  width: 100%;
  overflow: hidden;
}

// .game-container.LHC-en,
// .game-container.LHC-vi,
// .game-container.LHC-th,
// .game-container.LHC-zh{
//   margin-top: 75px;
// }

.col-20{
  width: 20%;
  float: left;
}

.col-25{
  width: 25%;
  float: left;
}

.col-33{
  width: 33%;
  float: left;
}

.col-40{
  width: 40%;
  float: left;
}

.col-50{
  width: 50%;
  float: left;
}

.col-60{
  width: 60%;
  float: left;
}


.col-100{
  float: left;
  width: 100%;
}

.game-container .ball{
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 17px;
  font-size: 15px;
  color: #2477b9;
  text-align: center;
  line-height: 32px;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  position: relative;
  bottom: 0;
  margin: 0 auto;
}

.ball.dds{
  padding: 0 10px;
  width: auto;
}

.kw3-list{
  padding: 15px !important;
  background: white;
}

.kw3-list .col-25 , .kw3-list .col-60, .kw3-list .col-16{
  padding: 0 5px;
}

.kw3-list .col-60{
  margin: 5px auto;
  font-size: 20px;
  padding: 5px 0;
  width: 60%;
  width: -webkit-calc((100% - 16px*.6666666666666667)/ 1.6666666666666667);
  width: calc((100% - 16px*.6666666666666667)/ 1.6666666666666667);
  float:none;
}

.col-33{
  float: left;
  width: 33.333333333333336%;
  width: -webkit-calc(100% / 3);
  width: calc(100% / 3);
}

.kw3-list .col-16{
  float: left;
  width: 16.6666667%;
  width: -webkit-calc(100% / 6);
  width: calc(100%/ 6);
}

.kw3-list .square{
  width: 100%;
  overflow: hidden;
  padding: 2px 0;
  font-size: 18px;
  line-height: 1.4;
  color: #2477b9;
  vertical-align: top;
  display: inline-block;
  margin: 5px 0 0;
  text-align: center;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 3px;
}

.kw3-list .col-60 .square{
  padding: 5px 0;
  margin: 5px 0;
  font-size: 20px;
}

.kw3-list .square.active{
  color: #000000;
}

.kw3-list .square .tip{
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  display: block;
}

.kw3-list .square.active .tip{
  color: #000000;
}

.game-container .ball.active{
  color: #000000;
}

.bg-red {
  background-color: #F74F0A !important;
}

.resultStyle{
  position: fixed;
  z-index: 9999;
  background: white;
  width: 100%;
  top:44px;
}

.leftBut{
  border-radius: 36px 0 0 36px;
  background-color:#ff5722;
  color: white;
  border-style: solid;
  width:100%;
}

.rightBut{
  color: #ff5722;
  border-radius: 0 36px 36px 0;
  background-color:white;
  border-style: solid;
  border-width:1px;
  width:100%;
}

.button-row{
  display: -webkit-box;
}

.ball-list{
  border-bottom: 1px solid #dfdfda;
  padding-top: 15px;
  background: white;
}

//.ball-list:nth-of-type(2){
//  border-top: none;
//}

.position-list {
  background: #eeebda;
  border: 1px solid #e3decf;
  padding: 0 10px 5px;
  margin-bottom: 10px;
}

.position-list .buttons-row {
  margin-top: 5px;
}

.buttons-row {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.position-list .button {
  text-align: center;
  display: block;
  border-radius: 2px;
  box-sizing: border-box;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  padding: 0 8px;
  position: relative;
  overflow: hidden;
  outline: 0;
  border: none;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  min-width: 28px;
  height: 24px;
  line-height: 24px;
  width: 100%;
  -webkit-box-flex: 1;
  margin-left: 16px;
  color: #f44336 !important;
}

.buttons-row .button{
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  background: #fff;
  color: #f44336;
  width: 100%;
  text-align: center;
  display: block;
  border-radius: 6px;
  line-height: 36px;
  margin: 0;
  margin-left: 10px;
  height: 36px;
  font-size: 14px;
  position: relative;
}

.button-raised {
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  height: auto !important;
  line-height: 24px !important;
}

.button-raised.active{
  background: #f44336 !important;
  color: white !important;
}

.buttons-row .button:first-child{
  margin-left:0px !important;
}

.lines{
  display: block;
  color: #999;
  margin-top: 5px;
  font-size: 12px;
}

.ball-title {
  float: left;
  font-size: 14px;
  width:70px;
  position: relative;
}

.ball-title span {
  color: #ffffff;
  border-radius: 4px;
  padding: 5px 0px 5px 5px;
  max-width: 60px;
}

.ball-title span.active{
  background: #ff8521;
}

.ball-title em {
  border: 12px solid transparent;
  border-left-color: #e1e1e1;
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: top;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 13.2px solid transparent;
  border-bottom: 13.2px solid transparent;
  border-left: 13.2px solid var(--theme-color);
  display: inline-block;
  transition: none !important;
}

.arrow-right.active{
  border-top: 13.2px solid #ff8521;
  border-bottom: 13.2px solid #ff8521;
  border-left: 13.2px solid white;
}

.arrow-right.small{
  //border-top: 10.2px solid transparent;
  //border-bottom: 10.2px solid transparent;
  //border-left: 10.2px solid #ff8521;
  //top: -3px;
  //left: -10.2px;
  //position: relative;
  //opacity: 0;
  border-top: 10.2px solid transparent;
  border-bottom: 10.2px solid transparent;
  border-left: 10.2px solid #ff8521;
  top: 3px;
  right: 1px;
  opacity: 0;
  position: absolute;
}

.arrow-right.small.active{
  opacity: 1;
}

.ball-row{
  float: left;
  width: 100%;
}

.ball-title~.ball-row{
  width: calc(100% - 70px);
}

.open-award-list-num{
  text-align:center;
  white-space: nowrap;
}

.open-award-list-num-wrap{
  text-align:center;
}

.open-award-list-text {
  font-size: 12px;
  margin-top: -1px;
}

.open-award-list-text span {
  display: inline-block;
  width: 19px;
  text-align: center;
  margin-right: 3px;
  font-size: 12px;
}

.award-lhc{
  margin-top: -3px;
}

.award-kl8 .ball{
  width: 14px;
  height: 14px;
  line-height: 14px;
  font-size: 10px;
  margin: 0 0 2px;
}

.open-award-list-num .red-ball, .open-award-list-num .red-ball-em, .open-award-list-num .blue-ball, .open-award-list-num .blue-ball-em{
  width: 21px;
  height: 21px;
  border-radius: 12px;
  line-height: 21px;
  font-size: 13px;
  margin: 2px 0 0 3px;
}

.open-award-list-num .Dice{
  width: 26px;
  height: 26px;
}

.red-ball, .blue-ball, .green-ball {
  display: inline-block;
  background: #f44336;
  color: #fff;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  text-align: center;
  line-height: 28px;
  font-style: normal;
  margin: 4px 5px 0 0;
  font-size: 16px;
}

.blue-ball{
  background: #2196f3;
}

.green-ball{

}

.red-text-small {
  display: inline-block;
  color: #f44336;
  width: 20px;
  height: 20px;
  text-align: center;
  font-style: normal;
  font-size: 13px;
}

.red-ball-small-header {
  display: inline-block;
  background: #f44336;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 14px;
  text-align: center;
  line-height: 18px;
  font-style: normal;
  margin: 4px 5px 0 0;
  font-size: 14px;
}

.react-tabs__tab-list{
  border-bottom: none;
}
.react-tabs__tab{
  padding: 0 10px;
  font-size: 13px;
  background-color: transparent;
  border: none;
  color: rgba(255,255,255,.7);
  bottom: 0;
  line-height: 28px;
}
.react-tabs__tab--selected{
  border-bottom: 2px solid rgba(255,255,255,.7);
  color: rgba(255,255,255,1);
}

.react-tabs__tab:focus{
  border-bottom: 2px solid rgba(255, 255, 255, 0.7);
  box-shadow: none;
}

.react-tabs__tab:focus:after{
  height:0;
}

.my-collapsible__content-inner h3 {
  font-size: 14px;
  margin: 0 0;
  font-weight: normal;
  clear: both;
  padding: 10px 10px 0;
  border-top: 1px solid #dfdfdf;
  width: auto;
}

.my-collapsible__content-inner h3 img{
  width:20px;
  height:20px;
  border-radius:100%;
  margin-right:5px
}

.change-game-list{
  padding:0 10px;
  display: flex;
  flex-wrap: wrap;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}

.my-collapsible__content-inner .change-game-list a {
  background: #eee;
  line-height: 1.5;
  border-radius: 16px;
  border: 1px solid transparent;
  margin-bottom: 10px;
  color: #212121;
  font-size: 13px;
  display: flex;
  width: calc(33.333333333333% - 5px);
  float: left;
  text-align: center;
  padding: 5px 5px;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.my-collapsible__content-inner .change-game-list a:hover,.my-collapsible__content-inner .change-game-list a.active{
  color: var(--theme-color) !important;
  border: 1px solid var(--theme-color);
  text-decoration: none;
  background: white;
}

.change-game-text{
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: auto;
}

.content-block {
  margin: 16px 0;
}

.content-block .buttons-row {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  -ms-flex-wrap: nowrap;

}


.purlist-popup .buttons-row .button.button-round:first-child, .pop-menu .buttons-row .button.button-round:first-child {
  border-radius: 36px 0 0 36px;
}

.button-round{
  text-align: center;
  height: 32px;
  line-height: 32px;
  width:100%;
  font-size: 14px;
}

.button-round:first-child{
  border-radius: 36px 0 0 36px;
  border-width: 1px;
  border-style: solid;
}

.button-round:last-child{
  border-radius: 0 36px 36px 0;
  border-width: 1px;
  border-style: solid;
}

.button-round.active{
  color: #fff !important;
  border-width: 0 !important;
}

.playType{
  overflow-y: auto;
  max-height: calc(100vh - 108px);
}

.methodhelp{
  color: #999;
  font-size: 11px;
  padding: 10px 10px;
  background: white;
  margin: 5px 0;
  display:flex;
}

.help-content,.help-btn{
  height: 12px;
  line-height: 12px;
}

.help-content.hide{
  white-space: nowrap;
  width: calc(100% - 35px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.help-content.show{
  width: calc(100% - 35px);
  text-overflow: ellipsis;
  display:inline-table;
}

.help-btn{
  min-width: 35px;
  overflow: hidden;
  margin-top: auto;
}

.resultStyle ul{
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}

.resultStyle ul li{
  line-height: 1.5;
  margin-bottom: 10px;
  color: #212121;
  font-size: 13px;
  display: flex;
  width: calc(33.333333333333% - 5px);
  float: left;
  text-align: center;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.resultStyle ul li button{
  background: #fff;
  border-radius: 4px;
  min-height: 30px;
  line-height: 20px;
  display: block;
  margin: 0 10px 10px 0;
  text-align: center;
  font-size: 13px;
  border: 1px solid #e1e1e1;
  color: #666;
  width: 90%;
}

.resultStyle ul li.active a{
  border: 1px solid #ff5722;
  color: #ff5722;
}

.pop-menu-line{
  border-top: 1px solid #e1e1e1;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  margin-top: 10px;
}

.pop-menu-line span{
  background: #fff;
  color: #333;
  padding: 0 10px;
  display: inline-block;
  line-height: 1;
  position: absolute;
  top: -8px;
  left: 50%;
  margin-left: -38px;
}

.pop-menu {
	position: static;
    top: 44px;
    width: 100%;
    z-index:9999999999;
    font-size: 14px;
	background: #fff;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
.pop-menu ul {
	margin: 10px 0 0 10px;
	padding: 0;
	clear: both;
	/*height: 100%;*/
	overflow: hidden;
}
.pop-menu ul li {
	margin: 0;
	padding: 0;
	list-style: none;
	float: left;
	width: 25%
}
.pop-menu1 ul li ,.pop-menu ul.w33 li {
	width: 33.33%
}
.pop-menu1 ul li ,.pop-menu ul.w50 li {
	width: 50%
}

.pop-menu ul li a {
	background: #fff;
	border: 1px solid #e1e1e1;
	border-radius: 4px;
	height: 30px;
	line-height: 30px;
	display: block;
	margin: 0 10px 10px 0;
	text-align: center;
	color: #666;
	font-size: 13px;
}

.pop-menu ul li.active a {
	border: 1px solid var(--theme-color);
	color: var(--theme-color);
}

.userface {
	color: #fff;
	margin: 0;
	background: rgb(255, 87, 34);
    filter: none;
}

.content-block{
  margin: 15px 0;
  padding: 0 16px;
  box-sizing: border-box;
  height: auto;
}

.DataTotal{
	padding: 16px 16px 48px 16px;
  background-size: 100% 100%;
  position: relative;
}
.DataTotal dl {
	margin:0;
	padding:0;
	text-align:center;
	line-height:1;
}
.DataTotal dl.col-4 {
	width:33.33%;
	float:left;
}
.DataTotal dl.col-6 {
	width:50%;
	float:left;
}
.DataTotal dl.col-border{
  border-left: 1px solid rgba(186, 186, 186, 0.3);
}
.DataTotal dt {
	margin:0;
	padding:0;
	font-size:34px;
}
.DataTotal-box dl input {
  border: none;
  text-align: center;
  margin: -2px;
  padding:0px;
  font-size: 18px;
  width: 100%;
  background: none;
  opacity:unset;
}
.DataTotal dd {
	margin:6px 0 0;
	padding:0;
	font-size:14px;
}
.DataTotal dd small{
	display:block;
	font-size:12px;
	margin:8px 0 0;
}

.DataTotal dd small2 {
    display: block;
    font-size: 12px;
    background: white;
    color: black;
    width: 80%;
    margin: auto;
    line-height: 1.5;
    border-radius: 5px;
    margin-top: 8px;
}

.DataTotal-tip {
	position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background:rgba(0, 0, 0, 0.1);
    font-size:12px;
}
.DataTotal-tip span {
	padding:4px 16px;
	display:block;
}
a#searchbarARCancel{
	background-image:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ3Ljk3MSA0Ny45NzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ3Ljk3MSA0Ny45NzE7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48ZyB0cmFuc2Zvcm09Im1hdHJpeCgwLjY4OTQ0OSAwIDAgMC42ODk0NDkgNy40NDg3MSA0LjAwMTQ3KSI+PGc+Cgk8cGF0aCBkPSJNMjguMjI4LDIzLjk4Nkw0Ny4wOTIsNS4xMjJjMS4xNzItMS4xNzEsMS4xNzItMy4wNzEsMC00LjI0MmMtMS4xNzItMS4xNzItMy4wNy0xLjE3Mi00LjI0MiwwTDIzLjk4NiwxOS43NDRMNS4xMjEsMC44OCAgIGMtMS4xNzItMS4xNzItMy4wNy0xLjE3Mi00LjI0MiwwYy0xLjE3MiwxLjE3MS0xLjE3MiwzLjA3MSwwLDQuMjQybDE4Ljg2NSwxOC44NjRMMC44NzksNDIuODVjLTEuMTcyLDEuMTcxLTEuMTcyLDMuMDcxLDAsNC4yNDIgICBDMS40NjUsNDcuNjc3LDIuMjMzLDQ3Ljk3LDMsNDcuOTdzMS41MzUtMC4yOTMsMi4xMjEtMC44NzlsMTguODY1LTE4Ljg2NEw0Mi44NSw0Ny4wOTFjMC41ODYsMC41ODYsMS4zNTQsMC44NzksMi4xMjEsMC44NzkgICBzMS41MzUtMC4yOTMsMi4xMjEtMC44NzljMS4xNzItMS4xNzEsMS4xNzItMy4wNzEsMC00LjI0MkwyOC4yMjgsMjMuOTg2eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImhvdmVyZWQtcGF0aCBhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIGZpbGw9IiM5OTk5OTkiLz4KPC9nPjwvZz4gPC9zdmc+Cg==');
}
input#searchbarARPlaceholder{
	background-image:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIGNsYXNzPSIiPjxnIHRyYW5zZm9ybT0ibWF0cml4KDAuODI2MTY5IDAgMCAwLjgyNjE2OSA0Ljk1MTIzIDAuODIwMzkxKSI+PHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCIgZmlsbD0iIzk5OTk5OSIvPjwvZz4gPC9zdmc+Cg==');
}
a#searchbarARCancel.white{
  background-size: 20px 20px;
  background-image:url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MS45MiA2MS45MiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5jbG9zZTwvdGl0bGU+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMzEsMzQuNDYsNTIuNTEsNTZhMi40NSwyLjQ1LDAsMCwwLDMuMzUsMCwyLjQsMi40LDAsMCwwLDAtMy40N0wzNC40MywzMSw1Niw5LjQ0QTIuNDUsMi40NSwwLDAsMCw1Mi41MSw2TDMxLDI3LjUzLDkuNCw2QTIuMzksMi4zOSwwLDAsMCw1Ljk0LDZhMi4zOSwyLjM5LDAsMCwwLDAsMy40NkwyNy40OSwzMSw1Ljk0LDUyLjU1YTIuNCwyLjQsMCwwLDAsMCwzLjQ3LDIuNDMsMi40MywwLDAsMCwzLjM0LDBaIi8+PC9zdmc+');
}
input#searchbarARPlaceholder.white{
  background-size: 20px 20px;
  background-image:url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MS45MiA2MS45MiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5zZWFyY2g8L3RpdGxlPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTU5LjYsNTYuMTksNDIuNzYsMzkuMzRhMjMsMjMsMCwxLDAtMy40MiwzLjQyTDU2LjE5LDU5LjZhMS4yLDEuMiwwLDAsMCwxLjcxLDBsMS43LTEuN0ExLjIsMS4yLDAsMCwwLDU5LjYsNTYuMTlaTTI0LjkyLDQzQTE4LjEzLDE4LjEzLDAsMSwxLDQzLDI0LjkyLDE4LjE0LDE4LjE0LDAsMCwxLDI0LjkyLDQzWiIvPjwvc3ZnPg==');
}
input#searchbarARPlaceholder::placeholder{
	color:#999;
}
.DataTotal-sub {
	background: #fff;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
	margin:16px;
	padding:0;
	border-radius: 8px;
}
.DataTotal-sub h3 {
	padding:12px 16px;
	font-size:14px;
	border-bottom: 1px solid #f4f4f4;
	line-height:1;
	font-weight: bold;
}
.DataTotal-sub dl {
	margin:0;
	padding:0 0 32px;;
	text-align:center;
	line-height:1;
}
.DataTotal-sub dl.col-4 {
	width:33.33%;
	float:left;
}
.DataTotal-sub dl.col-3 {
	width:25%;
	float:left;
}
.DataTotal-sub dt {
	margin:0;
	padding:0;
	font-size:18px;
}
.DataTotal-sub dt i {
	font-size:40px;
}
.DataTotal-sub dd {
	margin:6px 0 0;
	padding:0;
	font-size:12px;
  color:#999;
  height: 30px;
}
.DataTotal-nav dd {
	margin: 4px 0 0;
}
.DataTotal-top {
	background: #fff;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
	margin:-32px 16px 16px;
	padding:12px 0;
	border-radius: 8px;
}
.DataTotal-top dl {
	margin:0;
	padding:0;;
	text-align:center;
	line-height:1;
	border-left: 1px solid #f4f4f4;
}
.DataTotal-top dl:last-child {
	border-left:none
}
.DataTotal-top dl.col-7 {
	width:25%;
	float:left;
}
.DataTotal-top dl.col-6 {
	width:49.5%;
	float:left;
}
.DataTotal-top dl.col-5 {
	width:33%;
	float:left;
}
.DataTotal-top dl.col-4 {
  width:33%;
  float:left;
  border:none;
}
.DataTotal-top dt {
	margin:0;
	padding:0;
	font-size:22px;
}
.DataTotal-top dd {
	margin:6px 0 0;
	padding:0;
	font-size:12px;
	color:#999
}
.DataTotal dl.DataTotal-l {
	width:48%;
}
.DataTotal dl.DataTotal-sm {
	opacity: 0.6;
	//width:26%;
	margin:12px 0 0;
}
.DataTotal-sm dt {
	font-size:18px;
}
.DataTotal-sm dd {
	font-size:12px;
	margin: 4px 0 0;
}
.DataTotal-btn {
	width:40%;
	border-radius: 18px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	margin:24px auto 0!important;
	background: -webkit-linear-gradient(#fff, #e5e5e5);
    background: -o-linear-gradient(#fff, #e5e5e5);
    background: -moz-linear-gradient(#fff, #e5e5e5);
    background: linear-gradient(#fff, #e5e5e5);
}
.DataTotal-sub table {
	width:100%;
	text-align:center;
	border-spacing: 0;
    border-collapse: collapse;
    color:#999;
    line-height:1;
}
.DataTotal-sub table th, .DataTotal-sub table td {
	border-right: 1px solid #f4f4f4;
	border-bottom: 1px solid #f4f4f4;
	padding:12px 0;
}
.DataTotal-sub table th {
	color:#ffffff;
}
.DataTotal-tab {
	float:right;
	font-size:12px;
	line-height:38px;
	margin:-12px -16px 0 0;
}
.DataTotal-tab b {
	padding:0 10px;
	display:inline-block;
}
.DataTotal-tab a {
	color:#999;
	font-weight: normal;
	margin:0;
	padding:0 10px;
	display:inline-block;
}
.DataTotal-tab a.m-active {
	color: #f44336;
	font-weight:bold;
}
.DataTotal-tab a.r-active {
	color: #2196f3;
	font-weight:bold;
}

.red {
	color: #f44336;
}
.green{
	color:#4caf50;
}
.blue {
	color: #2196f3;
}
.orange {
	color: #ff9800;
}
.teal {
	color: #009688;
}
.gray {
	color: #999!important;
}

  .time-tab a {
    flex: 1;
    text-align: center;
    border-radius: 50px;
    background-color: white;
    margin-right: 10px;
  }
  .time-tab a:last-child {
    margin-right: 0;
  }

.DataTotal-card {
	background: #fff;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
	border-radius: 8px;
}
.DataTotal-card .list-block {
	border-radius: 8px;
}
.DataTotal-card .list-block .item-inner:after {
	display:none;
}
.DataTotal-card .card-footer {
	min-height:36px;
}
.DataTotal-bottom {
	height:49px;
	background-color: #fff;
    border-top: 1px solid #e1e1e1;
    position: fixed;
    bottom: calc(0px + var(--safe-bottom));
    left:0;
    width: 100%;
    z-index: 999;
}
.DataTotal-bottom-l {
	padding:14px 10px;
	float:left;
  font-size: 13px;
}
.DataTotal-bottom-r {
	padding:14px 10px;
	float:right;
  font-size: 13px;
}

.gameCaption{
  margin: 10px 0 0 10px;
  padding: 0;
  clear: both;
  overflow: hidden;
}

.gameCaption li{
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  width:25%;
}

.modal-overlay{
  visibility: visible;
  opacity: 1;
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  height:100%;
  background: rgba(0,0,0,.4);
  z-index:99999999;
  top:44px;
}

.lotteryCart{
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-left: auto;
  line-height: 10px;
  text-align: center;
}

.lottery-cart-count{
  display: inline-block;
  width: 15px;
  height: 15px;
  background: #ff5722;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  position: absolute;
  right: 0px;
  top: 5px;
}

.resultBottomStyle{
  position: fixed;
  z-index: 9999999999;
  background: #fff;
  width: 100%;
  bottom:calc(0px + var(--safe-bottom));
  left: 0;
  //max-height:100%;
  overflow:auto;
}

.lotteryCart .modal{
  transition: 0s;
}

.actions-modal{
  position: relative;
}

.actions-modal-button{
  width:fit-content;
  height:28px;
  display: inline-block;
  text-align: center;
  line-height: 21px;
  border: 1px solid var(--theme-color);
  border-radius: 20%;
  color: var(--theme-color);;
  margin: 1px;
}

.actions-modal-button.active{
  color:#fff;
  background: var(--theme-color);
  border-radius: 20%;
}

button:focus,input:focus{
  outline:none !important;
  box-shadow: none !important;
}

.lottery-left {
  position: fixed;
  top: 162px;
  bottom: 48px;
  left: 0;
  display: block;
  padding: 0;
  z-index: 99;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  width: 70px;
  zoom: 1;
}

.lottery-left ul {
  margin: 0;
  padding: 0;
}

.lottery-left li {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: block;
  margin-right: -1px;
}

.lottery-left li a {
  display: block;
  position: relative;
  text-align: center;
  color: #212121;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  font-size: 14px;
}

.lottery-left li a i{
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background: #ddd;
  position: absolute;
  top: 5px;
  left: 5px;
}

.lottery-left li a i.beted {
  background: #f44336;
}

.lottery-left li.active a {
  background: #f4f4f4;
  color: #f44336 !important;
}

.lottery-right {
  margin-left: 70px;
  padding: 0 10px 10px;
}

.bet-title {
  text-align: center;
  margin-bottom: 5px;
  padding-top: 5px;
  font-size: 15px;
  line-height: 1.2;
  color: #212121;
  width: fit-content;
  border-bottom: 3px solid var(--theme-color);
}

.bet-title1 {
  text-align: center;
  font-size: 15px;
  line-height: 1.2;
  background: #fff;
  color: #000;
  border: 1px solid #e5e5e5;
  padding: 5px 0;
  border-radius: 4px;
  position: relative;
  margin: 5px;
  margin-top: 0px;
}

i.bet, i.beted {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background: #ddd;
}

.bet-title1 i.beted, .betdownmenu i.beted, .bet-title1 i.bet, .betdownmenu i.bet {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.bet-title1 i.beted{
  background: red;
}

.bet-list{
  padding: 0 15px;
  padding-bottom: 35px;
  background: white;
}

.bet-list div[class*="col-"] {
  background: #fff;
  text-align: center;
  color: #000;
  border: 1px solid #e5e5e5;
  padding: 7px 0;
  margin-bottom: 8px;
  border-radius: 10px;
  position:relative;
}

.bet-list div[class*="col-"].disabled{
  background: #ffff;
  color: #F2F1F6;
  cursor: not-allowed;
}

.bet-list .row{
  display: table;
  border-spacing: 4px;
  border-collapse: separate;
  width:100%;
  margin:0;
  position: relative;
  //margin:0;
  //justify-content: space-between;
}

.bet-list .row .col-20 {
  width: 20%;
  display: table-cell;
  float: none;
  //width: -webkit-calc((100% - 8px*4) / 5);
  //width: calc((100% - 8px*4) / 5);
}

.bet-list .row .col-25 {
  width: 25%;
  display: table-cell;
  float: none;
  //width: -webkit-calc((100% - 8px*3) / 4);
  //width: calc((100% - 8px*3) / 4);
}

.bet-list .row .col-33 {
  width: 33.333333333333336%;
  display: table-cell;
  float: none;
  //width: -webkit-calc((100% - 8px*2) / 3);
  //width: calc((100% - 8px*2) / 3);
}

.bet-list .row .col-50 {
  width: 50%;
  display: table-cell;
  float: none;
  //width: -webkit-calc((100% - 8px*1) / 2);
  //width: calc((100% - 7px*1) / 2);
}

.bet-list .row .col-60{
  width: 60%;
  display: table-cell;
  float: none;
  //width: -webkit-calc((100% - 16px*.6666666666666667)/ 1.6666666666666667);
  //width: calc((100% - 16px*.6666666666666667)/ 1.6666666666666667);
}

.bet-list .row .col-100 {
  width: 100%;
  display: table-cell;
  float: none;
}

.bet-list .bet-number {
  font-style: normal;
  display: block;
  font-size: 18px;
  padding: 2px 0px 2px;
  line-height: 1;
  color: #2477b9;
}

.bet-list .bet-number-disabled {
  font-style: normal;
  display: block;
  font-size: 18px;
  padding: 2px 0px 2px;
  line-height: 1;
  color: #F2F1F6 !important;
}

.bet-list .odds {
  color: #666;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  position: relative;
  text-align: center;
  width: 100%;
  left: 0;
}

.bet-list .odds-disabled {
  color: #F2F1F6 !important;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  left: 0;
}

.bet-list .bgodds{
  color: #666;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}

.bet-list .yscodds{
  color: #666;
  font-size: 12px;
  line-height: 1;
  display: grid;
}


@media screen and (max-width: 1000px) {
  .bet-list .bet-number{
    font-size: 15px;
  }
  .bet-list .bgodds{
    font-size: 11px;
    width:40px;
  }
  .bet-list .odds{
    font-size: 11px;
  }
  .bet-list .lhc-odds{
    font-size: 11px;
  }
}

.chipsBarBet {
  bottom: calc(90px + var(--safe-bottom));
  position: fixed;
  z-index: 11;
  height: 30px;
  border: none;
  width: 100%;
  border: none;
  background: hsla(210,4%,80%,.5);
  background-position: bottom;
  background-repeat: no-repeat;
  margin-left: -10px;
}

.chipsBarBet.show {
  display: block;
}

.chipsBarBet.hide {
  display: none;
}

.chipsBarBet.cartList {
  background: initial;
  border-top: 0px solid rgb(235, 235, 235);
  box-shadow: 0px -2px 2px rgb(235 235 235);
  -webkit-box-shadow: 0px -2px 2px rgb(235 235 235);
  -moz-box-shadow: 0px -2px 2px rgb(235 235 235);
}
.bar_chip_bet.cartList {
  bottom: initial;
  background: initial;
}

.bar_chip_bet {
  position: absolute;
  bottom: -2px;
  width: 100%;
  background: #ddd;
}

.bar_chip_bet ul {
  margin: 0;
  padding: 0;
}

.bar_chip_bet li {
  font-family: iconfont;
  width: 14%;
  height: 60px;
  display: inline-block;
  margin: 0;
  text-align: center;
  border-radius: 100%;
  position: relative;
  font-size: 46px;
  max-width: 14%;
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.chipsBarBet .bar_chip_bet li.cm0 {
  background-image: url(../assets/image/chips/cm0.png);
}

.chipsBarBet .bar_chip_bet li.cm1 {
  background-image: url(../assets/image/chips/cm1.png);
}

.chipsBarBet .bar_chip_bet li.cm2 {
  background-image: url(../assets/image/chips/cm2.png);
}

.chipsBarBet .bar_chip_bet li.cm3 {
  background-image: url(../assets/image/chips/cm3.png);
}

.chipsBarBet .bar_chip_bet li.cm4 {
  background-image: url(../assets/image/chips/cm4.png);
}

.chipsBarBet .bar_chip_bet li.cm5 {
  background-image: url(../assets/image/chips/cm5.png);
}

.chipsBarBet .bar_chip_bet li.cm6 {
  background-image: url(../assets/image/chips/cm6.png);
}

.chipsBarBet .bar_chip_bet li.cm6 {
  background-image: url(../assets/image/chips/cm0.png);
}

.chipsBarBet .bar_chip_bet a {
  display: block;
  color: #000 !important;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  left: 0px;
  line-height: 60px;
  width: 100%
}

.multiple-bar{
  background:rgba(0,0,0,0.5);
  position:absolute;
  top:-40px;
  width:100%;
  height:40px;
  border-bottom:1px solid #ccc;
  display:none;
  left:0;
}

.multiple-bar.active{
  color: #fff;
  background: #B8B8B8;
  border-color: var(--theme-color);
  display: block;
}

.multiple-bar-button{
  text-align:center;
  width:14%;
  margin:0 2px;
  font-size:12px;
  height:24px;
  line-height:24px;
  border:1px solid #ccc;
  border-radius:3px;
  background:#fff;
}

.multiple-bar-button.active{
  background: var(--theme-color);
  color: white!important;
}

.bet-modal , .cart-modal, .full-page-modal{
  margin:0;
  height:100vh;
}

.full-page-modal{
  overflow-y: hidden;
}


.bet-modal .modal-content{
  height: 100vh;
  border:0;
  background: white;
  border-radius: 0;
}

.cart-modal .modal-content{
  height: 100vh;
  border:0;
  //padding-bottom: 97px;
}

@media screen and (min-width: 800px){
  .bet-modal, .cart-modal{
    margin: auto;
    height: auto;
  }

  .bet-modal .modal-content, .cart-modal .modal-content{
    height: auto;
    border:0;
  }
}

@media screen and (min-width: 500px){
  .bet-modal{
    margin: auto;
    height: auto;
    max-width: 600px;
    margin: 7rem auto;
    margin-top: 7rem;
    margin-right: auto;
    margin-bottom: 7rem;
    margin-left: auto;
  }

  .bet-modal .modal-content{
    height: 70vh;
    border:0;
  }
}

.cart-button-list{
  padding: 15px 10px;
  margin: 0px;
  background-color: white;
  border-bottom: 1px solid rgb(225, 225, 225);
}

.cart-button-list .buttons-row .button{
  box-shadow: none;
  border: 1.5px solid var(--theme-color);
  line-height: 27px;
  height: 30px;
  font-size: 12px;
  border-radius: 5px;
}

.cart-button-list .buttons-row .button span{
  position:relative;
  left: -5px;
}

.cart-ul{
  list-style: none;
  padding: 0px;
  margin: 0px;
  position: relative;
  background: rgb(255, 255, 255);
}

.cart-ul li{
  border-bottom: 1px solid rgb(225, 225, 225);
}

.cr-sidebar--open{
  overflow: auto;
  right: 0 !important;
  box-shadow: 0 0 20px rgba(0,0,0,.5);
}

.rotate0 {
  transition: 0.5s;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate180 {
  transition: 0.5s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.lhc-odds{
  color: #666;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  left: 0;
}

.red-ball-em {
  display: inline-block;
  color: #f44336;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  text-align: center;
  line-height: 28px;
  font-style: normal;
  margin: 4px 5px 0 0;
  font-size: 16px;
  border: 1px solid #E1E1E1;
  margin-right: 5px;
}

.blue-ball-em {
  display: inline-block;
  color: #2196f3;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  text-align: center;
  line-height: 28px;
  font-style: normal;
  margin: 4px 5px 0 0;
  font-size: 16px;
  border: 1px solid #2196f3;
  margin-right: 5px;
}

.betball-lhc-red, .betball-lhc-blue, .betball-lhc-green {
  display: inline-block;
  text-align: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-right: 5px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  background-size: 32px;
  vertical-align: middle;
  border-radius: 16px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.16);
}

.betball-lhc-red{
  background-image: url("../assets/image/red-ball.png");
  background-repeat: no-repeat;
}

.betball-lhc-blue{
  background-image: url("../assets/image/blue-ball.png");
  background-repeat: no-repeat;
}

.betball-lhc-green{
  background-image: url("../assets/image/green-ball.png");
  background-repeat: no-repeat;
}

.dice {
  width: 35px;
  height: 36px;
  line-height: 0;
  display: inline-block;
  background: url("../assets/image/kuai3.png") no-repeat;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
  margin: 5px 10px 0 0;
}
.dice1 {
  background-position: -9px 0;
}
.dice2 {
  background-position: -64px 0;
}
.dice3 {
  background-position: -119px 0;
}
.dice4 {
  background-position: -9px -49px;
}
.dice5 {
  background-position: -64px -49px;
}
.dice6 {
  background-position: -119px -49px;
}

.Dice {
  background-image: url("../assets/image/diceK3.png");
  display: inline-block;
  background-size: 200% 600%;
  vertical-align: middle;
  height: 21px;
  width: 21px;
  text-indent: 1000px;
  overflow: hidden;
}

.Dice1{background-position:0 0.5%}
.Dice2{background-position:0 -100.2%}
.Dice3{background-position:0 -199.8%}
.Dice4{background-position:0 -299.5%}
.Dice5{background-position:0 -400%}
.Dice6{background-position:0 -499.7%}

.kw3-blue {
  background: rgb(23, 121, 255);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: 24px;
  color: rgb(255, 255, 255);
  text-align: center;
}

.kw3-red {
  background: rgb(255, 0, 0);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: 24px;
  color: rgb(255, 255, 255);
  text-align: center;
}

.racing {
  width: 26px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #ddd;
  display: inline-block;
  color: #fff;
  font-size: 26px;
  text-shadow: 0 1px 1px #000;
  font-style: normal;
  border-radius: 4px;
  margin:4px 2px 0 0;
}

.live-racing {
  width: 13px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #ddd;
  display: inline-block;
  color: #fff;
  font-size: 13px;
  text-shadow: 0 1px 1px #000;
  font-style: normal;
  border-radius: 4px;
  margin:4px 2px 0 0;
}

.r1 {
  background: #ffff02;
}
.r2 {
  background: #018aff;
}
.r3 {
  background: #4c4c4c;
}
.r4 {
  background: #ff7400;
}
.r5 {
  background: #82feff;
}
.r6 {
  background: #5204ff;
}
.r7 {
  background: #e4e4e4;
}
.r8 {
  background: #ff1201;
}
.r9 {
  background: #770400;
}
.r10 {
  background: #23c300;
  letter-spacing: -3px;
  text-indent: -3px;
}

.live-r1 {
  color: #ffff02;
  border-style:solid;
  border-width:2px;
  background-color: white;
}
.live-r2 {
  color: #018aff;
  border-style:solid;
  border-width:2px;
  background-color: white;  
}
.live-r3 {
  color: #4c4c4c;
  border-style:solid;
  border-width:2px;
  background-color: white;  
}
.live-r4 {
  color: #ff7400;
  border-style:solid;
  border-width:2px;
  background-color: white;  
}
.live-r5 {
  color: #82feff;
  border-style:solid;
  border-width:2px;
  background-color: white;  
}
.live-r6 {
  color: #5204ff;
  border-style:solid;
  border-width:2px;
  background-color: white;  
}
.live-r7 {
  color: #e4e4e4;
  border-style:solid;
  border-width:2px;
  background-color: white;  
}
.live-r8 {
  color: #ff1201;
  border-style:solid;
  border-width:2px;
  background-color: white;  
}
.live-r9 {
  color: #770400;
  border-style:solid;
  border-width:2px;
  background-color: white;  
}
.live-r10 {
  color: #23c300;
  border-style:solid;
  border-width:2px;
  background-color: white;  
  // letter-spacing: -3px;
  text-indent: -2px;
}

.Race {
  display: inline-block;
  width:16px;
  height:18px;
  line-height:18px;
  text-align: center;
  background: #000;
  color:#fff;
  margin:4px 2px 0 0;
}
.Race1{background:#e6de00}
.Race2{background:#0092e0}
.Race3{background:#4b4b4b}
.Race4{background:#ff7501}
.Race5{background:#17e1e1}
.Race6{background:#5333ff}
.Race7{background:#bfbfbf}
.Race8{background:#ff2600}
.Race9{background:#790a01}
.Race10{background:#07be01}

.ysc-small {
  height: 32px;
  line-height: 27px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-size: 30px;
  margin-right:4px;
  width:100%
}

.LHC-redball-small,.LHC-blueball-small,.LHC-greenball-small, .LHC-ball-small {
  width: 32px;
  height: 32px;
  line-height: 28px;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-size: 30px;
  // margin-right:4px;
}

.lottery-right .LHC-ball-small{
  display: inline-block;
  text-align: center;
  width: 32px;
  height: 32px;
  line-height: 30px;
  margin-right: 5px;
  color: #000;
  font-style: normal;
  font-weight: bold;
  background-size: 32px;
  vertical-align: middle;
  border-radius: 16px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.16);
  font-size: 16px;
  font-family: Roboto,Noto,Helvetica,Arial,sans-serif;
  border:none !important;
}
.LHC-ball-small-full{
  display: inline-block;
  color: #fff;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  text-align: center;
  line-height: 28px;
  font-style: normal;
  margin: 4px 5px 0 0;
  font-size: 16px;
}
.lhc-b-01,.lhc-b-02,.lhc-b-07,.lhc-b-08,.lhc-b-12,.lhc-b-13,.lhc-b-18,.lhc-b-19,.lhc-b-23,.lhc-b-24,.lhc-b-29,.lhc-b-30,.lhc-b-34,.lhc-b-35,.lhc-b-40,.lhc-b-45,.lhc-b-46 {
  background:#f44336;
}
.lhc-b-03,.lhc-b-04,.lhc-b-09,.lhc-b-10,.lhc-b-14,.lhc-b-15,.lhc-b-20,.lhc-b-25,.lhc-b-26,.lhc-b-31,.lhc-b-36,.lhc-b-37,.lhc-b-41,.lhc-b-42,.lhc-b-47,.lhc-b-48 {
  background:#2196f3;
}
.lhc-b-05,.lhc-b-06,.lhc-b-11,.lhc-b-16,.lhc-b-17,.lhc-b-21,.lhc-b-22,.lhc-b-27,.lhc-b-28,.lhc-b-32,.lhc-b-33,.lhc-b-38,.lhc-b-39,.lhc-b-43,.lhc-b-44,.lhc-b-49 {
  background:#4caf50;
}
.livelhc-b-01,.livelhc-b-02,.livelhc-b-07,.livelhc-b-08,.livelhc-b-12,.livelhc-b-13,.livelhc-b-18,.livelhc-b-19,.livelhc-b-23,.livelhc-b-24,.livelhc-b-29,.livelhc-b-30,.livelhc-b-34,.livelhc-b-35,.livelhc-b-40,.livelhc-b-45,.livelhc-b-46 {
  color:#f44336;
  background:white;
  border-style: solid;
  border-width: 2px;
  width:22px;
  line-height:16px;
}
.livelhc-b-03,.livelhc-b-04,.livelhc-b-09,.livelhc-b-10,.livelhc-b-14,.livelhc-b-15,.livelhc-b-20,.livelhc-b-25,.livelhc-b-26,.livelhc-b-31,.livelhc-b-36,.livelhc-b-37,.livelhc-b-41,.livelhc-b-42,.livelhc-b-47,.livelhc-b-48 {
  color:#2196f3;
  background:white;
  border-style: solid;
  border-width: 2px;
  width:22px;
  line-height:16px;
}
.livelhc-b-05,.livelhc-b-06,.livelhc-b-11,.livelhc-b-16,.livelhc-b-17,.livelhc-b-21,.livelhc-b-22,.livelhc-b-27,.livelhc-b-28,.livelhc-b-32,.livelhc-b-33,.livelhc-b-38,.livelhc-b-39,.livelhc-b-43,.livelhc-b-44,.livelhc-b-49 {
  color:#4caf50;
  background:white;
  border-style: solid;
  border-width: 2px;
  width:22px;
  line-height:16px;
}


.LHC-redball-small,.lhc01,.lhc02,.lhc07,.lhc08,.lhc12,.lhc13,.lhc18,.lhc19,.lhc23,.lhc24,.lhc29,.lhc30,.lhc34,.lhc35,.lhc40,.lhc45,.lhc46 {
  background-position-x: center;
  background-image: url("../assets/image/red-ball.png");
  background-repeat: no-repeat;
}
.LHC-blueball-small,.lhc03,.lhc04,.lhc09,.lhc10,.lhc14,.lhc15,.lhc20,.lhc25,.lhc26,.lhc31,.lhc36,.lhc37,.lhc41,.lhc42,.lhc47,.lhc48 {
  background-position-x: center;
  background-image: url("../assets/image/blue-ball.png");
  background-repeat: no-repeat;
}
.LHC-greenball-small,.lhc05,.lhc06,.lhc11,.lhc16,.lhc17,.lhc21,.lhc22,.lhc27,.lhc28,.lhc32,.lhc33,.lhc38,.lhc39,.lhc43,.lhc44,.lhc49 {
  background-position-x: center;
  background-image: url("../assets/image/green-ball.png");
  background-repeat: no-repeat;
}

.lhc-num01,.lhc-num02,.lhc-num07,.lhc-num08,.lhc-num12,.lhc-num13,.lhc-num18,.lhc-num19,.lhc-num23,.lhc-num24,.lhc-num29,.lhc-num30,.lhc-num34,.lhc-num35,.lhc-num40,.lhc-num45,.lhc-num46 {
  color:#f44336;
}
.lhc-num03,.lhc-num04,.lhc-num09,.lhc-num10,.lhc-num14,.lhc-num15,.lhc-num20,.lhc-num25,.lhc-num26,.lhc-num31,.lhc-num36,.lhc-num37,.lhc-num41,.lhc-num42,.lhc-num47,.lhc-num48 {
  color:#2196f3;
}
.lhc-num05,.lhc-num06,.lhc-num11,.lhc-num16,.lhc-num17,.lhc-num21,.lhc-num22,.lhc-num27,.lhc-num28,.lhc-num32,.lhc-num33,.lhc-num38,.lhc-num39,.lhc-num43,.lhc-num44,.lhc-num49 {
  color:#4caf50;
}

.picker-modal .toolbar+.picker-modal-inner {
  height: -webkit-calc(100% - 48px);
  height: -moz-calc(100% - 48px);
  height: calc(100% - 48px);
}

.picker-modal .picker-modal-inner {
  height: 100%;
  position: relative;
}

.picker-keypad-buttons {
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 31vh;
}

.picker-keypad-button {
  background: #fcfcfd;
  position: relative;
  text-align: center;
  cursor: pointer;
  width: 33.33333333%;
  width: -webkit-calc(100% / 3);
  width: calc(100% / 3);
  height: auto;
  max-height: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto,Noto,Helvetica,Arial,sans-serif;
  border: 1px solid grey;
  border: 1px solid rgba(128,128,128,0.3);
}

.picker-keypad-button-number {
  font-size: 28px;
  line-height: 1;
}

.picker-keypad-button-number, .picker-keypad-button-letters {
  display: block;
  width: 100%;
}

.picker-keypad-button.picker-keypad-button-dark {
  background: #bcc0c5;
}

.picker-keypad .picker-modal-inner:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 1px;
  width: 100%;
  background-color: rgba(92, 94, 96, 0.35);
  display: block;
  z-index: 15;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

//.picker-keypad-button:after {
//  content: '';
//  position: absolute;
//  right: 0;
//  top: 0;
//  width: 1px;
//  height: 100%;
//  background-color: rgba(92, 94, 96, 0.35);
//  display: block;
//  z-index: 15;
//}
//
//.picker-keypad-button:before {
//  content: '';
//  position: absolute;
//  left: 0;
//  top: 0;
//  height: 1px;
//  width: 100%;
//  background-color: rgba(92, 94, 96, 0.35);
//  display: block;
//  z-index: 15;
//}

.picker-keypad-button:nth-child(1):before, .picker-keypad-button:nth-child(2):before, .picker-keypad-button:nth-child(3):before{
   background-color: transparent;
}

i.icon-keypad-delete {
  width: 100%;
  height: 16px;
  background-size: contain !important;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 16'><path d='M18,0h-8C8.9,0,7.9,0.5,7.1,1.2c0,0,0,0,0,0L1,7.2C0.6,7.7,0.6,8.4,1,8.8l4.9,4.7L7,14.6c0,0,0,0,0,0c0.7,0.9,1.8,1.4,3,1.4 h8c2.2,0,4-1.8,4-4V4C22,1.8,20.2,0,18,0z M17.3,11.2l-0.7,0.7l-3.2-3.2l-3.2,3.2l-0.7-0.7L12.8,8L9.6,4.8l0.7-0.7l3.2,3.2l3.2-3.2 l0.7,0.7L14.2,8L17.3,11.2z' fill='%23000000'/></svg>");
  }

.bet-record-tab{
  flex: 1 1 0%;
  color: red;
  width: 50%;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  height:48px;
}

.bet-record-tab.active{
  border-bottom: 3px solid #fff;
}

.game-right-toggle{
  float:right;
  margin: 0 5px;
  color:white;
  line-height: 44px;
}

.right-toggle-slide{
  position: fixed;
  top: 22px;
  right:15px;
  background: white;
  text-align: center;
  color: black;
  border-radius: 5px;
  border: 1px solid #c1c1c1;
  min-height: 8px;
  z-index: 1056;
}

//.right-toggle-slide::before {
//  content: "";
//  display: block;
//  position: fixed;
//  width: 0;
//  height: 0;
//  top: 31px;
//  right: 16.3px;
//  border-left: 20px solid transparent;
//  border-right: 0px solid transparent;
//  border-bottom: 20px solid #ffffff;
//  overflow: overlay !important;
//  -webkit-backface-visibility: hidden;
//  /* margin: -8px 0 0 113px; */
//}

.right-toggle-slide div{
  padding: 0 15px;
  height: 35px;
  background: white;
  z-index: 999;
  line-height: 35px;
  font-size: 12px;
  width: 40vw;
  text-align: left;
}

.menu-page .mdc-tab-scroller__scroll-area--scroll{
  overflow-y: hidden;
}

.menu-page .mdc-tab button:focus, .menu-page .mdc-tab .mdc-tab-indicator:focus{
  outline: none;
}

.disabled, [disabled] {
  opacity: .55;
  pointer-events: none;
}

tr.detailsTr td {
  padding: 0 10px 5px;
}

.data-table1 table th, .data-table1 table td {
  padding: 10px 5px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: normal;
}

.data-table1 table {
  width: 100%;
  text-align: left;
  border-spacing: 0;
  border-collapse: collapse;
}

.data-table1 table thead {
  background: #f4f4f4;
}

.more-bet-info {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.data-table1 {
  font-size: 14px;
}

.data-table1 .small-text-green{
  background: #6fe26f;
  color: #fff !important;
  font-size: 12px;
  line-height: 1;
  padding: 1px 5px;
  border-radius: 4px;
  margin-left: 8px;
}

.data-table1 .small-text-red{
  background: red;
  color: #fff !important;
  font-size: 12px;
  line-height: 1;
  padding: 1px 5px;
  border-radius: 4px;
  margin-left: 8px;
}

.buttonPurse-round{
  text-align: center;
  height: 32px;
  line-height: 32px;
  width:100%;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
}

.buttonPurse-round.active{
  color: #fff !important;
  border-width: 0;
}

.purse-input{
  width: 100%;
  height: 25px;
  margin-top:3px;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding-left: 5px;
}

.content-block-box{
  display:none;
}

.content-block-box.active{
  margin: 5px 0;
  padding: 0 5px 0 0;
  display: block;
}

.testluzitu {
  .react-tabs__tab-list{
    border-bottom: none;
  }
  .react-tabs__tab{
    padding: 0 10px;
    font-size: 13px;
    background-color: transparent;
    border: none;
    color: rgb(155, 157, 158);
    bottom: 0;
    line-height: 28px;
  }
  .react-tabs__tab--selected{
    border-bottom: 2px solid rgb(20, 132, 217);
    color: rgb(20, 132, 217);
  }

  .react-tabs__tab:focus{
    border-bottom: 2px solid rgb(20, 132, 217);
    box-shadow: none;
  }

  .react-tabs__tab:focus:after{
    height:0;
  }
}

.dropbtn {
  background-color: white;
  width:100px;
  height:25px;
  color: black;
  font-size: 14px;
  border: 2px solid #dcdcdc;
  border-radius:5px;
  cursor: pointer;
  padding-top:5px;
  padding-left:5px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  z-index: 99;
}

.dropdown:before {
  content: '\0025BC';
    color: black;
    right: 14px;
    text-align:center;
    top:-2px;
    height: 30px;
    padding: 10px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 110px;
  width: max-content;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
  max-height: 400px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 2;
}

.dropdown a:hover {background-color: #dcdcdc;}

.show {display: inline;}

.award-list{
  height: calc(100vh - 134px);
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  z-index: 3;
  background-color: white;
  z-index: 1057;
}
.loadingSpinner{
  fill: white;
  width: 15%;
  // background-color: black;
  background-color: transparent;
  border-radius: 14%;
  position: fixed;
  top: 44%;
  opacity: .8;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: all 0.5s;
  padding:5px
}

.checkboxStyle{
  display: none;
}

.checkboxStyle.active{
  margin-top: -3px;
  display: inline-block;
}

.award-list table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  text-align: center;
  //line-height: 2.2;
  border-bottom: 1px solid #dfdfdf;
  color: #666;
  line-height: 32px;
}

.award-list table thead {
  background: #dfdfdf;
  font-size: 13px;
}

.award-list table>tbody>tr:nth-of-type(even) {
  background-color: #f4f4f4;
}

.award-list table td{
  padding: 0;
}

.award-lhc {
  margin-top: -3px;
}

.award-lhc .award-list-num, .award-lhc .award-list-text {
  line-height: 1;
  font-size: 12px;
}

.award-list-title{
  text-align: center;
}

.award-list-num {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.award-lhc .award-list-num b, .award-lhc .award-list-text span {
  display: inline-block;
  width: 12.5%;
  float: left;
  margin: 3px;
}

.award-lhc .award-list-num b span:not([class^="sx-"], [class*=" sx-"]){
  text-align: center;
  width: 100%;
}

.award-lhc .fifth-col{
  width: 25% !important;
}
.fifth-pic{
  text-align: left;
}
.award-50{
  width:50% !important;
}

.award-period-no{
  position: relative;
  width: 10px;
}

.award-period-no:before {
  position: absolute;
  left: 5px;
  top: 0;
  display: block;
  content: '';
  width: 1px;
  background: #dfdfdf;
  height: 2.25em;
}

.award-period-no:after {
  position: absolute;
  left: 0;
  display: block;
  content: '';
  width: 10px;
  background: #dfdfdf;
  height: 1px;
  margin-top: 1.2em;
  bottom: 50%;
}

.landingBg {
  background-image: url("../assets/image/page-load.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999999999999999999;
}
.betTable:after{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: auto;
  top: auto;
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,.12);
  display: block;
  z-index: 1;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.ping{
  font-size: 14px;
}

.trend-page .react-tabs__tab-list{
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  overflow: auto;
}

.trend-page .react-tabs__tab{
  border-radius: 0;
  line-height: 42px;
  text-align: center;
  width: 110px;
  -webkit-box-flex: 0;
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  -ms-flex: 0;
}

.trend-page .react-tabs__tab--selected{
  font-weight: bold;
}

.trend-page .table thead th{
  background-color: white;
  padding: 4px;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  min-width: 28px;
  //max-width: 28px;
  height:48px;
  font-family: Roboto,Noto,Helvetica,Arial,sans-serif;
}

.trend-page thead tr:first-child th:nth-child(1) {
  position: sticky;
  height: 40px;
  z-index: 999;
  left:0;
}

.trend-page tbody tr td:nth-child(1) {
  position: sticky;
  z-index: 999;
  left: 0;
}

.trend-page tbody tr td{
  padding: 4px;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  background: #fff;
  min-width: 28px;
}

.trend-page .lhc-num{
  border-radius: 50px;
  margin: auto;
  height: 18px;
  color: white;
  line-height: 18px;
  display: block;
  width: 18px;
}

.game-popup-overlay{
  z-index: 99991;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
}

.game-alert-overlay{
  z-index: 9999;
  top:0;
}

.game-popup-container-live{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 99992;
  padding: 0 40px;
  width:100%;
  max-height: 60vh;
}

.game-popup-container{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 99992;
  padding: 0 40px;
  width:100%;
  max-height: 80vh;
}

.game-alert-container{
  z-index: 99994;
  width:100%;
  max-width: 360px;
  position: fixed;
}

.game-popup{
  background:white;
  width:100%;
  padding: 20px;
  border-radius: 5px;
  font-family:Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size:14px;
  max-width: 300px;
  margin:auto;
  overflow-y: scroll;
  max-height: 80vh;
  -webkit-overflow-scrolling: touch;
}

.game-alert{
  //box-shadow: 0 19px 38px rgba(0,0,0,.3), 0 15px 12px rgba(0,0,0,.22);
  border-radius: 3px;
  padding: 24px;
}

.game-popup .apv-title {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.game-alert .apv-title{
  font-size: 20px;
  text-align: left;
}

.game-popup p {
  color: #999;
  margin: 5px 0 0 0;
  font-family:Roboto, "Helvetica Neue", Arial, sans-serif;
}

.game-alert p{
  font-size: 16px;
  text-align:left;
  margin-top: 20px;
  line-height: 1.5;
  color: #757575;
}

.game-popup .apv-sure {
  margin-top: 20px;
  color: white;
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 29px;
  border-radius: 5px;
  font-size:14px;
}

.modal-backdrop.show{
  opacity: 0 ;
}

.modal-header {
  border-bottom : 0;
}

.modal-footer {
  border-top: 0;
}

.alert-top {
}

.alert-top .bet-title1 {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: none;
}

.alert-top .bet-title2 {
  font-size: 16px;
  color: var(--theme-color);
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #dfdfda;
  margin-bottom: 10px;
}

.alert-top .issueTitle {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
}
.alert-top .issueTitle span:nth-child(1) {
  color: #000000;
}

.alert-top .issueTitle span:nth-child(2){
  color: #ff0000;
}

.alert-top .bet-Style {
  font-size: 12px;
  display: flex;
  margin: auto;
  margin-bottom: 10px;
  text-align: left;
}

.bet-wrap{
  margin: auto;
  width: fit-content;
}

.alert-top .bet-Style .title{
  color: #999;
  width: 80px;
  text-align: left;
}
.alert-top .bet-Style .content{
  color: #000;
  width: calc(100% - 80px);
  text-align: left;
  word-break: break-all;
}

.alert-top .bet-totle {
  font-size: 16px;
  color: #000;
  text-align: center;
}
.pd-0{
  padding: 0 !important;
}

.pd-20{
  padding: 20px !important;
}

.pd-0 .apv-sure{
  margin-bottom: 20px;
}

.width100{
  width: 100% !important;
}

.classic-bet-now .title{
  padding: 8px 0;
  background-color: white;
  color: black;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #e5e5e5;
}

.ball-line{
  display: flex;
  background: #ffffff;
  border-radius: 6px;
  margin: 3px 1px 7px 1px;
  padding: 0px 6px;
  height: 27px;
  line-height: 27px;
}

.first-sel{
  border-left: 1px solid var(--theme-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.last-sel{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.isCheckBtn-false {
  flex: 1;
  text-align: center;
  padding: 0px 5px;
  font-weight: bold;
  color: #dc3b40;
  background-color: #fff;
  border: 1px solid #fff;
}

.isCheckBtn-true {
  flex: 1;
  text-align: center;
  padding: 0px 5px;
  font-weight: bold;
  color: #fff;
  background-color: #dc3b40;
  border: 1px solid #dc3b40;
}

#prize-winning-bg-view {
  font-size: 0px;
  white-space: nowrap;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 180px;
}

#prize-winning-bg-view .prize-winning-view {
  display: inline-flex;
  position: relative;
  width: 100vw;
}

#prize-winning-bg-view .prize-content {
  margin-top: 45px;
  margin-left: 26px;
  margin-right: 30px;
  padding: 5px 30px 5px 70px;
  width: 100%;
  background-color: #dc3d3d;
  background: linear-gradient(0deg, #d53535, #f05252);
  border-radius: 120px;
  box-shadow: 5px 0 5px #ce8787;
  color: white;
}

#prize-winning-bg-view .prize-head {
  position: absolute;
  top: 0;
  width: 110px;
  height: 110px;
}

#prize-winning-bg-view .prize-footer {
  position: absolute;
  right: 0px;
  top: 40px;
  width: 80px;
}

#prize-winning-bg-view .prize-no {
  white-space: nowrap;
  font-size: 14px;
  margin: 0;
  text-align: left;
}

#prize-winning-bg-view .prize-no .no-num {
  color: #00ffff;
}

#prize-winning-bg-view .prize-price {
  font-size: 14px;
  margin: 5px 0 0 0;
  text-align: left;
}

#prize-winning-bg-view .prize-price .price {
  color: #f9e00b;
}

#prize-winning-bg-view .close-btn {
  position: absolute;
  right: 0px;
  top: 15px;
  width: 24px;
}

.side-nav-icon{
  position: absolute;
  right: 15px;
  font-size: 24px;
  color:#c5c5c7;
}

.cr-sidebar::before, .cr-sidebar::after{
  background-color: transparent !important;
}

*{
  font-family: Roboto,Noto,Helvetica,Arial,sans-serif;
}

.table {
  margin-bottom:0;
}

.center-toggle-title{
  background-color: transparent;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  font-size: 12px;
  border-radius: 4px;
  color: white;
  line-height: 23px;
}

.right-toggle-title{
  background-color: transparent;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  color: white;
  line-height: 23px;
  outline: none;
  padding: 0;
}
@media screen and (max-width: 380px){
  .center-toggle-title{
    font-size: 11px;
    padding: 0;
  }
  .right-toggle-title{
    font-size: 13px;
  }
}
@media screen and (max-width: 350px){
  .center-toggle-title{
    font-size: 12px;
    padding: 0;
  }
  .right-toggle-title{
    font-size: 12px;
  }
  .kw3-list .square .tip{
    font-size: 10px;
  }
}

@font-face {
  font-family: 'shengxiao';
  src:  url('../assets/fonts/shengxiao.eot');
  src:  url('../assets/fonts/shengxiao.eot') format('embedded-opentype'),
  url('../assets/fonts/shengxiao.ttf') format('truetype'),
  url('../assets/fonts/shengxiao.woff') format('woff'),
  url('../assets/fonts/shengxiao.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="sx-"], [class*=" sx-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'shengxiao' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 20px;
  display:block;
  margin-top: 5px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sx-sheep:before, .sx-羊:before{
  content: "\e900";
}
.sx-cow:before, .sx-牛:before{
  content: "\e901";
}
.sx-horse:before, .sx-马:before {
  content: "\e902";
}
.sx-dragon:before, .sx-龙:before {
  content: "\e903";
}
.sx-snake:before, .sx-蛇:before {
  content: "\e904";
}
.sx-monkey:before, .sx-猴:before {
  content: "\e905";
}
.sx-tiger:before, .sx-虎:before {
  content: "\e906";
}
.sx-rabbit:before, .sx-兔:before {
  content: "\e907";
}
.sx-hen:before, .sx-鸡:before {
  content: "\e908";
}
.sx-dog:before, .sx-狗:before {
  content: "\e909";
}
.sx-pig:before, .sx-猪:before {
  content: "\e90a";
}
.sx-rat:before, .sx-鼠:before {
  content: "\e90b";
}

.loading-popup-overlay{
  z-index: 99991;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background:'transparent'
}

.toolbar {
  left: 0;
  bottom: 0;
  height: 48px;
  font-size: 14px;
}
.toolbar a.link {
  line-height: 48px;
  height: 48px;
}
.toolbar a {
  -webkit-box-flex: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tabbar {
  z-index: 5001;
  overflow: hidden;
  bottom: auto;
  top: 0;
}
.tabbar .toolbar-inner {
  padding-left: 0;
  padding-right: 0;
}
.tabbar a.link {
  line-height: 1.4;
}
.tabbar a.tab-link,
.tabbar a.link {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  font-size: 14px;
  text-transform: uppercase;
}
.tabbar i.icon {
  height: 24px;
}
.tabbar a.tab-link {
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  color: #999;
  background-color: white;
  border-bottom: 2px solid #ececec;
}
.tabbar a.tab-link.active,
html:not(.watch-active-state) .tabbar a.tab-link:active,
.tabbar a.tab-link.active-state {
  color: var(--theme-color);
}
.tabbar .tab-link-highlight {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  background: var(--theme-color);
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}
.tabbar-labels {
  height: 72px;
}
.tabbar-labels a.tab-link,
.tabbar-labels a.link {
  padding-top: 12px;
  padding-bottom: 12px;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.tabbar-labels span.tabbar-label {
  line-height: 1;
  display: block;
  margin: 0;
  margin-top: 10px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}
.tabbar-labels.toolbar-bottom span.tabbar-label {
  text-transform: none;
}
.tabbar-scrollable .toolbar-inner {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  overflow: auto;
}
.tabbar-scrollable .toolbar-inner::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}
.tabbar-scrollable a.tab-link,
.tabbar-scrollable a.link {
  width: auto;
  -webkit-box-flex: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  padding: 0 16px;
}
.toolbar-bottom {
  bottom: 0;
  top: auto;
}
.toolbar-bottom .tab-link-highlight {
  bottom: auto;
  top: 0;
}

.empty {
	text-align: center;
	padding: 50px 0;
	font-size: 20px;
	color: #ccc;
	line-height: 1;
    background-color: #f4f4f4;
}
.empty-black {
	text-align: center;
	padding: 50px 0;
	font-size: 20px;
	color: #ccc;
	line-height: 1;
  background-color: #161616;
}
.empty i {
	font-size: 110px;
}
  .list-block .item-content {
    box-sizing: border-box;
    padding: 0 16px;
    min-height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding-right: 0;
  }

.cs_empty {
	text-align: center;
	padding: 50px 0;
	font-size: 20px;
	color: #ccc;
	line-height: 1;
    background-color: #f4f4f4;  
    background-image: url(../assets/image/customer_support_background.png);
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.35;
}

  .list-block .item-subtitle {
    font-size: 14px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
  }
  .list-block .item-subtitle-align-left {
    flex: auto;
    padding-left: 15px;
  }
  .list-block .item-text {
    font-size: 14px;
    color: #757575;
    line-height: 20px;
    position: relative;
    overflow: hidden;
    max-height: 40px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  .list-block.media-list .item-inner,
  .list-block li.media-item .item-inner {
    display: block;
    padding-top: 14px;
    padding-bottom: 14px;
    -ms-flex-item-align: stretch;
    -webkit-align-self: stretch;
    align-self: stretch;
  }
  .list-block.media-list .item-link .item-inner,
  .list-block li.media-item .item-link .item-inner {
    background: none;
    padding-right: 16px;
  }
  .list-block.media-list .item-link .item-title-row,
  .list-block li.media-item .item-link .item-title-row {
    padding-right: 26px;
    background: no-repeat right top;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%2060%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'm60%2061.5-38.25%2038.25-9.75-9.75%2029.25-28.5-29.25-28.5%209.75-9.75z'%20fill%3D'%23c7c7cc'%2F%3E%3C%2Fsvg%3E");
    background-size: 10px 20px;
  }
  .list-block.media-list .item-media,
  .list-block li.media-item .item-media {
    padding-top: 14px;
    padding-bottom: 14px;
    -ms-flex-item-align: flex-start;
    -webkit-align-self: flex-start;
    align-self: flex-start;
  }
  .list-block.media-list .item-media img,
  .list-block li.media-item .item-media img {
    display: block;
  }
  .list-block.media-list .item-title-row,
  .list-block li.media-item .item-title-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .list-block.media-list .item-content > .item-after,
  .list-block li.media-item .item-content > .item-after {
    padding-top: 14px;
    padding-bottom: 14px;
    -ms-flex-item-align: flex-start;
    -webkit-align-self: flex-start;
    align-self: flex-start;
  }

  .msg-view-sub {
    display: flex;
    height: 22px;

  }

  .msg-view-sub .item-subtitle {
    flex: 1;
  }

  .msg-view-sub .select-img {
    background: red;
  }

  .article {
    overflow: hidden;
    font-size: 17px;
    word-wrap: break-word;
    text-align: justify;
    margin: 0 15px 20px;
    color: #505050;
    line-height: 1.647;
  }
  .article p {
    word-wrap: break-word;
    text-align: justify;
    margin: 0 0 20px;
    color: #505050;
    line-height: 1.647;
    padding: 0;
  }

  .article img {
    width: auto;
    max-width: 100%;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    opacity: 1;
  }

  .article_header {
    display: block;
    margin: 24px 15px;
    padding: 0;
  }
  .article_header h1 {
    display: block;
    font-size: 22px;
    font-weight: bolder;
    padding-bottom: 15px;
    line-height: 1.3;
    margin: 0;
    color: #000;
  }

  .subtitle {
    position: relative;
    font-size: 11px;
    line-height: 1;
    color: #505050;
  }
  .subtitle time {
    display: inline-block;
    font-size: 11px;
    color: inherit;
    height: auto;
    line-height: 1;
  padding-left: 6px;
  }
  .floating-button {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    z-index: 1500;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    background-color: #2196f3;
    color: #fff;
    overflow: hidden;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  i.icon.icon-plus {
    width: 24px;
    height: 24px;
    font-size: 0;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20fill%3D'%23FFFFFF'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%20width%3D'24'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M19%2013h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }

  .material-icons img {
    width:24px;
    height: 24px;
    border-radius: 12px;
    vertical-align: inherit
  }

  .list-block textarea {
    resize: none;
    line-height: 1.4;
    padding-top: 8px;
    padding-bottom: 7px;
    height: 100px;
  }
  .list-block textarea.resizable {
    height: 36px;
  }
  .list-block .item-input {
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
    font-size: 0;
    position: relative;
    margin-bottom: 4px;
    min-height: 36px;
  }
  .list-block .input-item ul:after,
  .list-block.inputs-list ul:after {
    display: none;
  }
  .list-block .input-item .item-media,
  .list-block.inputs-list .item-media {
    -ms-flex-item-align: flex-end;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    min-height: 36px;
    margin-bottom: 8px;
    padding: 0;
  }
  .list-block .input-item .item-inner,
  .list-block.inputs-list .item-inner {
    display: block;
    margin-bottom: 4px;
    padding-bottom: 0;
  }
  .list-block .input-item .item-inner:after,
  .list-block.inputs-list .item-inner:after {
    display: none;
  }
  .list-block .input-item .label,
  .list-block.inputs-list .label,
  .list-block .input-item .floating-label,
  .list-block.inputs-list .floating-label {
    width: 100%;
    font-size: 12px;
  }
  .list-block .input-item .floating-label,
  .list-block.inputs-list .floating-label {
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scale(1.33333333) translateY(21px);
    transform: scale(1.33333333) translateY(21px);
    color: rgba(0, 0, 0, 0.35);
    width: auto;
    max-width: 75%;
  }
  .item-input input::-webkit-input-placeholder,
  .item-input input::-webkit-input-placeholder {
    color: #999;;
  }

  .list-block .item-title {
    min-width: 0;
    -webkit-box-flex: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 14px!important;
  }
  .list-block .item-after {
    white-space: nowrap;
    color: #757575;
    -webkit-box-flex: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    margin-left: auto;
    padding-left: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    max-height: 200px;
    font-size: 14px;
  }
  .list-block .item-after-align-left {
    flex: auto;
    padding-left: 15px;
  }

  .list-block .focus-state .floating-label,
  .list-block .not-empty-state .floating-label {
    color: rgba(0, 0, 0, 0.65);
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
  }

  .list-block .focus-state .label,
  .list-block .focus-state .floating-label {
    color: red;
  }
  .item-input-field,
  .input-field {
    position: relative;
  }
  .item-input-field:after,
  .input-field:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: auto;
    top: auto;
    height: 1px;
    width: 100%;
    background-color:white;
    display: block;
    z-index: 15;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  .item-input-field:after,
  .input-field:after {
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms;
  }
  .item-input-field.focus-state:after,
  .input-field.focus-state:after,
  .item-input-field.not-empty-state:after,
  .input-field.not-empty-state:after,
  .focus-state .item-input-field:after,
  .focus-state .input-field:after,
  .not-empty-state .item-input-field:after,
  .not-empty-state .input-field:after {
    background: red;
    -webkit-transform: scaleY(2) !important;
    transform: scaleY(2) !important;
  }

  .list-block .align-top,
  .list-block .align-top .item-content,
  .list-block .align-top .item-inner {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .messages-content {
    background: #eee;
  }
  .messages {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .messages-date {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    margin: 10px 15px;
  }
  .messages-date span {
    font-weight: 400;
  }
  .message {
    box-sizing: border-box;
    margin: 0px 8px 8px 8px;
    max-width: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .message:first-child {
    margin-top: 8px;
  }
  .message.message-pic img {
    display: block;
  }
  .message-name,
  .message-label,
  .message-date,
  .messages-date {
    color: rgba(0, 0, 0, 0.51);
  }
  .message-name {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 2px;
    margin-top: 7px;
  }
  .message-hide-name .message-name {
    display: none;
  }
  .message-label {
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }
  .message-hide-label .message-label {
    display: none;
  }
  .message-avatar {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    margin-top: -48px;
    position: relative;
    top: 1px;
    background-size: cover;
    opacity: 1;
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms;
  }
  .message-hide-avatar .message-avatar {
    opacity: 0;
  }
  .message-text {
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 8px;
    min-width: 48px;
    font-size: 16px;
    line-height: 1.2;
    word-break: break-word;
    color: #333;
    min-height: 48px;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .message-text img {
    max-width: 100%;
    height: auto;
  }
  .message-pic .message-text {
    padding: 8px;
  }
  .message-date {
    font-size: 12px;
    margin-top: 4px;
  }
  .message-pic img + .message-date {
    margin-top: 8px;
  }
  .message-sent {
    -ms-flex-item-align: end;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .message-sent .message-name,
  .message-sent .message-label {
    margin-right: 8px;
  }
  .message-sent .message-text {
    background-color: #a0e759;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1.4;
    min-height: 40px;
    padding: 10px 12px;
  }
  .message-sent .message-text:after {
    position: absolute;
    content: '';
    border-left: 0px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #a0e759;
    left: 100%;
    top: 8px;
    width: 0;
    height: 0;
  }

  .message-sent .message-text:before {
    position: absolute;
    content: '';
    border-left: 0px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #a0e759;
    border-bottom: 0px solid transparent;
    left: 100%;
    top: 14px;
    width: 0;
    height: 0;
  }

  .message-sent.message-with-avatar .message-text,
  .message-sent.message-with-avatar .message-name,
  .message-sent.message-with-avatar .message-label {
    margin-right: 56px;
  }
  .message-received {
    -ms-flex-item-align: start;
    -webkit-align-self: flex-start;
    align-self: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .message-received .message-text {
    background-color: #fff;
    border-radius: 2px 2px 2px 0px;
    margin-left: 8px;
  }
  .message-received .message-text:before {
    position: absolute;
    content: '';
    border-left: 8px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 8px solid #fff;
    right: 100%;
    bottom: 0;
    width: 0;
    height: 0;
  }
  .message-received .message-name,
  .message-received .message-label {
    margin-left: 8px;
  }
  .message-received.message-with-avatar .message-text,
  .message-received.message-with-avatar .message-name,
  .message-received.message-with-avatar .message-label {
    margin-left: 56px;
  }
  .message-appear-from-bottom {
    -webkit-animation: messageAppearFromBottom 400ms;
    animation: messageAppearFromBottom 400ms;
  }
  .message-appear-from-top {
    -webkit-animation: messageAppearFromTop 400ms;
    animation: messageAppearFromTop 400ms;
  }

  @-webkit-keyframes messageAppearFromBottom {
    from {
      -webkit-transform: translate3d(0, 100%, 0);
    }
    to {
      -webkit-transform: translate3d(0, 0, 0);
    }
  }
  @keyframes messageAppearFromBottom {
    from {
      transform: translate3d(0, 100%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @-webkit-keyframes messageAppearFromTop {
    from {
      -webkit-transform: translate3d(0, -100%, 0);
    }
    to {
      -webkit-transform: translate3d(0, 0, 0);
    }
  }
  @keyframes messageAppearFromTop {
    from {
      transform: translate3d(0, -100%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .content-block-title {
    margin: 14px 16px;
  }
  .content-block-title .more {
    float:right;
  }
  .content-block-title-index {
  padding: 14px 16px;
  background: #fff;
  margin:10px 0 0;
  border-bottom: 1px solid #ececec;
  text-align:center;
  font-size:18px;
  color: #f44336;
  }
  .content-block-title-index .more {
  color: rgba(0,0,0,.54);
  position: absolute;
    right: 10px;
    font-size:13px;
  }

  .content-block-title-img {
  margin: 0;
  padding: 0 0 10px;
  line-height: 0;
  }
  .content-block-title-img img {
  width: 100%;
  }

  .list-block .item-link,
  .list-block .list-button {
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    display: block;
    color: inherit;
    position: relative;
    overflow: hidden;
    z-index: 0;
  }
  .list-block .item-link .item-inner,
  .list-block .list-button .item-inner {
    padding-right: 42px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%2060%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'm60%2061.5-38.25%2038.25-9.75-9.75%2029.25-28.5-29.25-28.5%209.75-9.75z'%20fill%3D'%23c7c7cc'%2F%3E%3C%2Fsvg%3E");
    background-size: 10px 20px;
    background-repeat: no-repeat;
    background-position: 95% center;
    background-position: -webkit-calc(100% - 16px) center;
    background-position: calc(100% - 16px) center;
  }

  .list-block .smart-select .item-after,
  .list-block .autocomplete-opener .item-after {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: block;
  }

  .content-block-box .list-block.inputs-list .label {
    font-size: 12px;
  }
  .content-block-box .list-block .item-content {
  padding-left: 10px;
  }
  .content-block-box .list-block .item-inner {
  padding-right: 0;
  }

  .list-block .item-inner {
    padding-right: 16px;
    position: relative;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 48px;
    box-sizing: border-box;
    -webkit-box-flex: 1;
    min-width: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    -webkit-align-self: stretch;
    align-self: stretch;
  }

  .item-code {
    position: relative;
  }
  .item-code img {
    height: 36px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .slidemeup{
    position:absolute;
    bottom:calc(-40% + var(--safe-bottom));
    width:100%;
    left:0;
    animation-name:slideup;
    animation-delay:0.01s;
    animation-duration:0.5s;
    animation-fill-mode:forwards;
    animation-timing-function:ease-out;


  }
  @keyframes slideup {
    0%{bottom:calc(-40% + var(--safe-bottom))}
    100%{bottom:calc(0px + var(--safe-bottom))}
  }

  .slidemedown{
      position:absolute;
      bottom:calc(40% + var(--safe-bottom));
      width:100%;
      left:0;
      animation-name:slidedown;
      animation-delay:0.01s;
      animation-duration:0.5s;
      animation-fill-mode:forwards;
      animation-timing-function:ease-out;
  }

  @keyframes slidedown {
      0%{bottom:calc(0px + var(--safe-bottom))}
      100%{bottom:calc(-40% + var(--safe-bottom))}
  }

  label.label-radio i.icon-form-radio,
  .form-radio i {
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 20px;
    border: 2px solid #6d6d6d;
    box-sizing: border-box;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    display: block;
  }
  label.label-radio i.icon-form-radio:after,
  .form-radio i:after {
    content: ' ';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 50%;
    top: 50%;
    margin-left: -5px;
    margin-top: -5px;
    background-color: var(--theme-color);
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
  }
  label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio,
  label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio,
  label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio,
  label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio,
  label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio,
  label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio,
  .form-radio input[type="radio"]:checked ~ i {
    border-color: var(--theme-color);
  }
  label.label-radio input[type="checkbox"]:checked + .item-media i.icon-form-radio:after,
  label.label-radio input[type="checkbox"]:checked ~ .item-after i.icon-form-radio:after,
  label.label-radio input[type="checkbox"]:checked ~ .item-inner i.icon-form-radio:after,
  label.label-radio input[type="radio"]:checked + .item-media i.icon-form-radio:after,
  label.label-radio input[type="radio"]:checked ~ .item-after i.icon-form-radio:after,
  label.label-radio input[type="radio"]:checked ~ .item-inner i.icon-form-radio:after,
  .form-radio input[type="radio"]:checked ~ i:after {
    background-color: var(--theme-color);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  label.label-checkbox,
  label.label-radio {
    position: relative;
    overflow: hidden;
    z-index: 0;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
  }

  .form-radio input[type=checkbox], .form-radio input[type=radio], label.label-radio input[type=checkbox], label.label-radio input[type=radio] {
    display: none;
  }

  .langBtnActive{
    padding: 10px 10px;
    color: black;
    background: #F2F1F6;
    border: 1px solid #F2F1F6;
    border-radius: 8px;
  }

  .langBtn {
    padding: 10px 10px;
    color: black;
    background: white;
    border: 1px solid #F2F1F6;
    border-radius: 8px;
  }

  /****game-side: 手机设置****/
.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #ccc;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner::before, .toggle-switch-inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: attr(data-on);
  font-size: 10px;
  text-transform: uppercase;
  padding-left: 19px;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-disabled::before {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-inner::after {
  content: attr(data-off);
  font-size: 10px;
  text-transform: uppercase;
  padding-right: 19px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}

.toggle-switch2 {
  position: relative;
  width: 100px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch2-checkbox {
  display: none;
}
.toggle-switch2-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #ccc;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch2-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch2-inner::before, .toggle-switch2-inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch2-inner:before {
  content: attr(data-before);
  font-size: 10px;
  text-transform: uppercase;
  padding-left: 12px;
  color: #fff;
}
.toggle-switch2-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch2-disabled::before {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch2-inner::after {
  content: attr(data-after);
  font-size: 10px;
  text-transform: uppercase;
  padding-right: 13px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}
.toggle-switch2-switch {
  display: block;
  width: 17px;
  margin: 8px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 68px;
  border: 0 solid #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch2-checkbox:checked + .toggle-switch2-label .toggle-switch2-inner {
  margin-left: 0;
}
.toggle-switch2-checkbox:checked + .toggle-switch2-label .toggle-switch2-switch {
  right: 0px;
}

.toggle-switch3-checkbox {
  display: none;
}
.toggle-switch3-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #ccc;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch3-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch3-inner::before, .toggle-switch3-inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch3-inner:before {
  content: attr(data-before);
  font-size: 10px;
  text-transform: uppercase;
  padding-left: 12px;
  color: #fff;
}
.toggle-switch3-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch3-disabled::before {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch3-inner::after {
  content: attr(data-after);
  font-size: 10px;
  text-transform: uppercase;
  padding-right: 13px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}
.toggle-switch3-switch {
  display: block;
  width: 17px;
  margin: 8px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 68px;
  border: 0 solid #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch3-checkbox:checked + .toggle-switch3-label .toggle-switch3-inner {
  margin-left: 0;
}
.toggle-switch3-checkbox:checked + .toggle-switch3-label .toggle-switch3-switch {
  right: 0px;
}

/* The container */
.container2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container2 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container2 .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}


.backgroundd {
  width: 100px;
  height: 50px;
  padding: 0;
  margin: 0;
}

.linee {
  width: 185px;
  height: 45px;
  border-bottom: 1px solid #ddd;
  transform: translateY(-20px) translateX(5px) rotate(10deg);
}

.backgroundd>div {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.adjust-up .modal-content{
  margin-bottom: 200px;
}

.menu-page .mdc-tab-indicator > .mdc-tab-indicator__content--underline{
  height:3px;
}

.resultPageStyle .bet-list div[class*="col-"].active i:not(.LHC-ball-small), .resultPageStyle .bet-list div[class*="col-"].active span{
  color: #000000 !important;
}

.resultPageStyle .bet-list div[class*="col-"].activeYSC i:not(.LHC-ball-small), .resultPageStyle .bet-list div[class*="col-"].activeYSC span{
  color: #000000 !important;
  font-weight: bold;
}

.bet-list-live div[class*="col-"].liveActive.active i:not(.LHC-ball-small), .bet-list-live div[class*="col-"].liveActive.active span{
  color: #000000 !important;
}

.bet-list-live div[class*="col-"].liveActive.activeYSC i:not(.LHC-ball-small), .bet-list-live div[class*="col-"].liveActive.activeYSC span{
  color: #000000 !important;
  font-weight: bold;
}

.game-nav2{
  padding: 0px;
  background: white;
  height:  60px;
  margin-bottom: 0px;
  border-bottom: 1.5px solid #f4f4f4;
  .react-tabs__tab:focus{
    box-shadow: none;
    border-color: var(--theme-color);
  }
}

.game-nav2 .react-tabs__tab{
  color: #919191;
  font-size: 12px;
  //padding:inherit;
  padding: 0px 8px 0px 8px;
  border: 0;
  margin: 0px 6px 0px 6px; 
}

.react-tabs__tab:focus:after{
  background: transparent !important;
}

.game-tab{
  opacity: 0.5;
}

.game-nav2 .react-tabs__tab--selected{
  color: var(--theme-color);
  border-bottom: 2px solid var(--theme-color);
  bottom: 1px;
  .game-tab{
    opacity: 1;
  }
}

.trend-page .game-nav2{
  padding: 0;
  background: white;
  height: 50px;
  margin-bottom: 0px;
  white-space: nowrap;
  border: none;
  overflow-y: hidden;
}

.menu-page .mdc-tab-indicator > .mdc-tab-indicator__content--underline, .cr-content .betting-settlement, .bet-modal, .bet-details-header, .red-ball, .toggle-switch2-inner:before, .toggle-switch-inner:before, input:checked ~ .checkmark, .game-nav, .ball-title span:not(.active), .lotteryCart{
  background: var(--theme-color);
}

.isCheckBtn-true, .resultPageStyle .game-container .ball.active, .resultPageStyle .bet-list div[class*="col-"].active, .kw3-list .square.active{
  background-color: #CCC8C8 !important;
  border: 1px solid #CCC8C8 !important;
}

.resultPageStyle .bet-list div[class*="col-"].activeYSC{
  background-color: #CCC8C8 !important;
  border: 1px solid #CCC8C8 !important;
}

.red-ball-em, .resultStyle ul li button.active{
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.custom-modal-txt>button{
  color: var(--theme-color) !important;
}

.trend-num-row{
  display: flex;
}

.trend-pic-row{
  display: inline-block;
  width: 80px;
}

.trend-red-ball{
  padding: 0px;
  display: inline-block;
  background-color: #f54646;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  color: white;
  line-height: 18px;
  border: 0px;
  margin: 0 2px;
}

.lottery-method{
  margin: 9px 0;
/*  background: white; */
  height: 41px !important;
}

.colc-5{
  width: 41.666%;
  float: left;
}

.colc-8{
  width: 80%;
  float: left;
}

.colc-2{
  width: 16.666%;
  float: left;
}
.text-break{
  white-space: break-spaces;
  text-align: right;
}
.grey-btn{
  background-color: #9e9e9e !important;
}

.open-left-row{
  border-right: 1px solid #dfdfdf;
  color: #919191;
  height: 50px;
  text-align: center;
  margin: 5px 0;
}

.open-left-row-en{
  border-right: 1px solid #dfdfdf;
  color: #919191;
  height: 50px;
  text-align: center;
  margin: 5px 0;
}

.open-left-row-en.LHC{
  height:65px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  display: none;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment  {
  height: 30px;
  background-color: transparent;
}
::-webkit-scrollbar-track-piece  {
  background-color: #3b3b3b;
  -webkit-border-radius: 16px;
}
::-webkit-scrollbar-thumb:vertical {
  height: 50px;
  background-color: #666;
  border: 1px solid #eee;
  -webkit-border-radius: 6px;
}

.rel{position:relative;}
.abs{position:absolute;}
.back-img{
    width: 85%;
    padding-top: 85%;
    background-size: 100%;
}
.roulette-background{
  background: url("../assets/image/LUSTCITY/background.png") no-repeat;
}
.sound-btn{
    top:0px;
    bottom:0;
    left:0;
    right:0;
    z-index: 999;
}
.lot-desc{
    top: 0px;
    bottom:0;
    left:-10%;
    right:0;
    width: 85%;
    height: 85%;
    // background-image: url("../assets/image/ball-red.png");
    background: url("../assets/image/LUSTCITY/c2.gif") no-repeat;
    background-size: 100%;
    margin:auto;
}
.lot-btn {
    top: 50%;
    left: 50%;
    margin-left: -15%;
    margin-top: -14%;
    width: 20%;
    height: 25%;
    background: url("../assets/image/LUSTCITY/c3.png") no-repeat;
    background-size: 100% 100%;
}
.lot-heart {
  top: 50%;
  left: 50%;
  margin-left: -10%;
  margin-top: -4%;
  width: 10%;
  height: 10%;
  background: url("../assets/image/LUSTCITY/c4.gif") no-repeat;
  background-size: 100% 100%;
}

.lct-award-num {
  width: 30px;
  height: 30px;
  line-height: 27px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  background-size: 30px;
  vertical-align: top;
}

.game-girl {
  top: 10%;
  bottom: 0;
  left: 48%;
  right: 0;
}

.game-container-zhonghe{
  width: 100%;
  overflow: scroll;
  background: white;
}

.lottery-method-zhonghe{
  background: white;
}

.zongHeNextBtn{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--theme-color) !important;
  height: 40px;
  color: white;
  opacity: 1;
}

.zongHeNextBtn:disabled{
  background-color: #cccccc;
  color: #ffffff;
  pointer-events: auto;
}

.zongHeGameList{
  width: calc(50% - 10px);
  position: relative;
  padding: 10px 0px 10px 5px;
  border: 2px solid rgb(243, 243, 243);
  margin: 5px;
  height: max-content;
  border-radius: 5px;
}

.zongHeGameList.active{
  border: 1px solid var(--theme-color);
}

.zongHeGameListEmpty{
  width: calc(100% - 10px);
  position: relative;
  padding: 20px 0px 10px 5px;
  border: 2px solid #f3f3f3;
  margin: 5px;
  height: 75px;
  text-align: center;
  background: #f3f3f3;
}

.zongHeOdds{
  margin-left: 5px;
  color: #848484;
  margin-bottom: 3px;
}

.oddsstyle{
  border: 1px solid #bfbfbf;
  border-radius: 3px;
}

.oddsstyle td{
  border: 1px solid #bfbfbf;
}

.red-ball-small-header-s {
  display: inline-block;
  background: #f44336;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 14px;
  text-align: center;
  line-height: 18px;
  font-style: normal;
  margin: 4px 1px 0 0;
  font-size: 14px;
}

.cr-content, .cr-page{
  min-height:auto;
}

.select-box{
  height: 30px;
  text-align-last: center;
  width: auto;
  font-size: 14px;
  border: 1px solid #6a82fb;
  border-radius: 0.2rem;
  background: white;
}

.bhdLno{
  margin-bottom: -35px;
  max-width: 100%!important;
  margin-top: -40px;
  max-height: 100%!important;
}

.promotion-roulette-wheel-container {
  background-image: url("../assets/image/event/bgPromtionRoulette.png");
  background-size: 100% 100%;
  position: relative!important;
  .hmocIu{//roulette spin
    width: 66%;
    height: 65%;
  }
  .gTLZXx{//roulette pointer
    right: 13% !important;
    top: 13% !important;
  }
  .dNkkBq{
    margin-top:1px!important;
  }
}

.wheelBtn{
  background-color: yellow;
  border-radius: 7px;
  padding: 10px 30px;
  margin-bottom: 35px;
}

.promotionPointRoulette{
  padding-top: 25px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}
.rouletteWheelAnimation{
  animation: fadeIn 0.5s infinite alternate;
}
.rouletteWheelAnimationDelay{
  animation-delay: 0.5s;
}

@keyframes rotate-animation {
  0% {
      transform: rotate(0deg);
  }

  50% {
      transform: rotate(180deg);
  }

  100% {
      transform: rotate(360deg);
  }
}
.rouletteBackgroundLightAnimation{
  animation: rotate-animation 10s infinite linear;
}

.roulettePrizeStarAnimation{
  animation: fadeIn 1s infinite alternate
}

@keyframes zoom-in-zoom-out {
  0% {
      transform: scale(1, 1);
  }

  50% {
      transform: scale(1.1, 1.1);
  }

  100% {
      transform: scale(1, 1);
  }
}
.roulettePrizeCoinAnimation{
  animation: zoom-in-zoom-out 2.05s ease-out infinite;
}
.roulettePrizeGiftboxAnimation{
  animation: zoom-in-zoom-out 2s ease-out infinite;
}


.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: var(--theme-color)!important;
}

.Mui-checked .MuiIconButton-label{
  color: var(--theme-color);
}

.action-content{
  margin-top: 15px;
  padding: 0 16px;
  box-sizing: border-box;
  height: auto;
}

.action-row{
  margin-top: 1em;
  align-self: center;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0;
}

.action-cancel{
  margin-left: 0px !important;
  width: 100%;
  text-align: center;
  display: block;
  line-height: 25px;
  margin: 0;
  height: 25px;
  font-size: 14px;
  position: relative;
  border-right: 1px solid lightgray;
  color: #7f7777f5 !important;
}

.action-confirm{
  margin-left: 0px !important;
  color: #f44336 !important;
  width: 100%;
  text-align: center;
  display: block;
  line-height: 25px;
  margin: 0;
  height: 36px;
  font-size: 14px;
  position: relative;
}

.res-startDate{
  padding-top:15px;
  color: #808080c7;
  padding-bottom: 12px;
  width: 50%;
}

.res-endDate{
  padding-top:15px;
  color: #808080c7;
  width: 50%;
}

.res-reminder{
  padding-top:15px;
  color: #ffbf00;
  font-size: 14px;
}

.res-timeSelection{
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1.5px solid lightgray;
}

.res-endTimeSelection{
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
}

.react-datepicker__input-container input{
  border: 0px !important;
  width: inherit;
}

.res-endTimeSelection .react-datepicker-popper{
  width: max-content!important;
  left: -40% !important;
}

.res-endTimeSelection .react-datepicker__input-container{
  padding-top: 15px;
}

.res-endTimeSelection .react-datepicker__triangle{
  margin-left: 50% !important;
}

.res-timeSelection .react-datepicker-popper{
  width: max-content!important;
  left: -40% !important;
}

.res-timeSelection .react-datepicker__input-container{
  padding-top: 15px;
}

.res-timeSelection .react-datepicker__triangle{
  margin-left: 50% !important;
}

.rest-continer{
  display: flex;
  flex-wrap: wrap;
}

.timeclass .modal-content{
  border-radius: 25px;
}

.listStyle .picker-item{
  opacity: 0.6;
}

.listStyle .picker-item-selected{
  opacity: 1;
  color: red;
}

.warning-popup{
  width:100%;
  padding: 30px;
  border-radius: 5px;
  font-family:Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size:14px;
  max-width: 300px;
  margin:195px 0px 0px;
  max-height: 80vh;
  -webkit-overflow-scrolling: touch;
  height: 100px;
}

.warning-popup .apv-sure {
  margin-top: 20px;
  color: white;
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 29px;
  border-radius: 5px;
  font-size:14px;
}

.profitwarning-popup-container{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 99992;
  padding: 0px;
  width:85%;
  max-height: 80vh;
  background-image: url("../assets/image/newProfitWarning.png");
  background-size: 345px 215px;
  background-repeat: no-repeat;
  background-position: top;
  height: 400px;
  background-color:white;
  border-radius:30px;
  border-style:solid;
  border-color: gold;
}

.pausewarning-popup-container{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 99992;
  width:75%;
  max-height: 80vh;
  background-image: url("../assets/image/pauseWarning.png");
  background-size: 320px 200px;
  background-repeat: no-repeat;
  background-position: top;
  height: 380px;
  background-color:white;
  border-radius:30px;
  border-color: gold;
}

.sleepwarning-popup-container{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 99992;
  padding: 0px;
  width:80%;
  max-height: 80vh;
  background-image: url("../assets/image/sleepWarning.png");
  background-size: 345px 215px;
  background-repeat: no-repeat;
  background-position: top;
  height: 375px;
  background-color:white;
  border-radius:30px;
  border-style:solid;
  border-color: gold;
}

.restwarning-popup-container{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 99992;
  padding: 0 18px;
  width:75%;
  max-height: 80vh;
  background-image: url("../assets/image/restWarning.png");
  background-size: 310px 250px;
  background-repeat: no-repeat;
  background-position: top;
  height: 410px;
  background-color:white;
  border-radius:30px;
  border-style:solid;
  border-color: gold;
}

.PrivateSwitchBase-root-21 {
  color: #fafafa !important;
}

.action-cancel{
  margin-left: 0px !important;
  width: 50%;
  text-align: center;
  display: block;
  line-height: 25px;
  margin: 0;
  height: 25px;
  font-size: 14px;
  position: relative;
  border-right: 1px solid lightgray;
  color: #7f7777f5 !important;
  float:left;
}

.action-confirm{
  margin-left: 0px !important;
  color: #f44336 !important;
  width: 50%;
  text-align: center;
  display: block;
  line-height: 25px;
  margin: 0;
  height: 36px;
  font-size: 14px;
  position: relative;
  float:left;
}

.action-cancel-pause{
  margin-left: 38px !important;
  width: 50%;
  text-align: center;
  display: block;
  line-height: 25px;
  margin: 56px 0 0 0;
  height: 25px;
  font-size: 15px;
  position: relative;
  color: #7f7777f5 !important;
  float:left;
}

.time-modal{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.time-content{
  padding: 14px;
  background-color: #f4f4f4;
  border-radius: 25px;
  position: relative;
  width: 85%;
  margin: auto;
  top: 20%;
}

.deer
.gourd
.rooster
.fish
.crab
.prawn {
  background: url("../assets/image/YXX/deer.png") no-repeat;
  background-size: 100% auto;
  width: 65px;
  height: 65px;
  line-height: 0;
  display: inline-block;
  margin: auto;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 40px;
  -moz-transform-origin: 0 0;
  transform-origin: center;
}
.deer {
  background: url("../assets/image/YXX/deer.png") no-repeat;
}
.gourd {
  background: url("../assets/image/YXX/gourd.png") no-repeat;
}
.rooster {
  background: url("../assets/image/YXX/rooster.png") no-repeat;
}
.fish {
  background: url("../assets/image/YXX/fish.png") no-repeat;
}
.crab {
  background: url("../assets/image/YXX/crab.png") no-repeat;
}
.prawn {
  background: url("../assets/image/YXX/prawn.png") no-repeat;
}

.deer-small
.gourd-small
.rooster-small
.fish-small
.crab-small
.prawn-small {
  background: url("../assets/image/YXX/deer.png") no-repeat;
  background-size: 100% auto;
  width: 35px;
  height: 34px;
  line-height: 0;
  display: inline-block;
  margin: auto;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 40px;
  -moz-transform-origin: 0 0;
  transform-origin: center;
}
.deer-small {
  background: url("../assets/image/YXX/deer.png") no-repeat;
}
.gourd-small {
  background: url("../assets/image/YXX/gourd.png") no-repeat;
}
.rooster-small {
  background: url("../assets/image/YXX/rooster.png") no-repeat;
}
.fish-small {
  background: url("../assets/image/YXX/fish.png") no-repeat;
}
.crab-small {
  background: url("../assets/image/YXX/crab.png") no-repeat;
}
.prawn-small {
  background: url("../assets/image/YXX/prawn.png") no-repeat;
}

.localize-input-currency{
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: auto;
}

.localize-input-value{
  display: block;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-right: auto;
}

.zonghe-container-149{
  height : calc((var(--vh) - var(--safe-bottom)));
}

.zonghe-container-95{
  height : calc((var(--vh) - var(--safe-bottom)) - 95px);
}

.bold-red{
  color: #a94442 !important;
  font-weight: bold;
}

.jhc-pop-up{
  .action-cancel{
    color: #f44336 !important;
    border: 2px solid red;
    border-radius: 3px;
    font-size: 18px;
    height: 35px;
    line-height: 32px;
    width: 49%;
  }
  .action-confirm{
    width: 49%;
    color: white !important;
    background-color: #f44336;
    border-radius: 3px;
    font-size: 18px;
    height: 35px;
    line-height: 35px;
  }
}

.list-block .onlinePay-item-after {
  color: #757575;
  -webkit-box-flex: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 14px;
}

.open-award-list-num-live{
  text-align:center;
  // white-space: normal;
  width: 95%;
  // margin-top: -10px;
  padding-bottom: 5px;
  font-size: 14px;
}

.open-award-list-num-wrap-live{
  text-align:center;
}

.award-period .open-award-list-num-live .award-list-num b{
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  margin: 0 2px;
}

.award-period .open-award-list-num-live .award-list-num .fifth-col{
  width:36px;
}

.award-period .open-award-list-num-live .award-list-num b .lhc-num{
  width:100%;
  text-align:center;
  border-radius: 50px;
  color:white;
}

.open-award-list-num-live .red-ball, .open-award-list-num-live .red-ball-em, .open-award-list-num-live .blue-ball, .open-award-list-num-live .blue-ball-em{
  width: 24px;
  height: 30px;
  border-radius: 20px;
  // line-height: 41px;
  font-size: 18px;
  margin: 2px 0 0 3px;
}

.open-award-list-num-live .pending-award-live{
  text-align:center;
  // white-space: nowrap; 
  font-size: 10px;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  line-height: 15px;
  margin: 2px 0 0 3px;
}

.open-award-list-num-live .Dice{
  width: 46px;
  height: 46px;
}

.open-left-row-live{
  height: 50px;
  text-align: center;
  margin: 5px 0;
}

.open-left-row-live-en{
  height: 50px;
  text-align: center;
  margin: 5px 0;
}

.open-left-row-live-en.LHC{
  height:65px;
}

.col-70{
  width: 70%;
  float: right;
}
.col-30{
  width: 30%;
  float: left;
}

.lct-award-num-live {
  width: 50px;
  height: 50px;
  line-height: 27px;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  background-size: 30px;
  vertical-align: top;
}

.game-container .live-ball{
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 17px;
  font-size: 18px;
  color: #dc3b40;
  text-align: center;
  line-height: 32px;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  position: relative;
  bottom: 0;
  margin: 0 auto;
}

.live-ball.dds{
  padding: 0 10px;
  width: auto;
}

.game-container .live-ball.active{
  color: #fff;
  background-color: #dc3b40;
  border: 1px solid #dc3b40;
}

.bet-list div[class*="col-"].liveActive.active{
  color: #fff!important;
  background-color: #dc3b40!important;
}

.bet-list div[class*="col-"].liveActive.activeYSC{
  color: black!important;
  background-color: #dc3b40!important;
}

.bet-list div[class*="col-"].liveActive{
  color: #666;
  background-color: #fff;
}
.bet-list div[class*=col-].liveActive.active span {
  color: #fff!important;
}
.bet-list div[class*=col-].liveActive.active span {
  color: #666;
}
.bet-list div[class*=col-].liveActive.active i {
  color: #fff!important;
}
.bet-list div[class*=col-].liveActive.active i {
  color: #666;
}

.bet-list .live-odds {
  color: #666;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  left: 0;
}

.bet-list .live-odds-disabled {
  color: #F2F1F6;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  left: 0;
}

.live-chipsBarBet {
  bottom: 0px;
  position: fixed;
  z-index: 11;
  height: 100%;
  width: 100%;
  border: none;
  background: transparent;
  background-position: bottom;
  background-repeat: no-repeat;
  border-radius: 15px;
}

.live-chipsBarBet.show {
  display: block;
}

.live-chipsBarBet.hide {
  display: none;
}

.live-bar_chip_bet {
  position: absolute;
  bottom: -2px;
  width: 100%;
  background: white;
  height: 60%;
  overflow: auto;
  border-radius: 15px;
  border-top: 1px solid #ebebeb;
}

.live-bar_chip_bet ul {
  margin: 0;
  padding: 15px;
}

.live-bar_chip_bet li {
  font-family: iconfont;
  width: 25%;
  height: 60px;
  display: inline-block;
  margin: 0;
  text-align: center;
  border-radius: 100%;
  position: relative;
  font-size: 46px;
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.live-chipsBarBet .live-bar_chip_bet li.cm0 {
  background-image: url(../assets/image/chips/cm0.png);
}

.live-chipsBarBet .live-bar_chip_bet li.cm1 {
  background-image: url(../assets/image/chips/cm1.png);
}

.live-chipsBarBet .live-bar_chip_bet li.cm2 {
  background-image: url(../assets/image/chips/cm2.png);
}

.live-chipsBarBet .live-bar_chip_bet li.cm3 {
  background-image: url(../assets/image/chips/cm3.png);
}

.live-chipsBarBet .live-bar_chip_bet li.cm4 {
  background-image: url(../assets/image/chips/cm4.png);
}

.live-chipsBarBet .live-bar_chip_bet li.cm5 {
  background-image: url(../assets/image/chips/cm5.png);
}

.live-chipsBarBet .live-bar_chip_bet li.cm6 {
  background-image: url(../assets/image/chips/cm6.png);
}
.live-chipsBarBet .live-bar_chip_bet li.cm7 {
  background-image: url(../assets/image/chips/cm0.png);
}

.live-chipsBarBet .live-bar_chip_bet li.live-cm0-0 {
  background-image: url(../assets/image/chips/cm0.png);
}

.live-chipsBarBet .live-bar_chip_bet li.live-cm0-1 {
  background-image: url(../assets/image/chips/cm1.png);
}

.live-chipsBarBet .live-bar_chip_bet li.live-cm0-2 {
  background-image: url(../assets/image/chips/cm2.png);
}

.live-chipsBarBet .live-bar_chip_bet li.live-cm0-3 {
  background-image: url(../assets/image/chips/cm3.png);
}

.live-chipsBarBet .live-bar_chip_bet li.live-cm1-0 {
  background-image: url(../assets/image/chips/cm4.png);
}

.live-chipsBarBet .live-bar_chip_bet li.live-cm1-1 {
  background-image: url(../assets/image/chips/cm5.png);
}

.live-chipsBarBet .live-bar_chip_bet li.live-cm1-2 {
  background-image: url(../assets/image/chips/cm6.png);
}
.live-chipsBarBet .live-bar_chip_bet li.live-cm1-3 {
  background-image: url(../assets/image/chips/cm0.png);
}

.live-chipsBarBet .live-bar_chip_bet a {
  display: block;
  color: #000 !important;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 0px;
  line-height: 60px;
  width: 100%
}

.bet-list-live{
  //padding: 0 0 0 15px;
  background: white;
  width: 90%;
  // margin: 5%;
  margin-top: -2%;
  margin-bottom: 7%;
  margin-left: 5%;
  margin-right: 5%;
}

.bet-list-live div[class*="col-"] {
  background: #fff;
  text-align: center;
  color: #000;
  border: 1px solid #e5e5e5;
  padding: 7px 0;
  margin-bottom: 8px;
  border-radius: 10px;
  position:relative;
}

.bet-list-live div[class*="col-"].disabled{
  background: #ffff;
  color: #F2F1F6;
  cursor: not-allowed;
}

.bet-list-live div[class*="col-"].liveActive.active{
  color: #fff!important;
  background-color: #dc3b40!important;
}

.bet-list-live div[class*="col-"].liveActive.activeYSC{
  color: black!important;
  background-color: #dc3b40!important;
  border-color: #dc3b40;
}

.bet-list-live div[class*="col-"].liveActive{
  color: #666;
  background-color: #fff;
  padding-top: 10px;
}

.bet-list-live div[class*=col-].liveActive.active span {
  color: #fff!important;
}
.bet-list-live div[class*=col-].liveActive.active span {
  color: #666;
}
.bet-list-live div[class*=col-].liveActive.active i {
  color: #fff!important;
}
.bet-list-live div[class*=col-].liveActive.active i {
  color: #666;
}

.bet-list-live .live-odds {
  color: #666;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  left: 0;
}

.bet-list-live .row{
  // display: table;
  border-spacing: 8px;
  border-collapse: separate;
  width:100%;
  position: relative;
  margin-right: 0px;
  margin-left: 0px;
  //margin-top: -10px;
  //margin:0;
  //justify-content: space-between;
}

.bet-list-live .row .col-20 {
  width: 20%;
  display: table-cell;
  float: none;
  //width: -webkit-calc((100% - 8px*4) / 5);
  //width: calc((100% - 8px*4) / 5);
}

.bet-list-live .row .col-25 {
  width: 25%;
  display: table-cell;
  float: none;
  //width: -webkit-calc((100% - 8px*3) / 4);
  //width: calc((100% - 8px*3) / 4);
}

.bet-list-live .row .col-33 {
  width: 33.333333333333336%;
  display: table-cell;
  float: none;
  //width: -webkit-calc((100% - 8px*2) / 3);
  //width: calc((100% - 8px*2) / 3);
}

.bet-list-live .row .col-50 {
  width: 50%;
  display: table-cell;
  float: none;
  //width: -webkit-calc((100% - 8px*1) / 2);
  //width: calc((100% - 7px*1) / 2);
}

.bet-list-live .row .col-60{
  width: 60%;
  display: table-cell;
  float: none;
  //width: -webkit-calc((100% - 16px*.6666666666666667)/ 1.6666666666666667);
  //width: calc((100% - 16px*.6666666666666667)/ 1.6666666666666667);
}

.bet-list-live .row .col-100 {
  width: 100%;
  display: table-cell;
  float: none;
}

.bet-list-live .bet-number {
  font-style: normal;
  display: block;
  font-size: 18px;
  padding: 2px 0px 2px;
  line-height: 1;
  color: #c91b1c;
}

.bet-list-live .odds {
  color: #666;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  left: 0;
}

.bet-list-live .bgodds{
  color: #666;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}

.bet-list-live .yscodds{
  color: #666;
  font-size: 12px;
  line-height: 1;
  display: grid;
}


@media screen and (max-width: 1000px) {
  .bet-list-live .bet-number{
    font-size: 12px;
    padding: 5px;
    width: 63px;
    height: 45px;
  }
  .bet-list-live .bgodds{
    font-size: 11px;
  }
  .bet-list-live .odds{
    font-size: 11px;
  }
  .bet-list-live .lhc-odds{
    font-size: 11px;
  }
}

.live-cart-modal .modal-content{
 background-color: transparent;
 border: none;
  //padding-bottom: 97px;
}

.live-award-list{
  height: calc(100vh - 134px);
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  z-index: 3;
  background-color: transparent;
  z-index: 1057;
}
.live-award-list table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  text-align: center;
  border-bottom: 1px solid #dfdfdf;
  color: white;
  line-height: 32px;
}

.live-award-list table thead {
  background: rgba(238, 238, 238, 0.3);
  font-size: 13px;
}

.live-award-list table>tbody>tr:nth-of-type(even) {
  background-color: rgba(238, 238, 238, 0.15);;
}

.live-award-list table td{
  padding: 0;
}

.live-award-list-title{
  text-align: center;
}

.flow_bar_chip_bet {
  position: absolute;
  width: 100%;
}

.flow_bar_chip_bet ul {
  margin: 0;
  padding: 0;
}

.flow_bar_chip_bet li {
  font-family: iconfont;
  width: 11%;
  height: 40px;
  display: inline-block;
  margin: 0;
  text-align: center;
  border-radius: 100%;
  position: relative;
  font-size: 46px;
  max-width: 14%;
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
}

.flowchipsBarBet .flow_bar_chip_bet li.cm0 {
  background-image: url(../assets/image/chips/cm0.png);
}

.flowchipsBarBet .flow_bar_chip_bet li.cm1 {
  background-image: url(../assets/image/chips/cm1.png);
}

.flowchipsBarBet .flow_bar_chip_bet li.cm2 {
  background-image: url(../assets/image/chips/cm2.png);
}

.flowchipsBarBet .flow_bar_chip_bet li.cm3 {
  background-image: url(../assets/image/chips/cm3.png);
}

.flowchipsBarBet .flow_bar_chip_bet li.cm4 {
  background-image: url(../assets/image/chips/cm4.png);
}

.flowchipsBarBet .flow_bar_chip_bet li.cm5 {
  background-image: url(../assets/image/chips/cm5.png);
}

.flowchipsBarBet .flow_bar_chip_bet li.cm6 {
  background-image: url(../assets/image/chips/cm6.png);
}

.flowchipsBarBet .flow_bar_chip_bet li.cm6 {
  background-image: url(../assets/image/chips/cm0.png);
}

.flowchipsBarBet .flow_bar_chip_bet a {
  display: block;
  color: #000 !important;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  left: 0px;
  line-height: 38px;
  width: 100%
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

div {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.hg-theme-default .hg-button {
  font-size: 18px;
}

.instantBet-button {
  padding: 0px 10px;
  color: white !important;
  font-size: 12px;
  display: flex;
  align-items: center;
  position: relative;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  /*margin-left: auto;*/
  line-height: 10px;
  text-align: center;
  background: var(--theme-color);
}

a {
  text-decoration:none;
}

.game-popup-regoverlay{
  z-index: 99991;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
}

.game-alert-regcontainer{
  z-index: 99994;
  width:100%;
  position: fixed;
}

.game-popup-regcontainer{
  margin: 0;
  top: 0%;
  text-align: center;
  z-index: 99992;
  width:100%;
}

.game-regalert{
  border-radius: 3px;
}

.game-regpopup{
  background:white;
  width:100%;
  border-radius: 5px;
  font-family:Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size:14px;
  -webkit-overflow-scrolling: touch;
}

.mapiaoBetNumber {
  width: 60%;
  border: 1px solid #ABB2B9;
  border-radius: 5px;
  margin-bottom: 5px;
  height: 32px;
  padding-left: 10px;
}
.mapiaoDropdown {
  margin-top: 0;
  z-index: auto;
  display: inline-block;
}
.mapiaoDropdown::before {
  content: "";
}
.mapiaoDropdown div {
  z-index: 99;
}
.mapiaoDropdownToggle, .mapiaoDropdownToggle:hover, .mapiaoDropdownToggle:active {
  background: none;
  border: 1px solid #ABB2B9;
  white-space: nowrap;
  background-color: white !important;
  border-color: #ABB289 !important;
}
.mapiaoToolbarTotalAmount {
  text-align: center;
  background-color: black;
  color: white;
  padding: 10px;
}
.mapiaoToolbarButtonGroup {
  padding: 0px;
  display: flex;
}
.mapiaoToolbarButton {
  flex: 50%;
  padding: 10px;
  text-align: center;
}
.zhonghe4dAwardButton {
  background-color: var(--theme-color);
  color: white;
  padding: 5px 25px;
  border-radius: 5px;
  margin: 0px 0px 10px;
}
.mapiaoTable td {
  padding: 0.5rem 0.2rem;
}
.mapiaoEraserIcon {
  color: white;
  background-color: red;
  border-radius: 5px;
  padding: 2px;
  margin-left: 5px;
}

.hotcoldMain{
  height: 30px;
  /* margin-bottom: 5px; */
  /* margin-top: 5px; */
  /* top: 10px; */
  /* border-radius: 4px; */
  /* border: 1px solid #e0e0e0; */
  /* overflow: auto; */
  /* -webkit-box-sizing: border-box; */
  /* box-sizing: border-box; */
  /* font-size: 13px; */
  /* float: right; */
  /* position: relative; */
  padding: 5px 5px;
  // background: #fff;
  display: flex;
  justify-content: flex-end;
  
}

.hotcoldSub{
  border: 1px solid #e0e0e0;
  color: #000;
  border-radius: 5px;
  height: 28px;
  line-height: 25px;
  font-size: 12px;
  padding: 0 10px;
  width: auto;
  text-align: center;
  margin-right: 3px;
}

.hotcoldSubActive{
  height: 28px;
  line-height: 25px;
  font-size: 12px;
  padding: 0 10px;
  width: auto;
  text-align: center;
  margin-right: 3px;
  color: #fff !important;
  background-color: var(--theme-color) !important;
  border: 1px solid var(--theme-color);
  border-radius: 5px;
}

.inner_rl {
  display: block;
  position: absolute;
  left: 0px;
  top: -7px;
  font-size: 11px;
  color: #666;
  border: .7666666667px solid #d9def6;
  background: #fff;
  min-width: 5.15556vw;
  border-radius: 1.33333vw 1.33333vw 0 1.33333vw;
  color: #fff;
  background-color: #999;
  border: none;
}

.inner_rl.largest {
  color: #fff;
  background-color: #e3243a;
}

.inner_rl.smallest {
  color: #fff;
  background-color: #199cff;
}

.inner_lryl {
  display: block;
  position: absolute;
  right: 0px;
  top: -8px;
  font-size: 11px;
  color: #666;
  min-width: 5.15556vw;
  // border: .7666666667px solid #d9def6;
  background: #fff;
  border-radius: 1.33333vw 1.33333vw 1.33333vw 0;
}

.inner_lryl.largest {
  color: #d9001b;
}

.inner_lryl.smallest {
  color: #199cff;
}

.off_inner_ry {
  display: block;
  position: absolute;
  left: 2px;
  top: -5px;
  font-size: 11px;
  color: #666;
  border: .7666666667px solid #d9def6;
  background: #fff;
  min-width: 5.15556vw;
  border-radius: 1.33333vw 1.33333vw 0 1.33333vw;
  color: #fff;
  background-color: #999;
  border: none;
  line-height: 1.6;
}

.off_inner_ry.largest {
  color: #fff;
  background-color: #e3243a;
}

.off_inner_ry.smallest {
  color: #fff;
  background-color: #199cff;
}

.off_inner_lryl {
  display: block;
  position: absolute;
  right: 2px;
  top: -5px;
  font-size: 11px;
  color: #666;
  min-width: 5.15556vw;
  border: .7666666667px solid #d9def6;
  // border: 0.7666666667px solid #ebecf0;
  background: #fff;
  border-radius: 1.33333vw 1.33333vw 1.33333vw 0;
  line-height: 1.6;
}

.off_inner_lryl.largest {
  color: #d9001b;
}

.off_inner_lryl.smallest {
  color: #199cff;
}

.ball-hot {
  width: 100%;
  padding: 0 1.33333vw;
  line-height: 5.33333vw;
  text-align: center;
  color: #999;
  font-size: 3.2vw;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.normal.miss.largest,.normal.hotcold.largest {
  color: #e3243a;
}

.normal.miss.smallest,.normal.hotcold.smallest {
  color: #199cff;
}

.ball-hot>div:nth-child(2):before, .ball-miss>div:nth-child(2):before {
  content: "|";
  color: #c9c9c9;
  margin-left: .8vw;
  margin-right: .8vw;
}

.bet-list div[class*=col-].active .inner_lryl {
  color: #666 !important;
}

.sideMenu-button-row {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 50px;
}

.sideMenu-button {
  border: 1px solid #F2F1F6;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  width: 100%;
}

.sideMenu-selection-row {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 45px;
  border-top: 1px solid #F2F1F6!important;
}

.sideMenu-selection-row-title{
  padding-left: 35px !important;
}

.sideMenu-selection-icon {
  padding:0 0 0 5%;
}

.sideMenu-selection-right {
  padding:0;
}

.sideMenu-selection-right-icon {
  font-size: 24px;
  color: #c5c5c7;
}

.sideMenu-selection-button {
  background: #F2F1F6;
  width: 100%;
  border-radius: 10px;
  height: 40px;
  border: none;
}

.live-stream {
  position: relative;
  width: 100%;
  height: 270px;
  background-color: #323232;
  color: #fff;
  z-index: 5;
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.54118);
          box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.54118);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ls-header {
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
}

.ls-header span {
  color: #fff;
}

.ls-content {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.frame {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.v-lds-ellipsis {
  position: absolute;
  width: calc(5 * 1rem);
  height: calc(5 * .25rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 5;
}

.v-lds-ellipsis div {
  position: absolute;
  top: calc(5 * .11rem);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.v-lds-ellipsis div:nth-child(1) {
  left: calc(5 * .25rem);
  -webkit-animation: vlds-ellipsis1 0.6s infinite;
          animation: vlds-ellipsis1 0.6s infinite;
}

.v-lds-ellipsis div:nth-child(2) {
  left: calc(5 * .25rem);
  -webkit-animation: vlds-ellipsis2 0.6s infinite;
          animation: vlds-ellipsis2 0.6s infinite;
}

.v-lds-ellipsis div:nth-child(3) {
  left: calc(5 * .5rem);
  -webkit-animation: vlds-ellipsis2 0.6s infinite;
          animation: vlds-ellipsis2 0.6s infinite;
}

.v-lds-ellipsis div:nth-child(4) {
  left: calc(5 * 0.75rem);
  -webkit-animation: vlds-ellipsis3 0.6s infinite;
          animation: vlds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes vlds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes vlds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes vlds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes vlds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes vlds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(calc(5 * .25rem), 0);
            transform: translate(calc(5 * .25rem), 0);
    transform: translate(calc(5 * .25rem), 0);
  }
}

@keyframes vlds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(calc(5 * .25rem), 0);
            transform: translate(calc(5 * .25rem), 0);
    transform: translate(calc(5 * .25rem), 0);
  }
}

.v-dialog-container .dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 3;
}

.v-dialog-container .dialog-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.v-dialog-container .dialog-box {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}