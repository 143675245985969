.cr-header {
  // margin-bottom: $spacer * .5;
  // border-bottom: $border-width solid $border-color;

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }

  // &__notification-popover {
  //   &.popover {
  //     width: 320px;
  //     max-width: 320px;
  //     max-height: 100vh;
  //     overflow-y: scroll;
  //     cursor: pointer;
  //   }
  // }
}

.btn-sm,
.btn-group-sm > .btn {
  margin: 4px;
  font-size: 12px;
}

.hr-text {
  position: relative;
  height: 1.5em;
  font-size: 12px;
  line-height: 1em;
  color: #000;
  text-align: center;
  border: 0;
  outline: 0;
  opacity: .5;
  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    content: " ";
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
  }
  &::after {
    position: relative;
    display: inline-block;
    padding: 0 .5em;
    line-height: 1.5em;
    color: #000;
    content: attr(data-content);
    // this is really the only tricky part, you need to specify the background color of the container element...
    background-color: #fcfcfa;
  }
}
