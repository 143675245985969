.mdc-tab__ripple.mdc-ripple-upgraded {
    display: none !important;
}

.mdc-tab {
    outline: none !important;
}

.mdc-tab__content {
    padding-bottom: 10px;
}

  /****game-side: 手机设置****/
.menu-page-bg-red .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #f44336 !important;
  }
  
  .menu-page-bg-pink .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #e91e63 !important;
  }
  
  .menu-page-bg-purple .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #9c27b0 !important;
  }
  
  .menu-page-bg-deeppurple .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #673ab7 !important;
  }
  
  .menu-page-bg-indigo .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #3f51b5 !important;
  }
  
  .menu-page-bg-blue .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #2196f3 !important;
  }
  
  .menu-page-bg-lightblue .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #03a9f4 !important;
  }
  
  .menu-page-bg-cyan .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #00bcd4 !important;
  }
  
  .menu-page-bg-teal .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #009688 !important;
  }
  
  .menu-page-bg-green .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #4caf50 !important;
  }
  
  .menu-page-bg-lightgreen .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #8bc34a !important;
  }
  
  .menu-page-bg-lime .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #cddc39 !important;
  }
  
  .menu-page-bg-yellow .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #ffeb3b !important;
  }
  
  .menu-page-bg-amber .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #ffc107 !important;
  }
  
  .menu-page-bg-orange .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #ff9800 !important;
  }
  
  .menu-page-bg-deeporange .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #ff5722 !important;
  }
  
  .menu-page-bg-brown .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #795548 !important;
  }
  
  .menu-page-bg-gray .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #9e9e9e !important;
  }
  
  .menu-page-bg-bluegray .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #607d8b !important;
  }
  
  .menu-page-bg-black .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #000000 !important;
  }