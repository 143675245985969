// page
.cr-page {
  min-height: 100vh;
  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: flex-end;
    align-self: center;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }

  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }
    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: theme-color('light');
  }

  .row {
    // stylelint-disable-next-line
    .col,
    > [class^='col-'] {
      margin-bottom: 1rem;
    }
  }

  .cr-page-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

  .foot-bar {
    position: fixed;
    bottom: 50px;
    width: 100%;
    height: 47px;
    background: #fff;
    border-top: 1px solid #e1e1e1;
    z-index: 3;
}

.bsBox {
  background: #fff;
  font-size: 16px;
  height: 49px;
  line-height: 49px;
  padding: 12px;
  margin-left: 350px;
  display: flex;
}

.seleInp {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 35px;
  line-height: 35px;
  border-radius: 2px;
  display: flex;
  margin-top: 6px;
}

.baBox{
  bottom: 0;
  top: auto;
}

.toolbar{
  bottom: 0;
  top: auto;
  position: fixed;
    z-index: 2;
  background: #fff;
    border-top: 1px solid #e1e1e1;
    height: 48px !important;
}

.toolbar-button{
  padding: 14px;
  background: #ff5722;
  margin: -14px;
  margin-left: 65px;
  border-color: white;
}

