:root {
  --mdc-theme-primary: white;
  --mdc-theme-on-surface: white;
}

.top-title {
  font-size: 15px;
  margin-bottom: 0;
}

.content-block-gaia {
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #f4f4f4;
  margin: 0 auto;
  width: calc(100% - 20px);
  border-radius: 50px;
  margin-bottom: 15px;
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.15);
}

.content-block-gaia dl {
  margin: 0;
  padding: 0;
  text-align: center;
}

.content-block-gaia dt {
  margin: 0;
  padding: 0;
}

.content-block-gaia dt span {
  font-size: 25px;
  border-radius: 50%;
  padding: 5px;
  display: block;
}

.content-block-gaia dd {
  font-size: 10px;
  margin: 4px 0 0;
  color: #666666;
}

.content-block-gaia .rows {
  display: flex;
  flex-wrap: wrap;
}

.content-block-gaia .col20 {
  float: left;
}

.content-row-gaia {
  padding-left: 30px;
  padding-right: 20px;
}

.iconStyle {
  margin: 7px 0;
  color: #fff;
}

.gradientMonet {
  background: linear-gradient(to right, #ff9800 0%, #ff5722 100%);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.gradientAccount {
  background: linear-gradient(to right, #ffc107 0%, #ff9800 100%);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.gradientPublic {
  background: linear-gradient(to right, #ff5722 0%, #f44336 100%);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.gradientLocal {
  background: linear-gradient(to right, #03a9f4 0%, #2196f3 100%);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.gradientApp {
  background: linear-gradient(to right, #00bcd4 0%, #009688 100%);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.navbar {
  min-height: 44px;
}

.navbar-theme-white {
  background-color: #ffffff !important;
}

.navbar a.top-link {
  font-size: 13px;
  padding: 0 6px 0 10px;
}

.navbar a.link {
  padding: 0 10px;
  line-height: 44px;
  height: 44px;
}

.toolbar-bottom {
  height: 50px;
  background-color: #fff !important;
  border-top: 1px solid #e1e1e1;
}

//.toolbar-bottom {
//  height: calc(50px + env(safe-area-inset-bottom))!important;
//}

.tabbar .toolbar-inner {
  padding-left: 0;
  padding-right: 0;
}

.navbar-inner,
.toolbar-inner {
  //position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

//.toolbar-bottom .toolbar-inner {
//  padding-bottom: env(safe-area-inset-bottom)!important;
//}

.toolbar-bottom a.tab-link,
.toolbar-bottom a.link {
  padding-top: 3px;
  padding-bottom: 6px;
}

.tabbar-labels a.link,
.tabbar-labels a.tab-link {
  padding-top: 12px;
  padding-bottom: 12px;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.tabbar a.link,
.tabbar a.tab-link {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex: 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  font-size: 14px;
  text-transform: uppercase;
}

.navbar a.link,
.subnavbar a.link,
.toolbar a.link {
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
}

.tabbar-labels.toolbar-bottom span.tabbar-label {
  text-transform: none;
}

.toolbar-bottom a.link i+span {
  margin-left: 0;
}

.toolbar-bottom span.tabbar-label {
  margin-top: 0;
  font-size: 12px;
}

.tabbar-labels span.tabbar-label {
  line-height: 1;
  display: block;
  margin: 0;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.MuiBottomNavigationAction-label.MuiBottomNavigationAction-iconOnly {
  opacity: 1 !important;
  transition-delay: 0s;
}

.makeStyles-root-1 {
  width: 100% !important;
}

.MuiBottomNavigation-root {
  height: 80px !important;
  justify-content: space-between !important;
  width: 100%;
}

.bnbar .MuiBottomNavigationAction-root {
  max-width: auto;
  min-width: auto;
}

.carouselContainer {
  height: 75px;
  width: 100%;
  padding: 0 15px;
  background-color: var(--theme-color);
  border-bottom-left-radius: 25%;
  border-bottom-right-radius: 25%;
}

/*
.carouselContainer .slick-slider{

}
*/

.carouselStyle .slick-list {
  height: 100%;
  border-radius: 15px;
}

.carouselStyle .slick-dots {
  bottom: 10px;
  display: none !important;
}

.carouselStyle.newAPI .slick-list {
  border-radius: 0;
}

/*
.carouselStyle.newAPI .slick-slide{
  //max-width: 200px !important;
}
*/

.carouselStyle.newAPI img {
  border-radius: 15px;
  width: calc(100% - 5px);
  margin: auto;
  height: auto;
  //max-width: 190px;
  //max-height: 270px;
}

.empty-space-50 {
  padding-top: 50px;
}

.slick-dots li button:before {
  font-size: 8px !important;
}

.slick-dots li.slick-active button:before {
  color: red !important;
}

.MuiBottomNavigationAction-root {
  padding: 13px 0 6px 0px !important;
}

.navbarheaders {
  width: 100%;
  position: fixed;
  top: 0px;
  /* height: 45px; */
  height: calc(45px + env(safe-area-inset-top) / 4) !important;
  z-index: 500;
}

.navbarheaders.active {
  width: 100%;
  position: fixed;
  top: 50px;
  /* height: 45px; */
  height: calc(45px + env(safe-area-inset-top) / 4) !important;
  z-index: 500;
}

.download-top {
  height: 44px;
}

.download-top.active {
  height: 94px;
}

.download-top .textframe {
  padding: 0px 13px;
  border: 1px solid white;
  height: 25px;
  margin-left: 10px;
  border-radius: 6px;
  color: white;
  font-size: 14px;
}

.download-top .icon {
  font-size: 28px;
}

.openapp {
  display: none;
}

.openapp.active {
  top: 0;
  position: fixed;
  width: 100%;
  height: 50px;
  background: #181818;
  color: #fff;
  z-index: 500;
  display: block;
}

.marquee div {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;

}

@keyframes notifi-scroll-left {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes winner-scroll-left {
  0% {
    -moz-transform: translateX(1%);
    -webkit-transform: translateX(1%);
    transform: translateX(1%);
  }

  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-moz-keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -100%);
  }
}

@-webkit-keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -100%);
  }
}

@keyframes marquee {
  0% {
    -moz-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -moz-transform: translate(0, -100%);
    -webkit-transform: translate(0, -100%);
    transform: translate(0, -100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.slick-prev,
.slick-next {
  display: none !important;
}

.newAPIGame {
  position: relative;
  padding: 15px;

  a {
    width: 70%;
    position: relative;
    display: block;
    margin: auto;
    text-align: center;

    .bq2 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 0;
    }

    span {
      font-size: 14px;
      color: white;
    }
  }

  img {
    width: 64px;
    margin: auto;
    border-radius: 50%;
  }

  .gameSlide {
    position: absolute;
    overflow: hidden;
    width: 90%;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-slider {
    width: 60%;
    margin-left: auto;
    position: relative;
    margin-right: 25px;
    padding: 0px;
  }

  .slick-prev {
    left: 15px;
    display: block !important;
    z-index: 5;
    width: 20px;
  }

  .slick-next {
    right: 15px;
    display: block !important;
    z-index: 5;
    width: 20px;
  }

  .bg1 {
    img {
      width: 100%;
      border-radius: 15px;
    }
  }
}

.floatButtonOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, .83);
  z-index: 999999997;
}

.floatButton {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  position: fixed;
  z-index: 999999998;
  box-shadow: 0 0 0.625rem #9a9a9a;
  top: 0;
}

.floatButtonClose {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999998;
  box-shadow: 0 0 0.625rem #9a9a9a;
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

.fixe {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999998;
  border-radius: 50px;
  width: 3.75rem;
  height: 3.75rem;
  background-color: white;
  text-align: center;

  img {
    width: 70%;
    padding-top: 8px;
  }

  p {
    height: 27px;
    font-size: 11px;
    margin: auto;
    display: block;
    position: relative;

    span {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      color: white;
    }
  }
}

.fixeText {
  color: white;
  background-color: transparent;
}

.fixeHome {
  margin-top: -5rem;
  margin-left: -5rem;

}

.fixeHomeText {
  margin-top: -1.5rem;
  margin-left: -5rem;
}

.fixeHomeTest {
  margin-top: -4rem;
  margin-left: -6rem;
}

.fixeHomeTextTest {
  margin-top: -0.5rem;
  margin-left: -6rem;
}

.fixeClearCache {
  margin-top: -7.5rem;
}

.fixeClearCacheText {
  margin-top: -3.5rem;
}

.fixeChatroom {
  margin-top: 6.5rem;
}

.fixeChatroomText {
  margin-top: 10rem;
}

.fixeRefresh {
  margin-top: -5rem;
  margin-left: 5rem;
}

.fixeRefreshText {
  margin-top: -1.5rem;
  margin-left: 5rem;
}

.fixeRefreshTest {
  margin-top: -4rem;
  margin-left: 6rem;
}

.fixeRefreshTextTest {
  margin-top: -0.5rem;
  margin-left: 6rem;
}

.fixeRecharge {
  margin-top: 0rem;
  margin-left: -7rem;
}

.fixeRechargeText {
  margin-top: 3.5rem;
  margin-left: -7rem;
}

.fixeOfflineDraw {
  margin-top: 0rem;
  margin-left: 7rem;
}

.fixeOfflineDrawText {
  margin-top: 3.5rem;
  margin-left: 7rem;
}

.fixeLine {
  margin-top: 5rem;
}

.fixeLineText {
  margin-top: 8.5rem;
}

.fixeLineLog {
  margin-top: 5rem;
  margin-left: -5rem;
}

.fixeLineLogText {
  margin-top: 8.5rem;
  margin-left: -5rem;
}

.fixeLineLogTest {
  margin-top: 3rem;
  margin-left: -6rem;
}

.fixeLineLogTextTest {
  margin-top: 6.5rem;
  margin-left: -6rem;
}

.fixeLong {
  margin-top: 5rem;
  margin-left: 5rem;
}

.fixeLongText {
  margin-top: 9rem;
  margin-left: 5rem;
}

.fixeLongTest {
  margin-top: 3rem;
  margin-left: 6rem;
}

.fixeLongTextTest {
  margin-top: 7rem;
  margin-left: 6rem;
}

.register-modal {
  margin: 0;
  height: 100vh;
}

.register-modal .modal-content {
  height: 100vh;
  border: 0;
}

.register-modal .item-media {
  text-align: right;
  min-width: 60px !important;
}

// .register-modal .item-media span{
//   width: 100%;
// }

.register-modal .content-block-title {
  text-align: center;
  margin: 0;
  padding: 14px 16px;
  background-color: #eaeff2;
  color: black;
}

.register-modal input::placeholder {
  font-size: 80%;
}

.register-button {
  line-height: 40px !important;
  height: 40px !important;
  border-radius: 7px !important;
}

.avatar-modal {
  margin: 0;
}

.avatar-modal .modal-content {
  border: 0;
}

/*picker modal*/
.borderZero .modal-content {
  border: 0;
}

.avatarRow {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-right: 0;
  margin-left: 0;

  &:after {
    content: "";
    flex: 0 0 50%;
  }
}

.avatarRow .col-25 {
  width: -webkit-calc((100% - 16px*3)/ 4);
  width: calc((100% - 16px*3)/ 4);
}

.moneylist-modal {
  margin: 0;
}

.moneylist-modal .modal-content {
  border: 0;
}

.register-tab .mdc-tab {
  line-height: 43px;
  height: 43px;
  font-size: 14px;
  background-color: white;
}

.register-tab .mdc-tab--active {
  color: var(--theme-color);
}

.register-tab .mdc-tab__content {
  padding-bottom: 0;
}

.register-tab .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  opacity: 1;
  background-color: #eaeaea;
  height: 1.5px;
}

.register-tab .mdc-tab--active .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  background-color: var(--theme-color);
  height: 2px;
}

.register-content .react-swipeable-view-container {
  height: calc((var(--auto)) - 87px) !important;
}

.list-block {
  margin: 5px 0;
  background: #fff;
}

.form-ul {
  padding: 0;
  margin-bottom: 0;
}

.list-block ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.list-block .item-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 40px;
  font-size: 14px;
}

.list-block .item-media span {
  font-size: 14px;
  color: rgba(0, 0, 0, .65);
}

.list-block .input-item .item-media,
.list-block.inputs-list .item-media {
  -ms-flex-item-align: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  min-height: 36px;
  margin-bottom: 8px;
  padding: 0;
}


.list-block .item-inner:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: auto;
  top: auto;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, .12);
  display: block;
  z-index: 15;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  opacity: 0.3;
}

.register-content .list-block .item-inner:after {
  height: 0;
}

.list-block .item-after.color-red {
  color: #f44336;
}

.list-block .item-after-blod {
  font-weight: bold;
}

.list-block .item-media+.item-inner {
  margin-left: 16px;
}

.list-block .input-item .item-inner,
.list-block.inputs-list .item-inner {
  display: block;
  margin-bottom: 4px;
  padding-bottom: 0;
}

.list-block .item-input {
  width: 100%;
  -ms-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex-shrink: 1;
  -ms-flex: 0 1 auto;
  flex-shrink: 1;
  font-size: 0;
  position: relative;
  margin-bottom: 4px;
  min-height: 36px;
}

.list-block input[type=date],
.list-block input[type=datetime-local],
.list-block input[type=email],
.list-block input[type=number],
.list-block input[type=password],
.list-block input[type=search],
.list-block input[type=tel],
.list-block input[type=text],
.list-block input[type=time],
.list-block input[type=url],
.list-block select,
.list-block textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: none;
  background: 0 0;
  border-radius: 0;
  box-shadow: none;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 36px;
  color: #212121;
  font-size: 14px;
  font-family: inherit;
}

.item-input-field:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: auto;
  top: auto;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, .12);
  display: block;
  z-index: 15;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.item-input-field:after {
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
}

.input-field::placeholder {
  opacity: .5;
}

.item-after input::placeholder {
  opacity: .5;
}

.register-content .item-input-field:after {
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
}

.item-input-field.focus-state:after {
  background: var(--theme-color);
  transform: scaleY(2);
}

.register-content .warning {
  font-size: 14px;
  color: red;
}

.item-code img {
  height: 36px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.number-picker .modal {
  top: unset;
  bottom: calc(0px + var(--safe-bottom));
}

.number-picker .modal-dialog {
  margin: 0;
}

.number-picker .modal.fade .modal-dialog {
  transition: transform 0.3s;
  transform: translate3d(0, 100%, 0);
}

.number-picker .modal.show .modal-dialog {
  transform: none;
}

.number-picker .modal,
.number-picker .modal-backdrop {
  height: auto;
}

.number-picker .modal-content {
  border-radius: 0;
  border: none;
}


.f7-button {
  text-decoration: none;
  text-align: center;
  display: block;
  border-radius: 6px;
  line-height: 36px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: 0 0;
  padding: 0 10px;
  margin: 0;
  height: 36px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  text-transform: uppercase;
  font-family: inherit;
  cursor: pointer;
  min-width: 64px;
  padding: 0 8px;
  position: relative;
  overflow: hidden;
  outline: 0;
  border: none;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background: var(--theme-color);
  color: #fff;
}

.money-conversion-button-withBackground {
  line-height: 40px !important;
  height: 40px !important;
  border-radius: 7px !important;
  color: #fff !important;
  border: 2px solid var(--theme-color);
  font-size: 30px;
  width: calc(50% - 5px);
  margin-left: 5px;
  margin-bottom: 5px;
  // float:left;
  width: 100%;
}

.money-conversion-button-withoutBackground {
  line-height: 40px !important;
  height: 40px !important;
  border-radius: 7px !important;
  color: var(--theme-color);
  background-color: #fff;
  border: 2px solid var(--theme-color);
  font-size: 30px;
  width: calc(50% - 5px);
  margin-left: 5px;
  margin-bottom: 5px;
  // float:left;
  width: 100%;
}

.team-detail-button {
  line-height: 40px !important;
  height: 40px !important;
  border-radius: 7px !important;
  background: #fff;
  color: var(--theme-color);
  border: 2px solid var(--theme-color);
  font-size: 30px;
}

.team-detail-button-text,
.money-conversion-button-text {
  font-size: 15px;
  vertical-align: top;
  padding: 10px;
}

.f7-button:hover {
  color: white;
  text-decoration: none;
}

.team-detail-button:hover {
  color: var(--theme-color);
}

.phone-email-validcode-span {
  position: absolute;
  right: 150px;
  bottom: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, .3);
  line-height: 36px;
}

.phone-email-validcode-a {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 14px;
  color: grey !important;
  line-height: 36px;
  width: 150px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

.phone-email-validcode-a:focus,
.phone-email-validcode-a:hover {
  color: #2196f3;
  text-decoration: none;
}

.content-block-title {
  position: relative;
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1;
  margin: 16px 16px 16px;
  padding-top: 16px;
  line-height: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, .54);
}

.content-block-title {
  margin: 14px 16px;
}

.pt0 {
  padding-top: 0;
}

.pt10 {
  padding-top: 15;
}

.list-block.ptb12 {
  padding: 12px 0;
}

.font12 {
  font-size: 12px !important;
}

i.icon {
  display: inline-block;
  vertical-align: middle;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  font-style: normal;
  position: relative;
}

.label-checkbox i.icon-form-checkbox {
  width: 18px;
  height: 18px;
  position: relative;
  border: 1px solid #6d6d6d;
  border-radius: 50%;
  box-sizing: border-box;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  background: 0 0;
  display: block;
}

.label-checkbox i.icon-form-checkbox:after {
  content: ' ';
  position: absolute;
  width: 18px;
  height: 18px;
  left: -1px;
  top: -1px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  opacity: 0;
  background: no-repeat center;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%…6.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.label-checkbox input[type=checkbox]:checked+.item-media i.icon-form-checkbox,
.label-checkbox input[type=checkbox]:checked~.item-after i.icon-form-checkbox,
.label-checkbox input[type=checkbox]:checked~.item-inner i.icon-form-checkbox,
.theme-red label.label-checkbox input[type=radio]:checked+.item-media i.icon-form-checkbox,
.label-checkbox input[type=radio]:checked~.item-after i.icon-form-checkbox,
.label-checkbox input[type=radio]:checked~.item-inner i.icon-form-checkbox,
.label-checkbox.theme-red input[type=checkbox]:checked+.item-media i.icon-form-checkbox,
.label-checkbox.theme-red input[type=checkbox]:checked~.item-after i.icon-form-checkbox,
.label-checkbox.theme-red input[type=checkbox]:checked~.item-inner i.icon-form-checkbox,
.label-checkbox.theme-red input[type=radio]:checked+.item-media i.icon-form-checkbox,
.label-checkbox.theme-red input[type=radio]:checked~.item-after i.icon-form-checkbox,
.label-checkbox.theme-red input[type=radio]:checked~.item-inner i.icon-form-checkbox {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
}

.label-checkbox i.icon-form-checkbox:after,
.label-checkbox.theme-red i.icon-form-checkbox:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
}

.label-checkbox input[type=checkbox]:checked+.item-media i.icon-form-checkbox:after {
  opacity: 1;
}

.label-checkbox input[type=checkbox] {
  display: none;
}

.label-checkbox,
.label-radio {
  position: relative;
  overflow: hidden;
  z-index: 0;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.psd-manage-page,
.offline-draw-page,
.detail-edit-page {
  .list-block.inputs-list .label {
    font-size: 14px;
  }
}

/*offline draw page*/
.offline-draw-page {
  .vertical-line {
    float: left;
    border: 0.7px solid #eaeff2;
    height: 1.5rem;
    margin: auto;
  }

  .list-block li {
    margin: 10px;
  }

  .list-block .item-content {
    display: flex !important;
  }

  .item-input-field:after,
  .list-block .item-inner:after {
    background-color: transparent;
  }

  .underline:not(:last-child) {
    border-bottom: 1px solid #eaeff2;
    padding: 0px 10px;
  }

  .button-withBackground {
    line-height: 40px !important;
    height: 45px !important;
    border-radius: 10px !important;
    font-size: 23px;
    width: calc(50% - 5px);
    width: 90%;
    margin: auto;
    color: #fff;
  }
}

/*Level page*/
.table_1 {
  text-align: center;
  width: 100%;
}

.table_1 .tr_1 td {
  background: #f4f4f4;
}

.table_1 td,
.table_1 th {
  height: 40px;
  border-bottom: solid 1px #eaeaea;
  width: 33.33%;
  border-right: solid 1px #eaeaea;
  vertical-align: middle;
  font-size: 14px;
}

i.rich0,
i.rich1,
i.rich2,
i.rich3,
i.rich4,
i.rich5,
i.rich6,
i.rich7,
i.rich8,
i.rich9,
i.rich10,
i.rich11,
i.rich12,
i.rich13,
i.rich14,
i.rich15,
i.rich16,
i.rich17,
i.rich18,
i.rich19,
i.rich20,
i.rich21,
i.rich22,
i.rich23,
i.rich24,
i.rich25,
i.rich26,
i.rich27,
i.rich28,
i.rich29,
i.rich30,
i.rich31,
i.rich32,
i.rich33,
i.rich34,
i.rich35,
i.rich36,
i.rich37,
i.rich38,
i.rich39,
i.rich40,
i.rich41,
i.rich42,
i.rich43,
i.rich44,
i.rich45,
i.rich46,
i.rich47,
i.rich48,
i.rich49,
i.rich50,
i.rich51,
i.rich52,
i.rich53,
i.rich54,
i.rich55,
i.rich56,
i.rich57,
i.rich58,
i.rich59,
i.rich60,
i.rich61,
i.rich62,
i.rich63,
i.rich64,
i.rich65,
i.rich66,
i.rich67 {
  margin-right: 4px;
  margin-top: -2px;
  width: 35px;
  background: url("../../assets/image/grade/rich.png") no-repeat 0 0;
  position: relative;
  height: 14px;
  vertical-align: middle;
  margin-bottom: 0;
  display: inline-block;
}

i.rich0 {
  background-position: -1050px -1px
}

i.rich1 {
  background-position: 0 -25px
}

i.rich2 {
  background-position: -50px -25px
}

i.rich3 {
  background-position: -100px -25px
}

i.rich4 {
  background-position: -150px -25px
}

i.rich5 {
  background-position: -200px -25px
}

i.rich6 {
  background-position: -250px -25px
}

i.rich7 {
  background-position: -300px -25px
}

i.rich8 {
  background-position: -350px -25px
}

i.rich9 {
  background-position: -400px -25px
}

i.rich10 {
  background-position: -450px -25px
}

i.rich11 {
  background-position: -500px -25px
}

i.rich12 {
  background-position: -550px -25px
}

i.rich13 {
  background-position: -600px -25px
}

i.rich14 {
  background-position: -650px -25px
}

i.rich15 {
  background-position: -700px -25px
}

i.rich16 {
  background-position: -750px -25px
}

i.rich17 {
  background-position: -800px -25px
}

i.rich18 {
  background-position: -850px -25px
}

i.rich19 {
  background-position: -900px -25px
}

i.rich20 {
  background-position: -950px -25px
}

i.rich21 {
  background-position: -1000px -25px
}

i.rich22 {
  background-position: -1050px -25px
}

i.rich23 {
  background-position: -1100px -25px
}

i.rich24 {
  background-position: -1150px -25px
}

i.rich25 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background-position: -1200px -21px;
  position: relative
}

i.rich26 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-26.png") no-repeat 0 0;
  position: relative
}

i.rich27 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-27.png") no-repeat center 0;
  position: relative
}

i.rich28 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-28.png") no-repeat 0 0;
  position: relative
}

i.rich29 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-29.png") no-repeat center 0;
  position: relative
}

i.rich30 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-30.png") no-repeat 0 0;
  position: relative
}

i.rich31 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-31.png") no-repeat center 0;
  position: relative
}

i.rich32 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-32.png") no-repeat 0 0;
  position: relative
}

i.rich33 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-33.png") no-repeat center 0;
  position: relative
}

i.rich34 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-34.png") no-repeat 0 0;
  position: relative
}

i.rich35 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-35.png") no-repeat center 0;
  position: relative
}

i.rich36 {
  width: 50px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-36.png") no-repeat 0 0;
  position: relative
}

i.rich37 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-37.png") no-repeat center 0;
  position: relative
}

i.rich38 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-38.png") no-repeat 0 0;
  position: relative
}

i.rich39 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-39.png") no-repeat center 0;
  position: relative
}

i.rich40 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-40.png") no-repeat 0 0;
  position: relative
}

i.rich41 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-41.png") no-repeat center 0;
  position: relative
}

i.rich42 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-42.png") no-repeat 0 0;
  position: relative
}

i.rich43 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-43.png") no-repeat center 0;
  position: relative
}

i.rich44 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-44.png") no-repeat 0 0;
  position: relative
}

i.rich45 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-45.png") no-repeat center 0;
  position: relative
}

i.rich46 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-46.png") no-repeat 0 0;
  position: relative
}

i.rich47 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-47.png") no-repeat center 0;
  position: relative
}

i.rich48 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-48.png") no-repeat center 0;
  position: relative
}

i.rich49 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-49.png") no-repeat center 0;
  position: relative
}

i.rich50 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-50.png") no-repeat center 0;
  position: relative
}

i.rich51 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-51.png") no-repeat center 0;
  position: relative
}

i.rich52 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-52.png") no-repeat center 0;
  position: relative
}

i.rich53 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-53.png") no-repeat center 0;
  position: relative
}

i.rich54 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-54.png") no-repeat center 0;
  position: relative
}

i.rich55 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-55.png") no-repeat center 0;
  position: relative
}

i.rich56 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-56.png") no-repeat center 0;
  position: relative
}

i.rich57 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-57.png") no-repeat center 0;
  position: relative
}

i.rich58 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-58.png") no-repeat center 0;
  position: relative
}

i.rich59 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-59.png") no-repeat center 0;
  position: relative
}

i.rich60 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-60.png") no-repeat center 0;
  position: relative
}

i.rich61 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-61.png") no-repeat center 0;
  position: relative
}

i.rich62 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-62.png") no-repeat center 0;
  position: relative
}

i.rich63 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-63.png") no-repeat center 0;
  position: relative
}

i.rich64 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-64.png") no-repeat center 0;
  position: relative
}

i.rich65 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-65.png") no-repeat center 0;
  position: relative
}

i.rich66 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-66.png") no-repeat center 0;
  position: relative
}

i.rich67 {
  width: 75px;
  height: 18px;
  margin-right: 2px;
  background: url("../../assets/image/grade/rich-67.png") no-repeat center 0;
  position: relative
}

/*lock psd manage page*/
.lock-psd-manage-page {
  .label-switch {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 14px;
    position: relative;
    cursor: pointer;
    -ms-flex-item-align: center;
    -webkit-align-self: center;
    align-self: center;
  }

  .label-switch input[type=checkbox] {
    display: none;
  }

  .label-switch .checkbox {
    width: 36px;
    border-radius: 36px;
    box-sizing: border-box;
    height: 14px;
    background: #b0afaf;
    z-index: 0;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    position: relative;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
  }

  .label-switch .checkbox:after {
    content: ' ';
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    z-index: 2;
    top: -3px;
    left: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .4);
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
  }
}

/*detail edit page*/
.detail-edit-page {
  .list-block ul:before {
    -webkit-transform: scaleY(.33);
    transform: scaleY(.33);
  }

  .list-block ul:after {
    -webkit-transform: scaleY(.33);
    transform: scaleY(.33);
  }

  .list-block .item-inner:after,
  .item-input-field:after {
    background: none;
  }

  .list-block .item-content {
    display: flex !important;
  }

  .list-block .item-media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0;
    -webkit-box-lines: single;
    -moz-box-lines: single;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: none;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    box-sizing: border-box;
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 40px;
    font-size: 14px !important;
  }

  .item-media .detaileditImg {
    width: auto;
    height: auto;
    max-width: 40px;
  }

  .list-block .not-empty-state .item-input-field:after,
  .list-block .not-empty-state.input-field:after {
    /* background: rgba(0,0,0,.12); */
    transform: none !important;
  }

  /* .greyStyle{
      color: rgba(0,0,0,.40) !important;
  } */

  .phone-email-validcode-span {
    position: absolute;
    right: 100px;
    bottom: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, .3);
    line-height: 36px;
  }

  .phone-email-validcode-a {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 14px;
    color: #2196f3;
    line-height: 36px;
    width: 100px;
    text-align: center;
  }
}

/*account setting page*/
.account-setting-page,
.custom-setting-page {

  .switch2 {
    position: relative;
    display: inline-block;
    width: 79px;
    height: 30px;
  }

  .switch2 input {
    display: none;
  }

  .slideDown {
    padding-right: 16px;
    position: relative;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 48px;
    box-sizing: border-box;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    min-width: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    -webkit-align-self: stretch;
    align-self: stretch;
  }

  /* The container */

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */

  .container input:checked~.checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */

  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  /* The Modal (background) */

  .modal2 {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 450px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
  }

  /* Modal Content */

  /* .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 14px;
      border: 1px solid #888;
      width: 80%;
      border-radius: 25px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    } */

  /* The Close Button */

  .close2 {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close2:hover,
  .close2:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked+.slider {
    background-color: #f44336;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #f44336;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .on,
  .off {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
  }

  .on {
    transform: translate(-150%, -50%);
  }

  .off {
    transform: translate(50%, -50%);
  }
}

/*money conversion page*/
.money-conversion-page {
  .icon_swap_vert {
    background: transparent;
    height: 45px;
    position: relative;
    z-index: 100;
  }

  .icon_swap_vert-li {
    margin-top: -20px;
    margin-bottom: -20px;
    justify-content: center;
    display: flex;
  }

  .inputAmount {
    background-color: #eaeff2 !important;
    border-radius: 7px !important;
    border-bottom: aliceblue !important;
    padding-left: 10px !important;
  }

  .item-input-field:after {
    height: 0px;
  }

  .toolbar {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin: 0;
    z-index: 500;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    color: #fff;
  }

  .toolbar-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}

.custom-bet-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content {
    background-color: #fefefe !important;
    margin: auto;
    padding: 14px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 25px;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .custom-bet-title {
    text-align: center;
    border-bottom: 1.5px solid #bbbbbb;
    ;
    padding-bottom: 8px;
  }

  .custom-bet-input {
    background-color: lightgray !important;
    margin: 10px auto auto !important;
    border-radius: 25px !important;
    padding-left: 10px;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    width: 100%;
    height: 36px;
    font-size: 14px;
  }

  .cancel-btn {
    color: #7f7777f5 !important;
  }

  .confirm-btn {
    // background: #f44336;
    color: #fff !important;
  }
}

/*custom setting page*/
.custom-setting-page {
  .toggleOnOff {
    font-size: 13px;
    justify-content: end;
  }

  .switch-customSetting .react-switch-bg>div:first-child {
    margin-left: 6px;
  }

  .list-block .item-inner {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .switch2 {
    margin: 0;
  }

  input:not(:checked)+.slider2 {
    background-color: #999;
  }

  .slider2 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }

  .slider2:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 8px;
    bottom: 5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:focus+.slider2 {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.slider2:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(55px);
    left: -3px;
  }

  /*------ ADDED CSS ---------*/

  .slider2:after {
    content: '默认';
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 62%;
    font-size: 13px;
    font-family: Verdana, sans-serif;
  }

  input:checked+.slider2:after {
    content: '自定义';
    left: 40%;
  }

  .toggle-switch2 {
    position: relative;
    width: 82px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
  }

  .toggle-switch2-checkbox {
    display: none;
  }

  .toggle-switch2-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #ccc;
    border-radius: 20px;
    margin: 0;
  }

  .toggle-switch2-checkbox:checked+.toggle-switch2-label .toggle-switch2-inner {
    margin-left: 0;
  }

  .toggle-switch2-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }

  .toggle-switch2-inner::before,
  .toggle-switch2-inner::after {
    display: block;
    float: left;
    width: 50%;
    height: 34px;
    padding: 0;
    line-height: 34px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }

  .toggle-switch2-inner:before {
    content: "自定义";
    text-transform: uppercase;
    padding-left: 12px;
    color: #fff;
  }

  .toggle-switch2-inner::after {
    content: "默认";
    text-transform: uppercase;
    padding-right: 13px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
  }

  .toggle-switch2-switch {
    display: block;
    width: 17px;
    margin: 8px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #ccc;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }

  .toggle-switch2-checkbox:checked+.toggle-switch2-label .toggle-switch2-switch {
    right: 0px;
  }
}

.input-inline {
  background-color: #eaeff2 !important;
  border-radius: 5px !important;
  padding-left: 10px !important;
}

.input-inlinelink {
  background-color: #F9F9F9 !important;
  border: none;
  border-radius: 5px !important;
  padding-left: 10px !important;
  width: 70%;
  height: 40px;
  font-size: 15px;
  opacity: 1;
}

/*My Bank Add Page*/
.my-bank-add-page {
  height: 100%;

  .item-inner {
    display: block;
    margin-bottom: 4px;
    padding-bottom: 0;
  }

  .item-input-field:after,
  .list-block .item-inner:after {
    background-color: transparent;
  }

  .modal.fade.show {
    /*to prevent autocomplete css {opacity:0} is not overide picker modal css*/
    opacity: 1 !important;
  }

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    background-color: #eaeff2 !important;
    border-radius: 5px !important;
    padding-left: 10px !important;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    // top: 51px;
    // width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    // font-family: Helvetica, sans-serif;
    // font-weight: 300;
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;

    overflow-y: auto;
    max-height: 500px;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
}

/*sign in page*/
.signinpage {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  #Signin .userface {
    height: 100%;
    overflow: hidden;
  }

  .SigninGiftA {
    float: right;
    font-size: .38rem;
    line-height: .95rem;
  }

  .SigninGiftA a {
    color: #fff;
  }

  .SigninNum {
    clear: both;
    padding: 40px 0;
    height: 100%;
    overflow: hidden;
    background-image: url("../../assets/image/user-bg.png");
    background-size: 100% 100%;
  }

  .SigninNum .col-33 {
    width: 33.33333%;
  }

  .SigninNum dl {
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .SigninNum dt {
    font-size: 22px;
    line-height: 1;
    padding: 0;
    font-weight: bold;
    margin: 0 0 4px;
  }

  .SigninNum dd {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    padding: 0;
    margin: 0;
  }

  .SigninList {
    clear: both;
    padding: 1rem 0.4rem;
    height: 100%;
    overflow: hidden;
  }

  .SigninList dl {
    width: 20%;
    float: left;
    font-size: 0.7rem;
    text-align: center;
    padding: 0;
    margin: 10px 0;
  }

  .SigninList dt {
    color: #999;
    padding: 0;
    margin: 0 auto 5px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
  }

  .SigninList dt.noborder {
    color: #737373;
  }

  .SigninList dt img {
    width: 100%;
  }

  .SigninList dd {
    color: #737373;
    padding: 0;
    margin: 0;
  }

  .SigninBottom {
    clear: both;
    padding: 20px 10px;
    text-align: center;
    position: relative;
  }

  .SigninButton {
    width: 55%;
    display: inline-block;
    color: #fff !important;
    font-size: 20px;
    text-align: center;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
  }

  .SigninButton-s {
    background: #ccc;
    width: 55%;
    display: inline-block;
    color: #fff !important;
    font-size: 20px;
    text-align: center;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
  }

  .SigninQ {
    color: #999;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #999 !important;
    border: 1px solid #999;
    border-radius: 50%;
    font-size: 14px;
    display: inline-block;
    position: absolute;
    top: 24px;
    left: 80%;
  }

  .SigninTip {
    color: #bbb;
    margin: 20px 30px;
    font-size: 12px;
    line-height: 1.8;
  }

  .activityRule {
    margin: 20px 20px;
  }

  .activityRule p {
    font-size: 14px;
    line-height: 1.7em;
    color: #666;
    margin-bottom: 10px;
  }

  .activityRule .table_1 {
    border: solid 1px #dcdcdc;
    border-right: none;
    border-bottom: none;
    font-size: 14px;
    text-align: center;
    width: 100%;
    margin: 20px auto;
  }

  .activityRule .table_1 td {
    height: 45px;
    border-bottom: solid 1px #dcdcdc;
    width: 50%;
    border-right: solid 1px #dcdcdc;
    vertical-align: middle;
  }

  .activityRule .table_1 .tr_1 td {
    background: #0082D6;
    color: #fff;
    border-right-style: none;
  }

  @media screen and (max-width: 325px) {
    .activityRule .table_1 {
      font-size: 14px;
    }
  }

  .activityRule .Signgzimg {
    width: 100%;
    text-align: center;
    margin: 30px 0;
  }

  .activityRule .Signgzimg img {
    width: 100%;
    min-width: 200px;
    max-width: 690px;
  }
}

/*leave message Page*/
.leave-message-page {
  .toolbar {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin: 0;
    z-index: 500;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    color: #fff;
  }
}

/*question page*/
.question-page {
  .messageCard {
    box-shadow: 0 0 8px 0 #f1f1f1;
    border-radius: 3px;
    background-color: #f4f4f4;
    border: 1px solid #e0e0e0;
    width: 100%;
    padding: 5px 0;
  }
}

.horizontal-line {
  height: 1px;
  width: 92%;
  background-color: #f2f2ef;
  margin: auto;
  margin-top: 10px;
}

.question-page,
.leave-message-page {
  .item-input-field:after {
    background-color: transparent;
  }

  // .item-media.col{//this is for right align
  //   justify-content: flex-end;
  // }
  .imgLogo {
    border-radius: 32px;
    width: 40px;
    height: 40px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    margin-right: 10px;
    align-self: center;
    min-width: 40px;
  }
}

.docdetail-page {
  .subtitle a span {
    color: var(--theme-color) !important;
  }
}

.user-checkin {
  background: rgba(0, 0, 0, 0.2);
  height: 26px;
  line-height: 26px;
  border-radius: 13px 0 0 13px;
  padding: 0 5px 0 7px;
  position: absolute;
  right: 0;
  top: 12px;
  font-size: 12px;
}

.user-proxy {
  display: inline-block;
  background: rgba(0, 0, 0, 0.2);
  height: 18px;
  line-height: 18px;
  border-radius: 4px;
  padding: 0 8px;
  margin-top: 2px;
  font-size: 12px;
}

.user-data {
  margin-top: 0;
  padding: 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.user-data .row .col-33 {
  width: 33.33333%;
  border-right: 1px solid #f0f0f0;
}

.user-data dl {
  margin: 0;
  padding: 10px 0;
  text-align: center;
}

.user-data dt {
  margin: 0;
  padding: 0;
  font-size: 15px;
  color: var(--theme-color);
  font-weight: 100;
}

.user-data dd {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #666;
}

.user-money1 {
  background: #fff;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  /* padding:16px; */
  margin-bottom: 0;
  margin-top: 15px;
}

.user-money1 i {
  vertical-align: middle;
}

.button.button-fill.bg-red,
.button.button-fill.color-red {
  background: #f44336;
  color: #fff !important;
}

.button.button-fill.bg-blue,
.button.button-fill.color-blue {
  background: #2196f3;
  color: #fff !important;
}

.button.button-fill.loginOut {
  background: #f44336;
  color: #fff !important;
  text-decoration: none;
}

.login-bar {
  background: #fff !important;
  box-shadow: none !important;
  height: 44px !important;
  border-bottom: 1px solid #f0f0f0;
}

.item-code img {
  height: 36px;
  position: absolute;
  right: 0;
  bottom: 0;
}

input.modal-text-input {
  box-sizing: border-box;
  height: 36px;
  background: #fff;
  margin: 0;
  margin-top: 15px;
  padding: 0;
  border: none;
  width: 100%;
  font-size: 14px;
  font-family: inherit;
  display: block;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
}

input.custom-placeholder::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.transaction-page .mdc-tab {
  line-height: 43px;
  height: 43px;
  font-size: 14px;
  background-color: white;
  ;
}

.transaction-page .mdc-tab--active .mdc-tab__content {
  color: var(--theme-color);
}

.transaction-page .mdc-tab__content {
  color: #b7b7b7;
}

.transaction-page .mdc-tab--active .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  background-color: var(--theme-color);
}


.transaction-record-list li a.item-link.item-content .small-text {
  font-size: 14px;
  line-height: 1;
  padding: 1px 5px;
  border-radius: 2px;
  margin-right: 4px;
}

.transaction-record-list li a.item-link.item-content .grey {
  background: #b7b7b7;
  color: #fff !important;
}

.transaction-record-list li a.item-link.item-content .red {
  background: #dc3b40;
  color: #fff !important;
}

.transaction-record-list li a.item-link.item-content .blue {
  background: #38f;
  color: #fff !important;
}

.transaction-record-list li a.item-link.item-content .violet {
  background: #6b73f5;
  color: #fff !important;
}

.transaction-record-list li a.item-link.item-content .green {
  background: #6fe26f;
  color: #fff !important;
}

.transaction-record-list li a.item-link.item-content .yellow {
  background: #ffc200;
  color: #fff !important;
}

.transaction-record-list li a.item-link.item-content::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: auto;
  top: auto;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  display: block;
  z-index: 15;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  opacity: 0.5;
}

.aboutproxy-page .mdc-tab {
  line-height: 43px;
  height: 43px;
  font-size: 12px;
  background-color: white;
}

.aboutproxy-page .mdc-tab__content {
  padding: 0;
}

.aboutproxy-page .mdc-tab--active .mdc-tab__content {
  color: var(--theme-color);
}

.aboutproxy-page .mdc-tab--active {
  color: var(--theme-color);
}

.aboutproxy-page .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  transform: translate3d(0%, 0px, 0px);
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  background: var(--theme-color);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 16 !important;
}

.game-list-tab {
  margin: 15px 0;
}

.game-list-tab .mdc-tab-scroller__scroll-area--scroll {
  margin: auto;
  width: calc(100vw - 40px);
}

.game-list-tab .mdc-tab__content {
  padding-bottom: 0;
}

.game-list-tab .mdc-tab {
  line-height: 28px;
  height: 28px;
  padding: 0 16px;
  font-size: 13px;
  min-width: 75px;
  margin-right: 10px;
  border-radius: 25px;
  border: 1px solid white;
}

.game-list-tab .mdc-tab--active {
  color: var(--theme-color);
  background-color: #f7f7f7;
  border: 1px solid #efefef;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15) !important;
}

.green-theme {
  padding: 0 15px;

  .mdc-tab {
    border-color: #DDDDDD;
  }

  .mdc-tab--active {
    background-color: var(--theme-color);
    color: white;
  }
}

.game-list-tab .mdc-tab-scroller__scroll-content {
  overflow-y: hidden;
  padding: 5px 0;
}

.game-list-tab .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  display: none;
  background-color: var(--theme-color);
}

.game-list {
  padding: 0;
}

.game-list dl {
  text-align: center;
  margin: 0 0 20px;
  position: relative;
}

.game-list dl a {
  display: block;
}

.game-list dt {
  position: relative;
}

.game-list dt img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.game-list dt img.bq2 {
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -25px;
  border-radius: 0;
}

.game-list img.bq2-en {
  position: absolute;
  width: 45px;
  height: 45px;
  top: -3px;
  right: -3px;
}

.game-list dd {
  color: #212121;
  margin: 7px 0 0;
  font-size: 10px;
}

.game-tip {
  color: #999;
  font-size: 12px;
  margin-top: 1px;
}

.game-tip span {
  color: #fff;
  background: #f44336;
  border-radius: 9px;
  padding: 0 5px;
  display: inline-block;
}

.point {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  border: 2px solid #f44336;
  vertical-align: middle;
  margin-right: 5px;
}

.proxy-title {
  padding: 12px;
  font-size: 15px;
  line-height: 1;
}

.proxy-box {
  padding: 16px;
  background: #fff;
}

.proxy-box p {
  margin: 0 0 10px;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.proxy-box.list-block {
  margin: 0;
  padding: 0;
}

.proxy-card-box {
  margin: 0;
  border: 1px solid #f44336;
}

.proxy-card-1 {
  background: #f44336;
  color: #fff;
  text-align: center;
  font-size: 14px;
  padding: 6px 0;
}

.proxy-card-2 {
  padding: 8px 0 0;
}

.proxy-card-3 {
  padding: 8px 0;
  font-size: 15px;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.proxy-card {
  text-align: center;
  margin-bottom: 20px;
  padding: 10px 15px 15px;
}

.row>[class*=col-] {
  box-sizing: border-box;
}

.row .col-33 {
  width: 33.333333333333336%;
}

.lottery-list1 {
  background: #fff;
  padding: 0;
  margin-bottom: 0;
}

.lottery-list1 dl {
  float: left;
  width: 50%;
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
}

.lottery-list1 dl a {
  display: block;
  border-right: 1px solid #f4f4f4;
  padding: 15px 0 15px 13px;
  height: 82px;
  overflow: hidden;
}

.lottery-list1 dt {
  float: left;
  line-height: 0;
  position: relative;
}

.lottery-list1 dt img {
  width: 52px;
  border-radius: 8px;
}

.lottery-list1 dt img.bq2 {
  position: absolute;
  top: -1px;
  left: 50%;
  margin-left: -25px;
}

.lottery-list1 dd {
  color: #212121;
  margin: 5px 0 0 62px;
  font-size: 15px;
}

.lottery-tip {
  font-size: 13px;
  margin: 5px 0;
  padding: 0 10px;
  position: relative;
}

.lottery-list1 .lottery-tip {
  color: #999;
  font-size: 13px;
  margin: 3px 0 0 62px;
  padding: 0;
}

.searchbar {
  padding: 0 16px;
  width: 100%;
  height: 32px;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex-shrink: 1;
  -ms-flex: 0 1 auto;
  flex-shrink: 1;
}

.searchbar-input {
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  display: block;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border-radius: 0;
  font-family: inherit;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  border-bottom: 1px solid #fff;
  padding: 0 15px 0 24px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0 center;
  opacity: .6;
  -webkit-background-size: 24px 24px;
  background-size: 24px 24px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20fill%3D'%23FFFFFF'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%20width%3D'24'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M15.5%2014h-.79l-.28-.27C15.41%2012.59%2016%2011.11%2016%209.5%2016%205.91%2013.09%203%209.5%203S3%205.91%203%209.5%205.91%2016%209.5%2016c1.61%200%203.09-.59%204.23-1.57l.27.28v.79l5%204.99L20.49%2019l-4.99-5zm-6%200C7.01%2014%205%2011.99%205%209.5S7.01%205%209.5%205%2014%207.01%2014%209.5%2011.99%2014%209.5%2014z'%2F%3E%3Cpath%20d%3D'M0%200h24v24H0z'%20fill%3D'none'%2F%3E%3C%2Fsvg%3E");
}

.searchbar-clear {
  position: absolute;
  width: 56px;
  height: 100%;
  right: -16px;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: 24px 24px;
  background-size: 24px 24px;
  transition-duration: .3s;
  cursor: pointer;
}

.searchbar-input::-webkit-input-placeholder {
  /* Edge */
  color: #fff;
}

.searchbar-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.searchbar-input::placeholder {
  color: #fff;
}

.thirdLoginDiv {
  background-color: var(--theme-color) !important;
  background: var(--theme-color) !important;
}

.page-content {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  height: calc(100% - var(--safe-bottom));
  position: relative;
  z-index: 1;
  // padding-top:44px;
}

.preloader {
  font-size: 0;
  display: inline-block;
  width: 32px;
  height: 32px;
  -webkit-animation: preloader-outer 3.3s linear infinite;
  animation: preloader-outer 3.3s linear infinite
}

.preloader svg {
  width: 100%;
  height: 100%;
  -webkit-animation: preloader-inner 1.32s linear infinite;
  animation: preloader-inner 1.32s linear infinite
}

.preloader svg circle {
  fill: none;
  stroke: #757575;
  stroke-linecap: square;
  -webkit-animation: preloader-arc 1.32s cubic-bezier(.8, 0, .4, .8) infinite;
  animation: preloader-arc 1.32s cubic-bezier(.8, 0, .4, .8) infinite
}

@-webkit-keyframes preloader-outer {
  0% {
    -webkit-transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg)
  }
}

@keyframes preloader-outer {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes preloader-inner {
  0% {
    -webkit-transform: rotate(-100.8deg)
  }

  100% {
    -webkit-transform: rotate(0)
  }
}

@keyframes preloader-inner {
  0% {
    transform: rotate(-100.8deg)
  }

  100% {
    transform: rotate(0)
  }
}

@-webkit-keyframes preloader-arc {
  0% {
    stroke-dasharray: 1 210.48670779px;
    stroke-dashoffset: 0
  }

  40% {
    stroke-dasharray: 151.55042961px, 210.48670779px;
    stroke-dashoffset: 0
  }

  100% {
    stroke-dasharray: 1 210.48670779px;
    stroke-dashoffset: -151.55042961px
  }
}

@keyframes preloader-arc {
  0% {
    stroke-dasharray: 1 210.48670779px;
    stroke-dashoffset: 0
  }

  40% {
    stroke-dasharray: 151.55042961px, 210.48670779px;
    stroke-dashoffset: 0
  }

  100% {
    stroke-dasharray: 1 210.48670779px;
    stroke-dashoffset: -151.55042961px
  }
}

.preloader-inner {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  -webkit-animation: preloader-inner-rotate 5.25s cubic-bezier(.35, 0, .25, 1) infinite;
  animation: preloader-inner-rotate 5.25s cubic-bezier(.35, 0, .25, 1) infinite
}

.preloader-inner .preloader-inner-gap {
  position: absolute;
  width: 2px;
  left: 50%;
  margin-left: -1px;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  border-top: 4px solid #757575
}

.preloader-inner .preloader-inner-left,
.preloader-inner .preloader-inner-right {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  overflow: hidden
}

.preloader-inner .preloader-inner-half-circle {
  position: absolute;
  top: 0;
  height: 100%;
  width: 200%;
  box-sizing: border-box;
  border: 4px solid #757575;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 1.3125s;
  -webkit-animation-timing-function: cubic-bezier(.35, 0, .25, 1);
  animation-iteration-count: infinite;
  animation-duration: 1.3125s;
  animation-timing-function: cubic-bezier(.35, 0, .25, 1)
}

.preloader-white .preloader-inner .preloader-inner-gap,
.preloader-white .preloader-inner .preloader-inner-half-circle {
  border-color: #fff
}

.preloader-inner .preloader-inner-left {
  left: 0
}

.preloader-inner .preloader-inner-left .preloader-inner-half-circle {
  left: 0;
  border-right-color: transparent !important;
  -webkit-animation-name: preloader-left-rotate;
  animation-name: preloader-left-rotate
}

.preloader-inner .preloader-inner-right {
  right: 0
}

.preloader-inner .preloader-inner-right .preloader-inner-half-circle {
  right: 0;
  border-left-color: transparent !important;
  -webkit-animation-name: preloader-right-rotate;
  animation-name: preloader-right-rotate
}

.color-multi .preloader-inner .preloader-inner-left .preloader-inner-half-circle {
  -webkit-animation-name: preloader-left-rotate-multicolor;
  animation-name: preloader-left-rotate-multicolor
}

.color-multi .preloader-inner .preloader-inner-right .preloader-inner-half-circle {
  -webkit-animation-name: preloader-right-rotate-multicolor;
  animation-name: preloader-right-rotate-multicolor
}

@-webkit-keyframes preloader-left-rotate {

  0%,
  100% {
    -webkit-transform: rotate(130deg)
  }

  50% {
    -webkit-transform: rotate(-5deg)
  }
}

@keyframes preloader-left-rotate {

  0%,
  100% {
    transform: rotate(130deg)
  }

  50% {
    transform: rotate(-5deg)
  }
}

@-webkit-keyframes preloader-right-rotate {

  0%,
  100% {
    -webkit-transform: rotate(-130deg)
  }

  50% {
    -webkit-transform: rotate(5deg)
  }
}

@keyframes preloader-right-rotate {

  0%,
  100% {
    transform: rotate(-130deg)
  }

  50% {
    transform: rotate(5deg)
  }
}

@-webkit-keyframes preloader-inner-rotate {
  12.5% {
    -webkit-transform: rotate(135deg)
  }

  25% {
    -webkit-transform: rotate(270deg)
  }

  37.5% {
    -webkit-transform: rotate(405deg)
  }

  50% {
    -webkit-transform: rotate(540deg)
  }

  62.5% {
    -webkit-transform: rotate(675deg)
  }

  75% {
    -webkit-transform: rotate(810deg)
  }

  87.5% {
    -webkit-transform: rotate(945deg)
  }

  100% {
    -webkit-transform: rotate(1080deg)
  }
}

@keyframes preloader-inner-rotate {
  12.5% {
    transform: rotate(135deg)
  }

  25% {
    transform: rotate(270deg)
  }

  37.5% {
    transform: rotate(405deg)
  }

  50% {
    transform: rotate(540deg)
  }

  62.5% {
    transform: rotate(675deg)
  }

  75% {
    transform: rotate(810deg)
  }

  87.5% {
    transform: rotate(945deg)
  }

  100% {
    transform: rotate(1080deg)
  }
}

@-webkit-keyframes preloader-left-rotate-multicolor {

  0%,
  100% {
    border-left-color: #4285f4;
    -webkit-transform: rotate(130deg)
  }

  75% {
    border-left-color: #1b9a59;
    border-top-color: #1b9a59
  }

  50% {
    border-left-color: #f7c223;
    border-top-color: #f7c223;
    -webkit-transform: rotate(-5deg)
  }

  25% {
    border-left-color: #de3e35;
    border-top-color: #de3e35
  }
}

@keyframes preloader-left-rotate-multicolor {

  0%,
  100% {
    border-left-color: #4285f4;
    transform: rotate(130deg)
  }

  75% {
    border-left-color: #1b9a59;
    border-top-color: #1b9a59
  }

  50% {
    border-left-color: #f7c223;
    border-top-color: #f7c223;
    transform: rotate(-5deg)
  }

  25% {
    border-left-color: #de3e35;
    border-top-color: #de3e35
  }
}

@-webkit-keyframes preloader-right-rotate-multicolor {

  0%,
  100% {
    border-right-color: #4285f4;
    -webkit-transform: rotate(-130deg)
  }

  75% {
    border-right-color: #1b9a59;
    border-top-color: #1b9a59
  }

  50% {
    border-right-color: #f7c223;
    border-top-color: #f7c223;
    -webkit-transform: rotate(5deg)
  }

  25% {
    border-top-color: #de3e35;
    border-right-color: #de3e35
  }
}

@keyframes preloader-right-rotate-multicolor {

  0%,
  100% {
    border-right-color: #4285f4;
    transform: rotate(-130deg)
  }

  75% {
    border-right-color: #1b9a59;
    border-top-color: #1b9a59
  }

  50% {
    border-right-color: #f7c223;
    border-top-color: #f7c223;
    transform: rotate(5deg)
  }

  25% {
    border-top-color: #de3e35;
    border-right-color: #de3e35
  }
}

.floating-button.theme-red,
.speed-dial-buttons a.theme-red,
.theme-red .floating-button,
.theme-red .speed-dial-buttons a {
  color: #fff;
  background: var(--theme-color);
}

.theme-red .card a,
.theme-red a {
  color: var(--theme-color);
}

.floating-button.theme-font-color,
.speed-dial-buttons a.theme-font-color,
.theme-font-color .floating-button,
.theme-font-color .speed-dial-buttons a {
  color: #fff;
  background: var(--theme-color);
}

.theme-font-color .card a,
.theme-font-color a {
  color: var(--theme-color);
}

.actions-modal-button,
.button,
.floating-button>a,
.label-checkbox,
.label-radio,
.modal-button,
.ripple,
.speed-dial-buttons a,
.tab-link,
a.floating-button,
a.item-link,
a.link {
  -webkit-user-select: none;
  user-select: none;
}

.floating-button {
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  z-index: 1500;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 0 6px 6px rgba(0, 0, 0, .23);
  background-color: #2196f3;
  color: #fff;
  overflow: hidden;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.user-pic {
  float: left;
  position: relative;
}

.user-pic i {
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  border: 1px solid #fff;
  position: absolute;
  bottom: 15px;
  right: 0;
}

.user-pic i.offline {
  background: #999;
}

.user-pic i.online {
  background: #009688;
}

.pay-icon img {
  border-radius: initial;
  height: 32px;
}

.recharge-list {
  margin: 0;
}

.recharge-list .item-media img {
  width: auto;
  height: auto;
  max-width: 40px;
}

.userface ul {
  padding: 0 0 5px;
  background-image: url("../../assets/image/user-bg.png");
  background-size: 100% 100%;
}

.amountbtn {
  margin-left: 5px !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.amountbtn:hover {
  text-decoration: none;
}

.navbar .center,
.subnavbar .center {
  text-align: center;
  margin: 0 auto;
  line-height: 44px;
  font-size: 15px;
  width: auto;
}

.navbar .center,
.subnavbar .center {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 0 16px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 56px;
  display: inline-block;
  text-align: left;
}

.nav-tab {
  //border-radius: 4px;
  //border: 1px solid #fff;
  height: 32px !important;
  line-height: 30px !important;
  text-align: center;
  margin: 0 auto;
  line-height: 44px;
  font-size: 15px;
  width: auto;
}

.nav-tab a.tab-link {
  background: var(--theme-color) !important;
  color: #fff !important;
  border: 1px solid white;
}

.nav-tab a.active {
  background: #fff !important;
  color: #000 !important;
}

.nav-tab a {
  height: 30px !important;
  line-height: 30px !important;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  width: 90px;
  text-align: center;
  text-decoration: none;
}

.userface {
  background: var(--theme-color);
}

.DataTotal {
  padding: 16px 16px 48px 16px;
  background-image: url("../../assets/image/user-bg.png");
  background-size: 100% 100%;
  position: relative;
}

.clearfix {
  clear: both;
}

.userface a {
  color: #fff !important;
  text-decoration: none;
}

.DataTotal-btn {
  width: 40%;
  border-radius: 18px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 24px auto 0 !important;
  background: -webkit-linear-gradient(#fff, #e5e5e5);
  background: -o-linear-gradient(#fff, #e5e5e5);
  background: -moz-linear-gradient(#fff, #e5e5e5);
  background: linear-gradient(#fff, #e5e5e5);

  color: var(--theme-color);
  text-decoration: none;
  text-align: center;
  display: block;
  line-height: 36px;
  font-size: 14px;
  outline: 0;
  border: none;
  height: 36px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  text-transform: uppercase;
  font-family: inherit;
  min-width: 64px;
  padding: 0 8px;
  position: relative;
  overflow: hidden;
}

.searchbar {
  background-color: transparent !important;
  height: 48px;
  width: 100%;
  background: #2196f3;
  box-sizing: border-box;
  padding: 0 16px;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: #fff;
}

.searchbar .searchbar {
  width: 100%;
  height: 32px;
  position: relative;
  flex-shrink: 1;
}

#searchbarARPlaceholder {
  border-bottom-color: #999;
  opacity: 1;
  color: #999;
}

.searchbar input[type=text] {
  box-sizing: border-box;
  width: 95%;
  height: 32px;
  display: block;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border-radius: 0;
  font-family: inherit;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  border-bottom: 1px solid #fff;
  height: 100%;
  padding: 0 36px 0 24px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0 center;
  opacity: .6;
  background-size: 24px 24px;
  transition-duration: .3s;
}

a,
input,
select,
textarea {
  outline: 0;
}

.searchbar a:before {
  width: 152%;
  height: 152%;
  position: absolute;
  left: -26%;
  top: -26%;
  background-image: -webkit-radial-gradient(center, circle cover, rgba(255, 255, 255, .15) 66%, rgba(255, 255, 255, 0) 66%);
  background-image: radial-gradient(circle at center, rgba(255, 255, 255, .15) 66%, rgba(255, 255, 255, 0) 66%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  opacity: 0;
  pointer-events: none;
  transition-duration: .6s;
}

.blue {
  font-weight: 400;
}

.theme-btn-brh-active {
  color: #fff;
  background-color: var(--theme-color);
  height: 30px;
  display: block;
  margin: 0 10px 10px 0;
  text-align: center;
  font-size: 13px;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
}

.btn-brh:hover {
  background-color: transparent;
  color: #212121;
}

.btn-brh-active:hover {
  background-color: transparent;
  color: var(--theme-color);
}


.btn-brh {
  height: 30px;
  display: block;
  margin: 0 10px 10px 0;
  text-align: center;
  font-size: 10px;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
  color: #212121;
  border-color: #e1e1e1;
}

.btn-brh-active {
  height: 30px;
  display: block;
  margin: 0 10px 10px 0;
  text-align: center;
  font-size: 10px;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
  color: var(--theme-color);
  border-color: var(--theme-color);
}

.div-btn {
  list-style: none;
  float: left;
  width: 33%;
  padding-left: 6px;
}

.cb-btn {
  flex: 1 1 auto;
  padding: 0;
}

.select-badge {
  font-size: 10px;
  display: inline-block;
  color: #fff;
  background: #8e8e93;
  border-radius: 3px;
  padding: 1px 6px;
  box-sizing: border-box;
  vertical-align: middle;
  text-decoration: none;
}

.item-after .select-badge {
  padding: 3px 6px;
}

.bg-green {
  background-color: #4caf50;
}

.mt0 {
  margin-top: 0 !important;
}

.select-channel .item-inner {
  color: #f44336;
}

.page-content-44 {
  height: calc((var(--vh) - var(--safe-bottom)) - 44px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.page-content-55 {
  height: calc((var(--vh) - var(--safe-bottom)) - 55px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.page-content-92 {
  height: calc((var(--vh) - var(--safe-bottom)) - 92px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.page-content-94 {
  height: calc((var(--vh) - var(--safe-bottom)) - 94px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.page-content-126 {
  height: calc((var(--vh) - var(--safe-bottom)) - 126px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.page-content-88 {
  height: calc((var(--vh) - var(--safe-bottom)) - 88px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.agitem-content {
  border-bottom: 1px solid;
  border-color: #dee2e6;
  color: #757575;
  font-size: 14px;
  width: 80%;
  margin-left: 12%;
}

.searchbar .arsearchbar-input {
  width: 100%;
  height: 32px;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex-shrink: 1;
  -ms-flex: 0 1 auto;
  flex-shrink: 1;
}

.searchbar .searchbar-clear {
  position: absolute;
  width: 56px;
  height: 100%;
  right: -16px;
  top: 0;
  opacity: 0;
  pointer-events: none;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: 24px 24px;
  background-size: 24px 24px;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  cursor: pointer;
}

.trsearchbar {
  background-color: var(--theme-color);
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  color: #fff;
}

.trsearchbar .trsearchbar-input {
  width: 100%;
  height: 32px;
  position: relative;
  -webkit-box-flex: 1;
  flex-shrink: 1;
}

.trsearchbar input[type=text] {
  box-sizing: border-box;
  width: 95%;
  height: 32px;
  display: block;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border-radius: 0;
  font-family: inherit;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  border-bottom: 1px solid #fff;
  height: 100%;
  padding: 0 36px 0 30px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0 center;
  opacity: .6;
  background-size: 24px 24px;
  transition-duration: .3s;
}

.trsearchbarARPlaceholder {
  border-bottom-color: #fff !important;
  opacity: 1;
  color: #fff !important;
}

.trsearchbarARPlaceholder::placeholder {
  color: #fff !important;
  color: #e5e5e5 !important;
}

.coupon {
  position: relative;
  display: inline-block;
  padding: 0 9px 0 12px;
  border-top: 1px solid #e4393c;
  border-bottom: 1px solid #e4393c;
  height: 18px;
  line-height: 18px;
  color: #e4393c;
  font-size: 12px;
}

.coupon:after,
.coupon:before {
  content: "";
  position: absolute;
  top: -1px;
  height: 18px;
  background-image: url("../../assets/image/coupon.png");
  background-size: 15px 18px;
  background-repeat: no-repeat;
}

.coupon:before {
  left: 0;
  width: 7px;
}

.coupon:after {
  right: 0;
  width: 4px;
  background-position: -11px 0;
}

.cengji {
  padding: 10px 5px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: normal;
  text-align: left;
  background: #f4f4f4;
  color: black;
}

label.label-radio .item-after i.icon-form-radio {
  margin-left: 8px;
  margin-right: 16px;
}

.full-page-modal .modal-content {
  background-color: #f4f4f4;
}

.safe-password-modal .list-block .item-inner:after {
  background-color: white;
}

.check-detail-modal {
  margin: 0;
  height: -moz-available;
  /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  height: fill-available;
}

.check-detail-modal .modal-content {
  height: 100%;
}

.check-record-page,
.check-detail-modal {
  label {
    margin: 0;
  }

  .data-table {
    overflow-x: auto;
  }

  .data-table table {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    text-align: left;
  }

  .data-table tbody {
    font-size: 13px;
  }

  .data-table th,
  .data-table td {
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: 40px !important;
  }

  .data-table td,
  .data-table th {
    position: relative;
  }

  .data-table tbody td:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: auto;
    right: auto;
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, .12);
    display: block;
    z-index: 15;
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }

  .data-table tbody td:before {
    -webkit-transform: scaleY(.33);
    transform: scaleY(.33);
  }

  .data-table td.numeric-cell,
  .data-table th.numeric-cell {
    text-align: right;
  }

  .data-table thead {
    font-size: 12px;
  }

  .data-table thead td,
  .data-table thead th {
    font-weight: 500;
    height: 56px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 16px;
  }

  .data-table thead td:not(.sortable-active),
  .data-table thead th:not(.sortable-active) {
    color: rgba(0, 0, 0, .54);
  }
}

.item-coupon {
  color: #eb5935;
  font-size: 28px;
  display: block !important;
}

.item-coupon-ed {
  color: #bbb;
}

.list-block.media-list .item-coupon {
  padding-top: 8px;
  padding-bottom: 8px;
}

.item-coupon small {
  font-size: 14px;
  vertical-align: text-top;
}

.item-coupon span {
  display: block;
  font-size: 12px !important;
  color: #999 !important;
  margin-top: -6px;
  text-align: center;
}

.slect-ed {
  color: #bbb;
}

.list-radio .item-after {
  padding-top: 26px !important;
}

.code-img {
  margin: 0 auto;
  width: 206px;
}

.code-list {
  background: #fff;
  text-align: center;
  font-size: 14px;
}

.sub-account .list-block .item-inner {
  display: block;
}

.sub-account .list-block .item-inner .item-title .label {
  width: 100%;
  font-size: 14px !important;
}

.sub-account .list-block .item-content .sub-account-slider {
  width: 100%
}

.agent-promo-tab2 .DataTotal-card {
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  margin: 10px 10px 0px 10px;
}

.agent-promo-tab2 .DataTotal-card .list-block {
  border-radius: 8px;
}

.agent-promo-tab2 .DataTotal-card .list-block .item-inner:after {
  display: none;
}

.agent-promo-tab2 .DataTotal-card .card-footer {
  min-height: 36px;
  position: relative;
  padding: 4px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
}

.agent-promo-tab2 .DataTotal-bottom {
  height: 49px;
  background-color: #fff;
  border-top: 1px solid #e1e1e1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.agent-promo-tab2 .DataTotal-bottom-l {
  padding: 14px 16px;
  float: left
}

.agent-promo-tab2 .DataTotal-bottom-r {
  padding: 14px 14px;
  float: right;
}

.agent-promotion-modal .item-content {
  box-sizing: border-box;
  padding-left: 18px;
  padding-right: 18px;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.agent-promotion-modal .item-content .item-inner {
  padding-right: 16px;
  position: relative;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 48px;
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.agent-promotion-modal .item-content .item-inner:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, .65);
  display: block;
  z-index: 15;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
  opacity: 0.3;
}

.agent-promotion-modal .item-content .item-inner .item-title {
  vertical-align: top;
  color: rgba(0, 0, 0, .65);
  transition-duration: .2s;
  width: 35%;
  flex-shrink: 0;
  min-width: 0;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px !important;
}

.agent-promotion-modal .item-content .item-inner .item-after {
  white-space: nowrap;
  color: #757575;
  -webkit-box-flex: 0;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 8px;
  display: flex;
  max-height: 28px;
  font-size: 14px;
}

.docdetail-tab .mdc-tab__content {
  padding-bottom: 0;
}

.docdetail-tab .mdc-tab {
  line-height: 43px;
  height: 43px;
  padding: 0 16px;
  font-size: 14px;
  background-color: white;
}

.docdetail-tab .mdc-tab--active {
  color: red;
}

.docdetail-tab .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  background-color: red;
}

.apm-button {
  color: white !important;
  width: 100%;
  text-align: center;
  display: block;
  border-radius: 2px;
  line-height: 36px;
  margin: 0;
  height: 36px;
  font-size: 14px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-family: inherit;
  cursor: pointer;
  min-width: 64px;
  padding: 0 8px;
  overflow: hidden;
  outline: 0;
  border: none;
  transition-duration: .3s;
  transform: translate3d(0, 0, 0);
}

.apm-button1 {
  color: var(--theme-color) !important;
  width: 100%;
  text-align: center;
  display: block;
  border-radius: 2px;
  line-height: 36px;
  margin: 0;
  height: 36px;
  font-size: 14px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-family: inherit;
  cursor: pointer;
  min-width: 64px;
  padding: 0 8px;
  overflow: hidden;
  outline: 0;
  border: none;
  transition-duration: .3s;
  transform: translate3d(0, 0, 0);
}

.rules-method-container {
  overflow: auto;
  white-space: nowrap;
  width: 100vw;
  height: 65px;
  position: relative;
}

.rules-method-header {
  display: inline-block;
  min-width: 65px;
  text-align: center;
  padding-top: 14px;
  margin: 0 5px;
}

.lottery-method .rules-method-header {
  padding-top: 5px;
  font-size: 14px;
}

.rules-method-header-button {
  padding: 5px 17px;
  border-radius: 25px;
  display: block;
  min-width: 75px;
  background-color: #f4f4f4;
  color: #bfbfbf;
}

.rules-method-header-button.active {
  color: white !important;
  background-color: var(--theme-color) !important;
}

.line-modal .modal {
  top: unset;
  bottom: calc(0px + var(--safe-bottom));
}

.line-modal .modal-dialog {
  margin: 0;
}

.line-modal .modal.fade .modal-dialog {
  transition: transform 0.3s;
  transform: translate3d(0, 100%, 0);
}

.line-modal .modal.show .modal-dialog {
  transform: none;
}

.line-modal .modal {
  height: auto;
}

.line-modal .modal-backdrop {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.line-modal .modal-content {
  border-radius: 0;
  border: none;
}

.msg-length {
  font-size: 80%;
  margin-left: 8px;
  padding: 2px 12px;
  background: var(--theme-color);
  border-radius: 15px;
  display: block;
  color: #ffff;
}

.unreadmsg {
  padding: 8px;
  float: left;
  border-radius: 50%;
  background: var(--theme-color);
  margin-right: 10px;
  margin-top: 5px;
  position: relative;
}

.unreadmsg svg {
  display: block;
  color: white;
}

.unreadmsg .msgdot {
  display: block;
  height: 7px;
  width: 7px;
  border-radius: 5px;
  border: 1px solid #fff;
  position: absolute;
  top: 0;
  background: orange;
  right: 0;
  z-index: 5;
}

.readmsg {
  padding: 8px;
  float: left;
  border-radius: 50%;
  background: #bcbcbc;
  margin-right: 10px;
  margin-top: 5px;
}

.readmsg svg {
  display: block;
  color: white;
}

.toggleOnOff {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 10px;
  color: white;
  padding-right: 2;
  font-family: Verdana, sans-serif;
}

.activity-main h3 {
  border-left: 0.27907rem solid var(--theme-color);
  font-size: 23px;
  padding-left: 0.46512rem;
  font-weight: 400;
  color: var(--theme-color);
  margin: 1.16279rem;
  margin-left: 0;
}

.activity-table {
  margin: 0 0.5rem;
}

.activity-main table {
  width: auto;
  text-align: center;
  color: #666666;
  font-size: 17px;

  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

.activity-main table th {
  height: 50px;
  border: 0.04651rem solid #e9e9e9;
  background: #f2f7ff;
  font-weight: 300;
  padding: 12px 20px;
  width: 100px;
}

.activity-main table th:first-child {
  min-width: 6.7907rem;
}

.activity-main table td {
  height: 1.8rem;
  border: 0.04651rem solid #e9e9e9;
  font-weight: 300;
  padding: 0px;
}

.activity-main .tip {
  margin: 0.5rem;
  font-size: 0.9em;
  border: 0.04651rem solid #e9e9e9;
}

.activity-main .tip p {
  margin: 0.3rem;
}

.list_image {
  margin-top: 6px;
}

.list_image ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 0;
  text-align: center;
}

.list_img_holder {
  height: 4.5rem;
  position: relative;
  //background: url(../img/noimage.gif) no-repeat center center;
  background-size: 100% 100%;
}

.list_image ul li {
  display: inline-block;
  overflow: hidden;
  width: 33.3%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.list_image ul li:first-child {
  float: left;
  padding-right: 6px;
}

.list_img_holder img {
  border: none;
  display: block;
  width: 100%;
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity ease .3s;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  text-decoration: none;
}

.list_image ul li:last-child {
  float: right;
  padding-left: 6px;
}

.list_img_holder_large {
  //background: url(../img/noimage.gif) no-repeat center center;
  background-size: 100% 100%;
  overflow: hidden;
  width: 100%;
  position: relative;
  min-height: 3.75rem;
  margin-top: 6px;
}

.list_img_holder_large img {
  border: none;
  display: block;
  width: 100%;
  pointer-events: none;
}

.proxycentericon {
  width: 50%;
}

.proxycenter-bigfont {
  font-weight: 400;
}

.proxycenter-mediumfont {
  font-weight: 400;
  font-size: 22px !important;
}

.bettingrecordpage .mdc-tab {
  line-height: 43px;
  height: 43px;
  font-size: 14px;
  background-color: white;
}

.bettingrecordpage .mdc-tab--active {
  color: var(--theme-color);
}

.bettingrecordpage .mdc-tab__content {
  padding-bottom: 0;
}

.bettingrecordpage .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  opacity: 1;
  background-color: #eaeaea;
  height: 1.5px;
}

.bettingrecordpage .mdc-tab--active .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  background-color: var(--theme-color);
  height: 2px;
}

.rise-page {
  .unclick {
    color: white;
    font-style: normal;
    font-weight: bold;
    background: var(--theme-color);
    border-color: var(--theme-color);
    transform: translate(12%, 50%);
    width: 80%;
    height: 50px;
    font-size: 25px;
    padding-top: 5px;
    border-radius: 5px;
    border: 1px solid var(--theme-color);
  }
  .newUnclick{
    color: white;
    font-style: normal;
    font-weight: bold;
    background: var(--theme-color);
    border-color: var(--theme-color);
    font-size: 14px;
    padding: 3px;
    border-radius: 5px;
    border: 1px solid var(--theme-color);
    min-width: 80px;
    text-align: center;
  }
}

.notice-dialog {
  transform: translate(-50%, -50%) !important;
  left: 50%;
  top: 50%;
  position: absolute;
  margin: 0;
  width: 90%;
  max-width: 400px;
}

.notice-modal .modal-backdrop {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.notice-dialog .modal-content {
  border: none !important;
}

.notice-dialog .notice-header {
  position: absolute;
  text-align: center;
  width: 100%;
  color: #fff;
  font-weight: 400;
  padding: 10px;
  font-size: 17px;
  background-color: #3A3A3A;
}

.notice-dialog .notice-close {
  position: absolute;
  right: 10px;
  top: 5px;
  color: white;
  font-size: 25px;
  z-index: 5;
}

.notice-dialog .notice-title {
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 60px;
}

.notice-dialog .notice-content {
  max-height: 360px;
  overflow: auto;
  padding: 0px 20px;
  height: 100%;
}

.notice-dialog .notice-confirm {
  color: #ff5722;
  text-align: center;
  font-weight: 700;
  padding: 10px 0px 15px 0px;
}

.notice-dialog img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.list-content {
  background: #fff;
}

.list-content section {
  margin: 0px 15px;
  padding: 15px 0;
  border-bottom: 1px solid rgba(221, 221, 221, 0.6);
}

.list-content h3 {
  color: #222;
  font-size: 17px;
  line-height: 21px;
  font-weight: normal;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.cardWrap {
  width: 100%;
  display: flex;
  padding: 0 15px;
  color: #fff;
  font-family: sans-serif;
}

.card1 {
  background: linear-gradient(to right, #ff8080 26%, #ff4d4d 100%);
  min-height: 7em;
  float: left;
  position: relative;
  padding: 1em;
}

.card2 {
  background: #ff4d4d;
  min-height: 7em;
  float: left;
  position: relative;
  padding: 1em;
}

.cardLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 60%;
  margin: 0 0 10px 0;
}

.cardRight {
  width: 40%;
  min-width: 100px;
  border-left: 2px dashed #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0 0 10px 0;

}

.cardRight:before,
.cardRight:after {
  content: "";
  position: absolute;
  display: block;
  width: 0.9em;
  height: 0.9em;
  background: #fff;
  border-radius: 50%;
  left: -0.5em;
}

.cardRight:before {
  top: -0.4em;
}

.cardRight:after {
  bottom: -0.4em;
}

.number {
  text-align: center;
  text-transform: uppercase;
  margin: -23px 2px 0 0;
}

.number p {
  margin: 0.9em 0 0 0;
  font-size: 25px;
}

.number span {
  color: #fff;
  font-weight: 'bold';
  font-size: "13px";
}

.voucher-button {
  margin: 10px -5px 0 0;
  width: 75px;
  height: 25px;
  border: #fff;
  font-size: 12px;
  line-height: 25px;
  padding: 0;
  background: #f39ea1 !important;
}

.invalidVoucher .voucher-button {
  background: #c5c5c5 !important;
}

.vouchernotice-dialog {
  transform: translate(-50%, -50%) !important;
  left: 50%;
  position: absolute;
  margin: 0;
  width: 85%;
  max-width: 400px;
}

.vouchernotice-dialog .modal-content {
  border: none;
}

.vouchernotice-modal .modal-backdrop {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.quan-list {
  position: absolute;
  top: 0;

  width: 100%;
  color: #fff;
  z-index: 99999;
}

.quan-box-zh {
  width: 250px;
  background-image: url("../../assets/image/quan-list-zh.png");
  background-repeat: no-repeat;
  background-size: 105% auto;
  margin: 0 auto;
  padding: 74px 10px 20px;
  border-radius: 15px;
  margin-top: 50%;
}

.quan-box-en {
  width: 250px;
  background-image: url("../../assets/image/quan-list-en.png");
  background-repeat: no-repeat;
  background-size: 105% auto;
  margin: 0 auto;
  padding: 74px 10px 20px;
  border-radius: 15px;
  margin-top: 50%;
}

.quan-box-vi {
  width: 250px;
  background-image: url("../../assets/image/quan-list-vi.png");
  background-repeat: no-repeat;
  background-size: 105% auto;
  margin: 0 auto;
  padding: 74px 10px 20px;
  border-radius: 15px;
  margin-top: 50%;
}

.quan-box-th {
  width: 250px;
  background-image: url("../../assets/image/quan-list-th.png");
  background-repeat: no-repeat;
  background-size: 105% auto;
  margin: 0 auto;
  padding: 74px 10px 20px;
  border-radius: 15px;
  margin-top: 50%;
}

.quan-box-in {
  width: 250px;
  background-image: url("../../assets/image/quan-list-in.png");
  background-repeat: no-repeat;
  background-size: 105% auto;
  margin: 0 auto;
  padding: 74px 10px 20px;
  border-radius: 15px;
  margin-top: 50%;
}

.quan-box-pt {
  width: 250px;
  background-image: url("../../assets/image/quan-list-pt.png");
  background-repeat: no-repeat;
  background-size: 105% auto;
  margin: 0 auto;
  padding: 74px 10px 20px;
  border-radius: 15px;
  margin-top: 50%;
}

.quan-box-es {
  width: 250px;
  background-image: url("../../assets/image/quan-list-es.png");
  background-repeat: no-repeat;
  background-size: 105% auto;
  margin: 0 auto;
  padding: 74px 10px 20px;
  border-radius: 15px;
  margin-top: 50%;
}

.quan-box-id {
  width: 250px;
  background-image: url("../../assets/image/quan-list-id.png");
  background-repeat: no-repeat;
  background-size: 105% auto;
  margin: 0 auto;
  padding: 74px 10px 20px;
  border-radius: 15px;
  margin-top: 50%;
}

.quan-li {
  height: 71px;
  background-image: url("../../assets/image/quan-li.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0 0 8px;
}

.quan-li-left {
  color: #eb5935;
  font-size: 15px;
  width: 37%;
  float: left;
  font-weight: bold;
  text-indent: 8px;
  margin-top: 25px;
}

.quan-li-left small {
  font-size: 14px;
}

.quan-li-right {
  color: #212121;
  width: 63%;
  float: right;
  text-indent: 15px;
  margin-top: 16px;
}

.quan-li-right span {
  color: #999;
  font-size: 12px;
  display: block;
}

a.quan-btn {
  background: #efb93f;
  color: #fff;
  display: block;
  text-align: center;
  padding: 8px 0;
  border-radius: 20px;
  font-size: 14px;
  margin-top: 20px;
}

a.quan-close {
  display: block;
  text-align: center;
  margin: 20px auto 0;
  width: 36px;
}

a.quan-close img {
  width: 36px;
}

.qksmall-text {
  font-size: 14px !important;
  line-height: 1;
  padding: 1px 5px;
  border-radius: 2px;
  margin-right: 4px;
}

.qksmall-text-red {
  background: #dc3b40;
  font-size: 14px;
  line-height: 1;
  padding: 1px 5px;
  border-radius: 2px;
  margin-right: 4px;
  color: white;
}

.qksmall-text-grey {
  background: #b7b7b7;
  font-size: 14px;
  line-height: 1;
  padding: 1px 5px;
  border-radius: 2px;
  margin-right: 4px;
  color: white;
}

.qksmall-text-green {
  background: #6fe26f;
  font-size: 14px;
  line-height: 1;
  padding: 1px 5px;
  border-radius: 2px;
  margin-right: 4px;
  color: white;
}

.qksmall-text-yellow {
  background: #ffc200;
  font-size: 14px;
  line-height: 1;
  padding: 1px 5px;
  border-radius: 2px;
  margin-right: 4px;
  color: white;
}

.qksmall-text-blue {
  background: #12C2FF;
  font-size: 14px;
  line-height: 1;
  padding: 1px 5px;
  border-radius: 2px;
  margin-right: 4px;
  color: white;
}

.small-title {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 16px;
  background: #d3d3d359;
  color: grey;
}

.pwdimage {
  width: 24px;
  height: 24px;
}

.font14px {
  font-size: 14px !important;
}

.login-content .react-swipeable-view-container {
  height: 100% !important;
  background-color: white;
}

// .game-list-content .react-swipeable-view-container{
//   height: auto !important;
// }

.bg-light {
  background-color: #f4f4f4 !important;
}

.transaction-record-refresh {
  border-radius: 100px;
  width: 2.5rem;
  height: 2.5rem;
  position: fixed;
  bottom: 45px;
  right: 33px;
  padding: 0px;
}


.hot-key-bar {
  background-color: #444444 !important;
  position: fixed !important;
  bottom: calc(48px + var(--safe-bottom)) !important;
  top: auto !important;
  width: 100%;
  border-top: none !important;
}

.hot-key-bar .row {
  margin: 0px;
  height: 100%;
  width: 100%;
}

.hot-key-bar .row .list-container {
  padding: 0px 15px;
  width: 100%;
}

.hot-key-bar dl {
  margin-bottom: 0;
  padding-top: 6px;
}

.hot-key-bar dd {
  margin-bottom: 0;
  color: white;
}

.hot-key-bar .lg-button {
  padding: 6px 12px;
  background: var(--theme-color);
  margin-top: 11px;
  border-radius: 5px;
  font-size: 14px;
  min-width: 70px;
  line-height: 1;
}

.list-block .no-inner-line:after {
  height: 0;
}

.list-block .item-after-input {
  margin: 0;
  flex: auto;
  padding-left: 10px;
}

.followBetBtn label {
  line-height: 16px;
  font-size: 16px;
  color: #f6dcdb;
  padding: 5px 20px;
  background: #d13f3f;
  border-radius: 5px;

}

.flow-bet-box-outside {
  //display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(51, 51, 51, 0.5);
  z-index: 3;
}

.flow-bet-outside {
  position: fixed;
  left: 50%;
  top: 62%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  background: #f44336;
  border-radius: 10px;
  z-index: 99999999;
  text-align: center;
  background: white;
  /* height: 250px;  */
  min-width: 80%;
  font-size: smaller;
}

.follow-bet-title {
  line-height: 40px;
  height: 25px;
  position: relative;
  /* border-top: 1px solid #929292; */
  font-size: 20px;
  font-weight: 600px;
}

.follow-bet-game-outside {
  font-size: 16px;
  padding: 5px 20px;
  display: flex;
}

.gray-left-label {
  color: darkgray;
  width: 80px;
}

.follow-bet-game-input {
  margin-top: 20px;
  padding: 10px;
  background-color: #f6f6f6;
  border-radius: 5px;
  margin: 0 20px;
}

.follow-bet-game-input .gray-left-label {
  color: #000;
  text-align: left;
}

.follow-bet-game-input input {
  border: 0;
  text-align: right;
  height: 25px;
  font-size: 18px;
}

.follow-bet-game-btn {
  display: flex;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: var(--theme-color);
  border-top: 1px solid #ccc;
  margin-top: 10px;
}

.follow-bet-game-btn .cancelBtn {
  width: 50%;
  border-right: 1px solid #ccc;
  padding: 10px 0;
}

.follow-bet-game-btn .sureBtn {
  width: 50%;
  padding: 10px 0;
}

.follow-bet-game-btn .cancelBtn2 {
  width: 50%;
  border-right: 1px solid #ccc;
  padding: 10px 0;
}

.follow-bet-game-btn .sureBtn2 {
  width: 50%;
  padding: 10px 0;
}

.follow-bet-game-money {
  display: flex;
  justify-content: flex-end;
  padding: 5px 20px 0 20px;
  margin-top: 5px;
  font-size: 15px;
}

.follow-bet-stake-info {
  font-size: initial;
  padding: 10px 0 10px 0;
  font-family: Arial, Helvetica, sans-serif;
}

.follow-bet-label {
  padding: 0 15px 0 0;
}

.follow-bet-repeatIssue {
  font-size: 15px;
  padding: 10px 0 10px 0;
}

.page.qqc-game,
.page.slm-game {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(var(--vh) - var(--safe-bottom));
  background: #fff;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.qqc-game .marskdiv,
.slm-game .marskdiv {
  background: rgba(0, 0, 0, 0);
  height: calc(var(--vh) - var(--safe-bottom));
  width: 100%;
  display: none;
  position: absolute;
  z-index: 9999;
}

.qqc-game .gamebox {
  margin: 0% auto 0;
  width: 100%;
  height: calc(var(--vh) - var(--safe-bottom));
  overflow: hidden;
  display: block;
}

.slm-game .gamebox {
  margin: 0% auto 0;
  width: 100%;
  height: calc(var(--vh) - 44px - var(--safe-bottom));
  overflow: hidden;
  display: block;
}

.qqc-game .navbar {
  background: #061a4f
}

.qqc-game .toolbar-bottom {
  background: #061a4f !important;
  border-top-color: #061a4f !important;
}

.marskdiv {
  background: rgba(0, 0, 0, 0);
  height: 100%;
  width: 100%;
  display: none;
  position: absolute;
  z-index: 9999;
}

.qqc-game.my-popup .navbar {
  background: #061a4f;
}

.qqc-game.my-popup table,
.qqc-game.my-popup table td {
  border-collapse: collapse;
  border: 1px solid #fff;
  font-family: Arial, Helvetica, sans-serif;
}

.qqc-game.my-popup table thead tr td {
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
}

.qqc-game.my-popup table td {
  vertical-align: middle;
  padding: 4px;
  color: #fff;
  font-size: 10px;
}

.qqc-game.my-popup table.chart-table,
.qqc-game.my-popup table.chart-table td {
  padding: 5px 0;
  color: #000;
  border: none;
  text-align: center;
}

.qqc-game.my-popup table.chart-table>thead>tr>td {
  padding: 8px 0;
  background: #223c80;
  color: #fff;
}

.qqc-game.my-popup table.chart-table>tbody>tr {
  background-color: #fff;
}

.qqc-game.my-popup table.chart-table>tbody>tr:nth-of-type(odd) {
  background-color: #f3f3f3;
}

.qqc-game.my-popup p {
  margin-bottom: 10px;
}

.qqc-game.my-popup .top-title {
  margin-bottom: 0;
}

.qqc-game.my-popup .page-content {
  color: #fff;
}

.qqc-game.my-popup table tr td:first-child,
.qqc-game.my-popup table tr td:last-child {
  white-space: nowrap;
  text-align: center;
}

.qqc-game .chart-table .guessChart_sum {
  color: #5da0ff;
}

.qqc-game.my-popup table.chart-table td span {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 auto;
  line-height: 1;
}

.qqc-game .chart-table .guessChart_bs span {
  background: #fb7f9a;
}

.qqc-game .chart-table .guessChart_sd span {
  background: #65b3ff;
}

.qqc-game .chart-table .guessChart_dssd span {
  background: #837BD3;
}

.qqc-game .chart-table .guessChart_ss span {
  background: #edc882;
}

.game-page.qqc-game .navbar-inner .center {
  display: flex;
}

.qq-modal .modal-dialog {
  margin: 0;
}

.qq-modal .modal-content {
  border: none;
}

#noticeLink div span {
  // min-width: 55vw;
  display: inline-block;
  text-align: center;
}

.login-page-icon {
  max-height: 10vh;
  max-width: 50vw;
  margin-bottom: 5vh;
  margin-top: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dropdown-item.active,
.dropdown-item:active {
  background: var(--theme-color);
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.MuiSlider-root {
  color: var(--theme-color) !important;
}

.tbplus {
  position: relative;
  width: 100% !important;
}

.tbplus a {
  position: absolute;
  right: 1px;
  top: 2px;
  font-size: 14px;
  color: #666666;
}

.tbplus ins {
  border-bottom: 1px solid #7fbbdc;
  position: absolute;
  //width: 145px;
  width: 170px;
  -webkit-transform: rotate(22deg);
  //transform: rotate(11deg);
  transform: rotate(19deg);
  //left: -3px;
  left: -25px;
  //top: 24px;
  top: 21px;
}

.tbplus i {
  position: absolute;
  left: 12px;
  bottom: 3px;
  color: #666666;
  font-style: inherit;
  font-size: 14px;
}

.maintenance-page {
  height: 100%;
  background: #fff;

  body {
    font-family: "微软雅黑";
    font-size: 14px;
    background: #393D49;
    margin: 0;
  }

  .bg1 {
    background: #fff;
  }

  .top1 {
    margin: 70px auto 20px;
    text-align: center;
  }

  .tip1 {
    margin: 0 auto;
    text-align: center;
    color: #999;
    font-size: 32px;
    padding: 0 0 0 20px;
  }

  .tip2 {
    margin: 0 auto;
    text-align: center;
    color: #999;
    font-size: 18px;
    padding: 20px 0 0 0;
  }

  .tip2 span {
    color: #c22a30;
  }
}

.selected-depOpt {
  border: 1px var(--theme-color) solid !important;
}

.selected-depOptName {
  color: var(--theme-color) !important;
}

.add-border {
  border: 1px gray solid;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  margin-right: 5px;
  min-width: calc((100% - 6px) / 2);
  max-width: 170px;
}

.add-scrollx {
  justify-content: normal !important;
  overflow: hidden;
  overflow-x: scroll;
}

.scrollx-left {
  top: 50%;
  height: 47px;
  width: 30px;
  position: absolute;
  background-color: var(--theme-color);
  background-repeat: no-repeat;
  background-size: cover;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.scrollx-right {
  top: 50%;
  height: 47px;
  width: 30px;
  right: 0%;
  z-index: 9;
  position: absolute;
  background-color: var(--theme-color);
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.scrollx-left i.left {
  width: 20px;
  height: 25px;
  left: 5%;
  top: 22%;
  position: inherit;
  z-index: 999999999;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/image/left.png");
}

.scrollx-right i.right {
  width: 20px;
  height: 25px;
  left: 5%;
  top: 22%;
  position: inherit;
  z-index: 999999999;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/image/right.png");
}

.img-wfourty {
  max-width: 40px !important;
  max-height: 40px !important;
}

.selected-depOptTxt {
  padding: 0px !important;
  display: block !important;
}

.awardcenterpage .mdc-tab {
  line-height: 43px;
  height: 43px;
  font-size: 14px;
  background-color: white;
}

.awardcenterpage .mdc-tab--active {
  color: var(--theme-color);
}

.awardcenterpage .mdc-tab__content {
  padding-bottom: 0;
}

.awardcenterpage .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  opacity: 1;
  background-color: #eaeaea;
  height: 1.5px;
}

.awardcenterpage .mdc-tab--active .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  background-color: var(--theme-color);
  height: 2px;
}

.awardcenterpage .react-datepicker__input-container input {
  width: 80px;
  font-size: 13px;
  height: 22px;
  border: 0;
  padding: 5px;
  font-size: 15px;
}

.awardcenterpage .react-datepicker__header {
  padding-top: 18px;
}

.awardzhonghe th {
  background: #E5E4E2;
  color: #f44336;
}

.awardzhonghe td,
.awardzhonghe th {
  border: 1px solid #D7D7D7;
}

.zhonghebetpage {
  text-align: center;
  width: 95%;
  padding: 0 10px;
  margin: 0 auto;
}

.zhonghebetpage td {
  border: 1px solid #D7D7D7;
}

.insertBet {
  padding: 8px 10px;
}

.betNumber {
  width: 100%;
  border: 1px solid #ABB2B9;
  border-radius: 5px;
  margin-bottom: 5px;
  height: 32px;
}

.betValue {
  width: 100%;
  border: 1px solid #ABB2B9;
  border-radius: 5px;
  margin-bottom: 5px;
  height: 32px;
}

.days {
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  float: left;
  padding: 10px 10px;
  width: 30%;
  text-align: center;
  margin-left: 8px;
  margin-top: 5px;
}

.days.active {
  background: var(--theme-color);
  color: white !important;
}

.award-result-tab {
  flex: 1 1 0%;
  color: var(--theme-color);
  ;
  width: inherit;
  text-align: center;
  line-height: 48px;
  height: 48px;
  border-bottom: 1px solid #dedede;
}

.award-result-tab.active {
  border-bottom: 3px solid var(--theme-color);
}

.react-pattern-lock__point {
  border: 1px solid black;
  padding: 20px;
  border-radius: 50%;
}

.react-pattern-lock__point-inner {
  //background:#27aed5 !important;
  background: white !important;
}

.react-pattern-lock__connector {
  background: #27aed5 !important;
}

.react-pattern-lock__point-wrapper.selected .react-pattern-lock__point-inner,
.react-pattern-lock__point-wrapper.selected .react-pattern-lock__connector {
  background: #27aed5 !important;
}

.react-pattern-lock__point-wrapper.selected .react-pattern-lock__point {
  border-color: #27aed5;
}

.react-pattern-lock__pattern-wrapper.disabled .react-pattern-lock__point-inner,
.react-pattern-lock__pattern-wrapper.disabled .react-pattern-lock__connector {
  background: grey !important;
}

.react-pattern-lock__pattern-wrapper.disabled .react-pattern-lock__point {
  border-color: grey;
}

.react-pattern-lock__pattern-wrapper.error .react-pattern-lock__point-inner,
.react-pattern-lock__pattern-wrapper.error .react-pattern-lock__connector {
  background: red !important;
}

.react-pattern-lock__pattern-wrapper.error .react-pattern-lock__point {
  border-color: red;
}

.react-pattern-lock__pattern-wrapper.success .react-pattern-lock__point-inner,
.react-pattern-lock__pattern-wrapper.success .react-pattern-lock__connector {
  background: #00ff00 !important;
}

.react-pattern-lock__pattern-wrapper.success .react-pattern-lock__point {
  border-color: #00ff00;
}

.error-page {
  p {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color: #a7a7a7;
  }

  table {
    text-align: center;
    height: 97vh;
    width: 100%;
  }

  table img {
    width: 220px;
  }

  td {
    height: 100%;
  }

  tr {
    height: 100%;
  }

  .time {
    color: #ff5252;
    font-weight: 800;
  }
}

.error-404-page {
  p {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color: #a7a7a7;
  }

  table {
    text-align: center;
    height: 97vh;
    width: 100%;
  }

  table img {
    width: 220px;
  }

  td {
    height: 100%;
  }

  tr {
    height: 100%;
  }

  .icon {
    border: 1px solid #d5d5d5;
    height: 38px;
    padding: 8px 36px;
    border-radius: 5px;
    width: 103px;
  }

  .icon:hover {
    background: #f7f7f7;
    border: 1px solid #d5d5d5;
    height: 38px;
    padding: 8px 36px;
    border-radius: 5px;
    width: 103px;
  }
}

.orientation-container {
  background: black;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .phone {
    height: 50px;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    /* display: none; */
  }

  .message {
    color: white;
    font-size: 1em;
    margin-top: 40px;
    /* display: none; */
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg)
    }

    50% {
      transform: rotate(-90deg)
    }

    100% {
      transform: rotate(-90deg)
    }
  }
}

.new-api-title {
  font-size: 26px;
  font-weight: bold;
  color: var(--theme-color);
}

#outer-first {
  position: relative;
}

#outer-first .static {
  /* The static image helps responsively resizing the bounding box correctly */
  margin-top: 60%;
  width: 100%;
  height: auto;
}

#outer-first .first {
  position: absolute;
  top: 46%;
  left: 0%;
  width: 100%;
  height: auto;
  border-radius: 50%;
  z-index: 1;
}

#outer-first .second {
  position: absolute;
  width: 100%;
}

#outer-first .second p {
  text-align: center;
  width: 100%;
}

#outer-first .third {
  position: absolute;
  top: 30%;
  left: 0%;
  width: 100%;
  height: auto;
  border-radius: 50%;
  z-index: 2;
}

#outer-second {
  position: relative;
}

#outer-second .static {
  /* The static image helps responsively resizing the bounding box correctly */
  margin-top: 80%;
  margin-left: 5%;
  width: 100%;
  height: auto;
}

#outer-second .first {
  position: absolute;
  top: 53%;
  left: 5%;
  width: 100%;
  height: auto;
  border-radius: 50%;
  z-index: 1;
}

#outer-second .second {
  position: absolute;
  width: 100%;
}

#outer-second .second p {
  text-align: center;
  width: 100%;
}

#outer-third {
  position: relative;
}

#outer-third .static {
  /* The static image helps responsively resizing the bounding box correctly */
  margin-top: 80%;
  width: 100%;
  height: auto;
}

#outer-third .first {
  position: absolute;
  top: 53%;
  left: 0%;
  width: 100%;
  height: auto;
  border-radius: 50%;
  z-index: 1;
}

#outer-third .second {
  position: absolute;
  width: 100%;
}

#outer-third .second p {
  text-align: center;
  width: 100%;
}

.ranking-table {
  width: 100%;

  th {
    text-align: center;
    color: white;
  }

  td {
    border: none;
    height: 55px;
    text-align: center;
    background-color: white;
  }

  tr:nth-child(odd) td {
    background-color: #e4e4e4;
  }

  tr:nth-child(odd) td:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  tr:nth-child(odd) td:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  .user-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    word-break: break-all;
  }

  .profit {
    color: #a77f16;
    font-weight: 600;
  }
}

.rectangle-frame {
  display: flex;
  margin-bottom: 13px;
  background-image: url('../../assets/image/ranking/rectangle.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  line-height: 40px;
  color: white;
  font-size: 14px;
  top: -2px;
  position: relative;
}

.ranking-page {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: white;
}

.multiple-amount-bar {
  position: absolute;
  top: -40px;
  width: 100%;
  height: 40px;
  display: none;
  left: 0;
  text-align: center;
}

.multiple-amount-bar.active {
  display: block;
}

.content-group {
  display: block;
  width: 130px;
  margin: auto;
  background: white;
  border-radius: 3px;
  height: 34px;
  line-height: 32px;
  border: 1px solid #bfbfbf;
  text-align: center;

  .content {
    display: inline-block;
    border: 1px solid #bfbfbf;
    line-height: 20px;
    background-color: white;
  }

  .left {
    border-radius: 4px 0 0 4px;
  }

  .right {
    border-radius: 0 4px 4px 0;
  }

  .left,
  .right {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .center {
    width: 84px;
    text-align: center;
    border-width: 1px 0 1px 0;
  }
}

.green-home-background {
  background: url("../../assets/image/green-background.png") no-repeat center bottom 48px fixed;
  background-size: contain;
  height: 100vh;
}

.theme-new-skin-green .empty {
  background-color: transparent;
}

.personal-report-page {
  overflow-y: auto;
  height: 100vh;
}

.switch-is-on .react-switch-bg {
  background: var(--theme-color) !important;
}

.gold-home-background {
  background: #ffffff;
  background-size: contain;
}

/*
.gold-home-background .react-swipeable-view-container{
}
*/

.theme-font-color {
  .icon-Speaker:before {
    content: "\e931";
    color: var(--theme-color);
  }

  .icon-cp98Speaker:before {
    content: "\e931";
    color: var(--theme-color);
  }
}

.theme-font-color {
  .gold .icon-shixun:before {
    content: "\e915";
    color: #cccccc;
  }
}

.theme-font-color {
  .green .icon-shixun:before {
    content: "\e915";
    color: var(--theme-color);
  }
}

.theme-font-color {
  .gold .icon-leaderboard:before {
    content: "\e907";
    color: #cccccc;
  }
}

.theme-font-color {
  .green .icon-leaderboard:before {
    content: "\e907";
    color: var(--theme-color);
  }
}

.theme-font-color {
  .gold .icon-GRl:before {
    content: "\e903";
    color: #cccccc;
  }
}

.theme-font-color {
  .green .icon-GRl:before {
    content: "\e903";
    color: var(--theme-color);
  }
}


.theme-font-color {
  .gold .icon-signin:before {
    content: "\e93b";
    color: #cccccc;
  }
}

.theme-font-color {
  .green .icon-signin:before {
    content: "\e93b";
    color: var(--theme-color);
  }
}

.theme-font-color {
  .gold .icon-withdrawnhome:before {
    content: "\e930";
    color: #cccccc;
  }
}

.theme-font-color {
  .green .icon-withdrawnhome:before {
    content: "\e930";
    color: var(--theme-color);
  }
}

.theme-font-color {
  .icon-COOP:before {
    content: "\e901";
    color: #7f7f7f;
  }
}

.theme-font-color {
  .gold .icon-deposithome:before {
    content: "\e92f";
    color: #cccccc;
  }
}

.theme-font-color {
  .green .icon-deposithome:before {
    content: "\e92f";
    color: var(--theme-color);
  }
}

.theme-font-color {
  .gold .icon-changlong:before {
    content: "\e90a";
    color: #cccccc;
  }
}

.theme-font-color {
  .green .icon-changlong:before {
    content: "\e90a";
    color: var(--theme-color);
  }
}

.theme-font-color {
  .gold .icon-user-white:before {
    content: "\e91c";
    color: var(--theme-color);
  }
}

.theme-font-color {
  .icon-user-gold-background:before {
    content: "\e91c";
    color: white;
    font-size: 60px;
  }
}

.theme-font-color {
  .download-top {
    height: 43px;
  }
}

.theme-font-color {
  .icon-zoushi:before {
    content: "\e919";
    color: #cccccc;
  }
}

.theme-font-color {
  .icon-gift:before {
    content: "\e90b";
    color: #cccccc;
  }
}

.theme-font-color {
  .icon-affiliate:before {
    content: "\e93c";
    color: #cccccc;
  }
}

.theme-font-color {
  .icon-support:before {
    content: "\e917";
    color: #cccccc;
  }
}

.theme-font-color {
  .icon-mine:before {
    content: "\e911";
    color: #cccccc;
  }
}

.theme-font-color {
  .icon-home:before {
    content: "\e90d";
    color: #cccccc;
  }
}

.sport-font-color {

  .icon-withdrawnhome:before,
  .icon-deposithome:before,
  .icon-GRl:before,
  .icon-changlong:before,
  .icon-shixun:before,
  .icon-leaderboard:before,
  .icon-COOP:before,
  .icon-GR2:before,
  .icon-AG:before,
  .icon-HL2:before,
  .icon-KY:before,
  .icon-SB:before,
  .icon-lottery:before,
  .icon-autobets:before,
  .icon-signin:before {
    color: white;
  }
}

.gold-theme {
  .mdc-tab {
    line-height: 28px;
    height: 35px;
    padding: 0 16px;
    font-size: 16px;
    min-width: 75px;
    margin-right: 10px;
    border-radius: 25px;
    border: 0;
    color: #fff;
    background-color: #2e2f32;
    font-weight: bold;
  }

  .mdc-tab--active {
    background-color: var(--theme-color);
    color: black;
    font-size: 16px;
    width: 37px;
  }

  .mdc-tab__content {
    padding-bottom: 0;
  }
}

.box-border {
  position: relative;
  left: 50%;
  width: 37px;
  border-bottom: 5px solid;
  color: var(--theme-color);
  transform: translateX(-50%);
}

.gold-toolbar-bottom {
  height: 50px;
  border-top: 1px solid var(--theme-color);
}

.gold-toolbar {
  bottom: 0;
  top: auto;
  position: fixed;
  z-index: 2;
  border-top: 1px solid var(--theme-color);
}

.gold-toolbar .MuiBottomNavigation-root {
  background-color: #000;
}

.theme-font-color {
  .gold-theme .mdc-tab--active {
    color: black;
    font-size: 14px;
    background: linear-gradient(180deg, hsla(42, 80%, 61%, 1) 0%, hsla(47, 91%, 83%, 1) 25%, hsla(35, 83%, 62%, 1) 53%, hsla(32, 66%, 39%, 1) 82%, hsla(40, 80%, 35%, 1) 100%);
    min-width: 75px;
    width: auto;
  }
}

.them-font-color {
  .react-swipeable-view-container {
    height: 300px !important;
  }
}

.gold-tool {
  background-color: #000 !important;
}

.theme-font-color {
  .button.button-fill {
    background-color: var(--theme-color);
  }
}

.blue-home-background {
  background-size: contain;
}

.blue-theme {
  .mdc-tab {
    line-height: 1.5;
    padding: 5px 5px 5px 10px;
    font-size: 14px;
    min-width: 100px;
    margin-right: 10px;
    border: 1px solid #f2f2f2;
    color: rgb(146, 146, 146);
    letter-spacing: 0;
    font-weight: bold;
    white-space: break-spaces;
    text-align: left;
  }

  .mdc-tab--active {
    background-color: #f2f2f2;
    color: var(--theme-color);
    font-size: 14px;
    width: 37px;
  }

  .mdc-tab__content {
    padding-bottom: 0;
  }

  .mdc-tab-scroller__scroll-content {
    display: block;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 92px);
    scrollbar-width: none;
  }
}

.blue-toolbar-bottom {
  height: 50px;
  border-top: 1px solid #e1e1e1;
}

.blue-toolbar {
  bottom: 0;
  top: auto;
  position: fixed;
  z-index: 2;
  border-top: 1px solid #e1e1e1;
}

.tabbar a.tab-link.active.blue-homepage {
  color: #304DF0;
}

.tabbar a.tab-link.active.blue-award {
  color: #FFD406;
}

.tabbar a.tab-link.active.blue-promo {
  color: #FC7272;
}

.tabbar a.tab-link.active.blue-customer {
  color: #7994FD;
}

.tabbar a.tab-link.active.blue-user {
  color: #B959EE;
}

.sport-game-title {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
}

.sport-game-container {
  display: flex;
  justify-content: space-between;
}

.sport-game-col {
  float: left;
  width: calc(50% - 5px);
}


.theme-sport-blue {
  .sport-main-nav {
    background-color: transparent !important;
    height: 60px;
    height: calc(60px + env(safe-area-inset-top) / 4) !important;
  }

  .hot-key-bar {
    background-color: #0A172E !important;
  }

  .download-top .icon {
    font-size: 35px;
  }

  .sport-try-now {
    background: linear-gradient(0deg, #3d9dff 0%, #0747b2 30%, #1c3764 100%);
    border-radius: 25px;
    text-align: center;
    position: absolute;
    bottom: 30px;
    right: 15px;
    padding: 3px 3px;
    min-width: 140px;

    span {
      padding: 3px 15px;
      display: block;
      border: 2px solid #3accf3;
      border-radius: 25px;
      font-size: 14px;
    }
  }

  .carouselStyle.sportCarousel {
    .slick-track {
      float: left;
    }

    .slick-prev {
      display: block !important;
      z-index: 1;
      left: 0;
    }

    .slick-next {
      display: block !important;
      z-index: 1;
      right: 0;
    }

    .slick-list {
      margin: 0 -5px;
    }

    .sport-game-box {
      border-radius: 10px;
      padding: 0 5px;
    }
  }

  .carouselStyle .slick-dots {
    display: block !important;
    bottom: 5px;
  }

  .slick-dots li.slick-active button:before {
    color: var(--theme-color) !important;
  }

  .icon-support::before {
    color: #2d2d2d;
  }
}

.theme-new-skin-blue {
  .navbarheaders {
    background-color: #021373 !important;
  }

  .content-block-gaia .rows {
    width: 20%;

    a {
      margin: 5px auto 0;
    }
  }

  .content-block-gaia dd {
    word-break: break-word;
  }

  .game-list dd {
    font-weight: bold;
    color: black !important;
  }

  .content-block-gaia dt img {
    width: 35px;
    margin: auto;
  }
}

.myClassname {
  height: calc(var(--vh) - var(--safe-bottom)) !important;
}

.eventContent img {
  max-width: 100% !important;
}

.junctionTab-active {
  background-color: #D9383D;
  color: white;
}

.junctionTab {
  background-color: white;
  color: #857AFD;
}

.cp-content-block-gaia {
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #f4f4f4;
  border-top: 2px solid #f4f4f4;
  margin: 0 auto;
  // width: calc(100% - 20px);
  // border-radius: 50px;
  // margin-bottom: 15px;
  // box-shadow : 4px 4px 50px rgba(0, 0, 0, 0.15);
}

.cp-content-block-gaia dl {
  margin: 0;
  padding: 0;
  text-align: center;
}

.cp-content-block-gaia dt {
  margin: 0;
  padding: 0;
}

.cp-content-block-gaia dt span {
  font-size: 25px;
  border-radius: 50%;
  padding: 5px;
  display: block;
}

.cp-content-block-gaia dd {
  font-size: 14px;
  margin: 4px 0 0;
  color: #666666;
}

.cp-content-block-gaia .rows {
  display: flex;
  flex-wrap: wrap;
}

.cp-content-block-gaia .col20 {
  float: left;
}

.cp-carouselContainer {
  width: 100%;
  border-radius: 0px;
}

// .cp-carouselContainer .slick-slider{

// }

.cp-carouselContainer .carouselStyle .slick-list {
  height: 100%;
  border-radius: 0px;
}

.cp-toolbar-bottom {
  height: 50px;
  border-top: 1px solid var(--theme-color);
}

.cp-toolbar {
  bottom: 0;
  top: auto;
  position: fixed;
  z-index: 2;
  border-top: 1px solid var(--theme-color);
}

.cp-toolbar .MuiBottomNavigation-root {
  background-color: #22292C;
}

.rules-img {
  border: 1px solid #e5e5e5;
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;
}

button {
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}

.navbar-theme-royal {
  background-color: #F7F7F7 !important;
}

.royal-theme {
  padding: 0 15px;

  .mdc-tab {
    border-color: var(--theme-color);
    color: var(--theme-color);
    font-size: 14px;
    font-weight: bold;
  }

  .mdc-tab--active {
    background-color: var(--theme-color);
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
}

.royal-theme-game-list{
  .game-list dt img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .game-list dd {
    color: #212121;
    margin: 7px 0 0;
    font-size: 14px;
    font-weight: bold;
  }
}

.royal-home-background {
  background: #ffffff;
  background-size: contain;
}

.hot-key-bar-royal {
  background-color: #fff !important;
  position: fixed !important;
  bottom: calc(48px + var(--safe-bottom)) !important;
  top: auto !important;
  width: 100%;
  border-top: none !important;
}

.toolbar-royal {
  z-index: 2;
  background: #fff;
  height: 110px !important;
}

.royal-toolbar-bottom {
  height: 50px;
  background-color: #fff !important;
  border-top: 1px solid #e1e1e1;

  .icon-support:before {
    content: "\e917";
    color: #cccccc;
    font-weight: bold;
  }
  .MuiBottomNavigationAction-label {
    opacity: 1;
    font-size: 0.75rem;
    transition: font-size 0.2s, opacity 0.2s;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    transition-delay: 0.1s;
    font-size: 13px;
    font-weight: bold;
  }
}

.gr-container {
  position: fixed;
  top: 44px;
  width: 100%;
}

.gr-left {
  vertical-align: middle !important;
  width: 5%;
  padding: 0 3px !important;
  background-color: white;
}

.gr-content {
  width: auto;
  display: flex;
  padding: 0 0 0 0 !important;
  background-color: white;
}

.gr-right {
  vertical-align: middle !important;
  width: 5%;
  padding: 0 3px !important;
  background-color: white;
}

.gr-item {
  flex: 1 1 auto;
  height: 30px;
  border: 1px solid #E4E4E4;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
  border-radius: 50px;
  margin: 5px;
  font-size: 10px;
}

.gr-top-right {
  position: relative !important;
  vertical-align: top !important;
  margin-left: -26px;
  margin-top: -8px;
  z-index: 1;
  width: 26px !important;
  height: 16px !important;
}

.cus-float-btn {
  width: 43px;
  height: 50px;
  padding-bottom: 10px;
  transition: all 0.3s ease 0s;
  z-index: 99995;
}

.cus-float-btn-allslot {
  width: 63px;
  height: 70px;
  padding-bottom: 10px;
  transition: all 0.3s ease 0s;
  z-index: 99995;
}

.text-Center {
  text-align: -moz-center;
  text-align: -webkit-center
}

.btn-facebook-all {
  margin: 0 30px;
  background: #1877f2;
  font-weight: bold;
  border-radius: 8px;
  line-height: 40px;
  color: #fff;
  width: -webkit-fill-available;

  i {
    float: left;
    margin: 12px -25px 12px 15px;
  }
}

.btn-facebook {
  background: #1877f2;
  font-weight: bold;
  height: 40px;
  border-radius: 8px;
  line-height: 40px;
  color: #fff;
  text-align: center;

  span {
    font-size: 15;
    float: left;
    margin: -2px 0 0 15px;
  }
}

.google-button {
  .abcRioButtonContents {
    margin: auto;
    padding: 10px 38px 10px 0px !important;
  }

  .abcRioButton {
    width: 100% !important;
    border-radius: 8px !important;
  }
}

.navbar-theme-live {
  // min-height:35px;
  background-color: transparent !important;
  // position: fixed;
  // bottom: 0;
}

.live-rules-method-container {
  overflow: auto;
  white-space: nowrap;
  width: 97vw;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  height: auto !important;
  margin-left: 1.5vw;
}

.live-rules-method-header {
  display: inline-block;
  text-align: center;
  margin: 0 0px;
}

.lottery-method .live-rules-method-header {
  font-size: 14px;
  text-align: center;
  margin: 5px;
}

.live-rules-method-header-button {
  padding: 5px 10px;
  border-radius: 30px;
  display: block;
  min-width: 75px;
}

.live-rules-method-header-button.active {
  color: white !important;
  background: linear-gradient(to bottom, #F775A1, #E80C55) !important;
}

.theme-sodo-blue {
  .sport-main-nav {
    background-color: transparent !important;
    height: 60px;
    height: calc(60px + env(safe-area-inset-top) / 4) !important;
  }

  .hot-key-bar {
    background-color: #0A172E !important;
  }

  .download-top .icon {
    font-size: 35px;
  }

  .sport-try-now {
    background: linear-gradient(0deg, #3d9dff 0%, #0747b2 30%, #1c3764 100%);
    border-radius: 25px;
    text-align: center;
    position: absolute;
    bottom: 30px;
    right: 15px;
    padding: 3px 3px;
    min-width: 140px;

    span {
      padding: 3px 15px;
      display: block;
      border: 2px solid #3accf3;
      border-radius: 25px;
      font-size: 14px;
    }
  }

  .carouselStyle.sportCarousel {
    .slick-track {
      float: left;
    }

    .slick-prev {
      display: block !important;
      z-index: 1;
      left: 0;
    }

    .slick-next {
      display: block !important;
      z-index: 1;
      right: 0;
    }

    .slick-list {
      margin: 0 -5px;
    }

    .sport-game-box {
      border-radius: 10px;
      padding: 0 5px;
    }
  }

  .carouselStyle .slick-dots {
    display: block !important;
    bottom: 5px;
  }

  .slick-dots li.slick-active button:before {
    color: var(--theme-color) !important;
  }

  .icon-support::before {
    color: #2d2d2d;
  }
}

.sodo-game-title {
  color: #5C5C5C;
  font-size: 16px;
  margin: 10px 0;
}

.sodoCarousel .slick-slide img {
  padding: 5px;
  border-radius: 5px;
}

.sodo-gamelist-tab .mdc-tab-indicator>.mdc-tab-indicator__content--underline {
  background-color: var(--theme-color);
}

.sodo-gamelist-tab .mdc-tab {
  padding: 0 10px;
}

.no-new-line {
  white-space: nowrap;
}

.queue {
  display: table;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 35px;
  padding: 10px 35px 30px;
  box-sizing: border-box;
  margin: 0;
}

.queue li {
  position: relative;
  display: table-cell;
  box-sizing: content-box;
  list-style: none;
}

.queue li:first-child span {
  margin-left: 0
}

.queue li:last-child {
  border: 1px solid #fff;
  box-shadow: 0 4px 0 #fff;
  width: 10px
}

.queue span {
  font-size: 12px;
  line-height: 55px;
  margin-left: -15px
}

.queue i {
  position: absolute;
  background: #fff;
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  border: 5px solid #e3e3e3;
  bottom: -10px;
  left: -5px
}

.queue .right {
  border-bottom: 1px solid #4aa9db;
  box-shadow: 0 4px 0 #4aa9db
}

.queue .right i {
  border: 5px solid hsla(0, 0%, 100%, 0);
  font-family: iconfont;
  color: #4aa9db;
  background: #fff;
  bottom: -10px;
  line-height: 6px;
  font-size: 16px;
  width: 3px
}

.queue .right i:before {
  content: "\E60B";
  margin-left: -6px
}

.queue .now span,
.queue .right span {
  color: var(--theme-color) !important;
}

.queue .now i {
  border: 3px solid var(--theme-color) !important;
  ;
  width: 16px;
  height: 16px
}

.status-box {
  box-shadow: 0 4px 0 var(--theme-color) !important;
}

.pending-status {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 2;
  top: 140px;
  background: rgba(0, 0, 0, 0.5);
}

.stage2-img {
  background-image: url("../../assets/image/page_icon/usercentre/stage2.png");
  background-repeat: no-repeat;
  background-position: 50% 56%;
  background-size: 70px;
  z-index: 1;
  pointer-events: none;
  min-height: 607px;
}

.text-align {
  text-align: center;
  padding-top: 390px;
  z-index: 1;
  color: white;
}

.signin-button-reward {
  // position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--theme-color);
  text-align: center;
  margin-bottom: 11%;
}

.signin-button-disabled {
  //position: fixed;
  bottom: 0;
  width: 100%;
  background: #eaeaea;
  text-align: center;
}

.signin-button-disabled .disabled-button {
  width: 50%;
  display: inline-block;
  font-size: 20px;
  text-align: center;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  color: #898989;
  white-space: nowrap;
}

.log-list {
  clear: both;
  padding: 1rem 0.4rem;
  height: 100%;
  overflow: hidden;
}

.log-list dl {
  width: 18.7%;
  float: left;
  font-size: 0.7rem;
  text-align: center;
  padding: 0;
  margin: 2px;
  border: 2px solid #ffbdbe;
  border-radius: 0.2rem;
}

.log-list dl.active {
  border: 2px solid #de6f6c;
}

.log-list dt {
  color: #ffffff;
  padding: 0;
  margin: 0 auto 5px;
  height: 30px;
  line-height: 30px;
  background: #ffbdbe;
}

.log-list dl.active dt {
  background: #de6f6c;
}

.log-list dt.noborder {
  color: #737373;
}

.log-list dd img {
  width: 22px;
}

.log-list dd {
  color: #c2c2c2;
  padding: 0;
  margin: 0;
}

.sign-in-tip {
  color: #bbb;
  font-size: 12px;
  line-height: 1.2;
  padding: 1rem 0.4rem;
  height: 250px;
}

.log-list dl.last-index {
  border: 2px solid #ffc100;
  background: #fff0c1;
  color: #dcba50;
}

.log-list dl.last-index dt {
  background: #ffc100;
}

.log-list dl.last-index dd {
  color: #dcba50;
}

.log-list dl.signed {
  border: 2px solid #bbbbbb;
}

.log-list dl.signed dt {
  background: #bbbbbb;
}

.theme-minigame-blue {
  .icon-homeactive:before {
    content: "\e90e";
    color: white !important;
  }

  .icon-giftactive:before {
    content: "\e90c";
    color: white !important;
  }

  .icon-affiliateactive:before {
    content: "\e93d";
    color: white !important;
  }

  .icon-supportactive:before {
    content: "\e918";
    color: white !important;
  }

  .icon-mineactive:before {
    content: "\e912";
    color: white !important;
  }

  .sport-main-nav {
    background-color: transparent !important;
    height: 60px;
    height: calc(60px + env(safe-area-inset-top) / 4) !important;
  }

  .hot-key-bar {
    background-color: #0A172E !important;
  }

  .download-top .icon {
    font-size: 35px;
  }

  .sport-try-now {
    background: linear-gradient(0deg, #3d9dff 0%, #0747b2 30%, #1c3764 100%);
    border-radius: 25px;
    text-align: center;
    position: absolute;
    bottom: 30px;
    right: 15px;
    padding: 3px 3px;
    min-width: 140px;

    span {
      padding: 3px 15px;
      display: block;
      border: 2px solid #3accf3;
      border-radius: 25px;
      font-size: 14px;
    }
  }

  .carouselStyle.sportCarousel {
    .slick-track {
      float: left;
    }

    .slick-prev {
      display: block !important;
      z-index: 1;
      left: 0;
    }

    .slick-next {
      display: block !important;
      z-index: 1;
      right: 0;
    }

    .slick-list {
      margin: 0 -5px;
    }

    .sport-game-box {
      border-radius: 10px;
      padding: 0 5px;
    }
  }

  .carouselStyle .slick-dots {
    display: block !important;
    bottom: 5px;
  }

  .slick-dots li.slick-active button:before {
    color: var(--theme-color) !important;
  }

  // tooltip css for mobile view
  @media only screen and (max-width: 600px) {
    .minigameHotTooltip {
      text-align: center;
      position: absolute;
      top: 0;
      left: 4%;
      border-radius: 0px 0 30px 0;
      font-size: 10px;
      padding: 0 10px;
      font-style: italic;
      font-weight: 600;
    }
  }

  // tooltip css for tablet view
  @media only screen and (min-width: 600px) {
    .minigameHotTooltip {
      text-align: center;
      position: absolute;
      top: 0%;
      left: 4%;
      border-radius: 50px 0px 15px 0px;
      font-size: 13px;
      padding: 4px 15px;
      font-style: italic;
      font-weight: 600;
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background: var(--theme-color);
  }

  .paddingTop50 {
    padding-top: 50px !important;
  }

  .top50 {
    top: 50px !important;
  }

  .miniImgWord {
    text-align: left;
    padding-bottom: 10px;
    color: rgb(147, 172, 211);
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 7px;
  }

  .miniImgWord-full {
    text-align: left;
    padding-bottom: 10px;
    color: rgb(147, 172, 211);
    font-size: 0.8rem;
    padding-left: 7px;
  }

  .sidebar-menuicon {
    color: #ffffff
  }

  #home-controller .pill-nav.selected {
    background: #4368ff;
    color: #ffffff
  }

  #home-controller .pill-nav {
    background: #202020;
    border: 1px solid #333333;
    color: #8E8E8E;
  }

  .minigame-filterBoxContent {
    background-color: #25265c;
  }

  .minigame-cardBox {
    background-color: #25265c;
    color: #fff;
  }

  .minigame-cardBody {
    border-top: 1px solid #9e9e9e;
  }

  .filter-box-right {
    background: rgba(0, 0, 0, 0.2);
  }

  .filter-box-left {
    background: rgba(0, 0, 0, 0.2);
  }
}

.top-download-banner-img {
  width: 44px;
  float: right;
  background-color: rgba(237, 29, 73, 255);
  padding: 13px;
  right: 0%;
  top: 0%;
  position: absolute;
}

.miniCarousel .slick-slide img {
  padding: 5px;
  border-radius: 20%;
}

.mini-game-title {
  color: #5C5C5C;
  font-size: 16px;
  margin: 10px 0;
}

.mini-badge {
  width: 58px;
  position: absolute;
  top: 0px;
  border-top-left-radius: 8px !important;
  border-bottom-right-radius: 27px !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.mini-notices div span {
  color: #ffffff !important;
  font-family: inherit !important;
}

.minigameHover:hover>.minigamePicHover {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.minigameHover:hover>.minigamePlayHover {
  opacity: 1;
  z-index: 1;
}

.minigameHover {
  position: relative;
  // z-index: 1;
}

.minigamePicHover {
  position: relative;
  // z-index: 1;
}

.minigamePlayHover {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  left: 0;
  right: 0;
  top: 1vh;
  bottom: 0;
  z-index: -1;
  padding-top: 10px;
}

.minigameImg {
  width: 100% !important;
  border-radius: 10% !important;
  padding: 0 5px 10px;
  height: 100%;
}

.htRow {
  padding: 0px 10px;
}

.minigameDropdown {
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 5px 10px;
  height: 40px;
}

.dropdown:before {
  color: white;
}

.miniAppDownload {
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7b49fc;
  padding: 10px;
  border-radius: 10px;
  width: 50%;
}

.unityMobile-section {
  background-image: url("../../assets/image/minigame/loading/loadingpageh5-min.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.topLoading-mobile {
  position: relative;
  top: 28%;
  left: 17%;
  width: 80%;
}

.topLoading-mobile img {
  width: inherit;
}

.phone-centeredLoading {
  position: relative;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.bottomLoading-mobile {
  position: relative;
  top: 34%;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}

.progress {
  background: #676768;
}

.progress-bar {
  background-image: linear-gradient(to right, #a736b6, #4b7bf5, #00aaff, #00cdf7, #15e9d9);
  background-size: auto;
}

.progress-bar-animated {
  animation: 1s linear infinite;
}

.gameRulesHeaderMobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block !important;
}

.gameRulesMobile {
  position: absolute;
  width: 85%;
  left: 8%;
  top: 30%;
  padding: 20px;
  border-radius: 8px;
  background-color: #020d22;
  border: 3px solid #1b7de1;
  margin-top: -75px;
}

.responsive-iframe {
  border: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

._loading_overlay_content {
  top: 50%;
  left: 0;
  right: 0;
  position: fixed;
}

.modal .react-swipeable-view-container {
  height: 100% !important;
}

.modal .slick-slider {
  height: 100% !important;
  padding: 0px;
}

.modal .carouselStyle .slick-dots {
  bottom: 10px;
  display: block !important;
}

.receipt-section .title {
  min-width: fit-content !important;
}

.receipt-section .button {
  height: auto;
  margin-left: 33px;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  text-align: center;
  padding: 5px 7px;
  border: 1px solid #212529;
  color: #212529 !important;
  background-color: #eaeff2 !important;
  border-radius: 5px !important;
}

.receipt-section input {
  visibility: hidden;
  width: 100%;
}

.receipt-section span {
  margin-left: 10px;
  padding: 5px 7px;
  white-space: normal;
  min-width: 100px;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.carouselStyle.noticeCarousel {
  .slick-track {
    float: left;
  }

  .slick-prev {
    display: block !important;
    z-index: 1;
    left: 105px;
    top: 490px;
    font-size: 0px;
    // background-color: #182333;
    height: 20px;
    background: url("../../assets/image/cleft.png") no-repeat 0 0;
    background-size: contain;
  }

  .slick-next {
    display: block !important;
    z-index: 1;
    right: 105px;
    top: 490px;
    font-size: 0px;
    // background-color: #182333;
    height: 20px;
    background: url("../../assets/image/cright.png") no-repeat 0 0;
    background-size: contain;
  }

  .slick-prev:before {
    display: block !important;
    content: "";
    font-size: 30px;
    top: 490px;
    left: 105px;
  }

  .slick-next:before {
    display: block !important;
    content: "";
    font-size: 30px;
    top: 490px;
    left: 105px;
  }

  .slick-list {
    margin: 0 0px;
  }
}

.purseBetDiv {
  display: flex; //all in-line
  justify-content: center; //text inside horizontal center
  align-items: center; //text & icon vertical center

  position: absolute; //position above chips nav bar
  bottom: 130px;
  left: 35%;

  height: 35px;
  width: 35%;

  background: var(--theme-color);
  color: white;
  font-weight: bold;
  border-radius: 15px;
}


/*** for crypto - 如何购买加密货币 ***/
.panel-heading a:before {
  /*font-family: 'Glyphicons Halflings';
	content: "\e114";*/
  content: "\f0d8";
  font-family: FontAwesome;
  color: blue;
  float: right;
  transition: all 0.5s;
}

.table-bordered {
  box-shadow: 0px 0px 5px 3px rgb(0 0 0 / 10%);
  border-radius: 6px;
}

.showNormalWrap {
  padding: 16px;
  white-space: normal !important;
  background-color: rgb(253, 253, 253);
}

.cryptoPaymentHelpNormalWrap {
  padding: 16px;
  white-space: normal !important;
  font-size: 14px;
}

.cryptoPaymentHelpTblHeader {
  background-color: black;
  color: white;
}

.howToBuyCryptocurrency {
  outline: none;
  border: 0px;
  margin-bottom: 0px;
  margin-right: 5px;
  color: rgb(0, 0, 255) !important;
  font-weight: bold;
}

.expandCollapse-iconDisplay {
  display: contents;
}

/*** for crypto - 如何购买加密货币 ***/

.theme-cp98-white {
  color: #607989;

  .cp98-main-nav {
    height: calc(60px + env(safe-area-inset-top) / 4) !important;
    background-color: #f7f7f7 !important;
  }

  .headerBtn {
    border: 1px solid #59789b;
    border-radius: 8px;
    color: #59789b;
    padding: 5px;
    margin: 2px;
    font-size: 11px;
  }

  .gameCatoContainer {
    padding: 3px 0px;
    overflow: auto;
    min-width: 36px;
    border-radius: 15px;
    background-color: #ecedef;
    margin: 8px;
  }

  .gameCatActive {
    background-image: url('../../assets/image/cp98/gameBtn.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 121%;
  }

  .gameCat {
    font-size: 12px;
    font-weight: bold;
    color: #607989;
    width: 100%;
    height: 37px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px;
  }

  .gameObjContainer {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    border-radius: 12px;
    border: 1px solid #e4e6ed;
    background-color: #fff;
    margin-left: 10px;
  }

  .gameTitle {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 21px;
    width: 50px;
    margin: -5px auto auto;
    font-size: 11px;
    color: #fff;
    background-image: url('../../assets/image/cp98/gameTitle.png');
    background-repeat: no-repeat;
    background-size: 50px 21px;
  }

  .gameObj {
    margin-top: 2px;
    font-size: 12px;
    color: #607989;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }

  .tabbar a.tab-link.active,
  html:not(.watch-active-state) .tabbar a.tab-link:active,
  .tabbar a.tab-link.active-state {
    color: #739ee1;
  }

  .hot-key-bar .lg-button {
    color: #739ee1 !important;
    background-color: white;
  }
}

.register-content-bgcolor {
  background-color: white;
}

#lineTd {
  background: #fff url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxsaW5lIHgxPSIwIiB5MT0iMCIgeDI9IjEwMCUiIHkyPSIxMDAlIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEiLz48L3N2Zz4=) no-repeat 100% center;
}

.transaction-page-loading {
  fill: black !important;
}

.imgTitle {
  color: var(--theme-color);
  font-weight: 600;
  width: fit-content;
  position: relative;
  top: -48px;
  left: 22px;
  font-size: 14px;  
}

#sport1ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

#sport2ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    width: 100px;
    font-size: 14px;
  }

  .zhTitle {
    top: -52px !important;
  }
}

#sport3ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    width: 100px;
    font-size: 14px;
  }

  .zhTitle {
    top: -52px !important;
  }
}

#lottery1ImgContainer {
  height: 457px;

  .imgTitle {
    position: relative;
    top: -419px;
    left: 22px;
  }
}

#live1ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

#live2ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

#live3ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

#live4ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

#live5ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

#live6ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

#slots1ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

#slots2ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

#slots3ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

#card1ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
  }
}

#card2ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
  }
}

#card3ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
  }
}

#fishing1ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
    font-size: 14px;
  }
}

.mainAction a {
  text-align: center;
  display: grid;
  padding: 6px;
  justify-items: center;
}

.theme-bet365-green {
  .carouselContainer {
    height: auto;
    padding-top: 10px;
  }

  .hot-key-bar {
    background-color: var(--theme-color) !important;
  }

  .hot-key-bar .lg-button {
    background-color: white !important;
    color: var(--theme-color) !important;
  }

  .carouselStyle .slick-dots {
    display: block !important;
    bottom: 5px;
  }

  .slick-dots li.slick-active button:before {
    color: var(--theme-color) !important;
  }

  .icon-support::before {
    color: #2d2d2d;
  }

  .hot-key-bar .row .list-container dt {
    color: white !important;
  }

  .hot-key-bar .row .list-container #hotKeyGetReward {
    color: #FAD910 !important;
  }

  .material-icons {
    font-family: 'Material Icons' !important;
  }
}

.awardNavbar-theme-white {
  background-color: #f7f7f7 !important;
}

.award-dropdown {
  top: 0px !important;
}

.repeatBtn {
  color: #ffff !important;
}

.fontSize11 {
  font-size: 11px !important;
}

.icon-eye {
  height: 27px;
  width: 27px;
  left: auto;
  right: 10px;
  position: absolute;
  z-index: 1;
  bottom: auto;
  top: 5px;
}

.navbar-container {
  background-color: white;
  padding: 10px;
  font-size: 0;
  justify-content: center;
  text-transform: uppercase;
}

.navbar-container ul {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  vertical-align: middle;
}

.navbar-container ul li {
  display: inline-block;
  width: 100%;
  font-size: 11px;
}

.navbar-container ul li a {
  color: black;
  text-decoration: none;
  display: flex;
  padding: 10px;
  transition: color 0.5s;
  justify-content: center;
}

.navbar-container ul li .underline {
  height: 3px;
  background-color: transparent;
  width: 0%;
  transition: width 0.2s, background-color 0.5s;
  margin: 0 auto;
}

.navbar-container ul li.active-link .underline {
  width: 100%;
  background-color: var(--theme-color);
}

.navbar-container ul li:active a {
  color: var(--theme-color);
  transition: none;
}

.navbar-container .active-link a {
  color: red !important;
}

.ifscInput {
  background-color: lightgrey !important;
}

.miniskinCarouselPadding {
  padding: 12px;
}

.mini-toolbar {
  bottom: 0;
  top: auto;
  position: fixed;
  z-index: 2;
  border-top: 1px solid #929292;
}

.mini-toolbar .MuiBottomNavigation-root {
  background-color: #000;
}

.mini-toolbar-bottom {
  height: 50px;
  border-top: 1px solid #929292;
}

.mini-tool {
  background-color: var(--theme-color) !important;
  color: #929292 !important;
}

.top-win-container {
  overflow: auto;
  white-space: nowrap;
  width: 100vw;
  height: 50px;
  margin-top: 15px;

}

.top-win-header {
  display: inline-block;
  min-width: 65px;
  text-align: center;
  padding-top: 14px;
  margin: 0 20px;
}

.top-win-list-container {
  padding: 5px 17px;
  border-radius: 10px;
  display: block;
  min-width: 75px;
  background-color: #15162E;
  margin-right: 15px;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.top-win-header-button {
  font-weight: bold;
}

@keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    top: 0%;
  }

  100% {
    -webkit-transform: translate3d(0, -307px, 0);
    transform: translate3d(0, -307px, 0);
    top: 100%;
  }
}

.top-win-list {
  border: 1px solid #999;
  position: relative;
  overflow: hidden;
  border-color: #1a1b39;
  margin-top: 0;
  margin-left: 15px;
}

.luzhuPlayTypeName {
  display: inline-block;
  text-align: center;
  border: 1px solid #C4C3C3;
  width: 30%;
  position: relative;
  border-radius: 5px;
  margin: 5px;
  background: #F7F7F7;
  padding: 5px 0;
}

.luzhuPlayTypeName.active {
  color: #fff;
  background: var(--theme-color);
}

.luzhuLoadResult {
  display: inline-block;
  margin: 5px;
  width: 25px;
  min-height: 60px;
  background: #f5f5f5;
  border-radius: 5px;
  text-align: center;
  padding: 0 3px;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
  overflow: hidden;
}

.luzhuotherLoadResult {
  display: inline-block;
  margin: 5px;
  width: 25px;
  min-height: 60px;
  background: #f5f5f5;
  border-radius: 5px;
  text-align: center;
  padding: 0 5px;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
  overflow: hidden;
}

.mini-google {
  .abcRioButton {
    background: none;
    box-shadow: none;
  }

  .abcRioButtonContents {
    margin-left: 0;
    padding: 0px !important;
  }

  .abcRioButtonIcon {
    padding: 0px !important;
  }
}

.remark-modal {
  width: 45vh;
  margin: 0 auto;
}

.remark-modal .remark-title {
  text-align: center;
  font-size: 15px;
  padding-bottom: 10px;
  font-weight: bold;
}

.remark-modal .remark-subtitle {
  font-size: 14px;
  padding-bottom: 5px;
  color: #a4a4a4;
}

.remark-modal ol li {
  font-size: 14px;
  color: #a4a4a4;
}

.backdrop {
  opacity: 0.5 !important;
}

.close-button {
  background-color: #f44336;
  color: white;
  width: 100px;
  height: 40px;
  border-radius: 6px;
}

.confirm-button {
  background-color: white;
  color: #f44336;
  width: 100px;
  height: 40px;
  border-radius: 6px;
  border-style: solid;
  border-color: #f44336;
}

.confirm-button-blue {
  background-color: #6595DE;
  color: white;
  width: 100px;
  height: 40px;
  border-radius: 6px;
}

.confirm-button-grey {
  background-color: #C1C1C1;
  color: white;
  width: 100px;
  height: 40px;
  border-radius: 6px;
}

.profit-modal {
  width: 45vh;
  margin: 0 auto;
  // top: 20%;
}

.profit-modal .modal-content {
  padding: 10px;
}

.profit-modal .profit-modal-title {
  text-align: center;
  font-size: 15px;
  font-weight: 550;
  padding-bottom: 5px;
}

.profit-modal .profit-modal-amount {
  text-align: center;
  font-size: 18px;
  font-weight: 550;
  color: red;
  padding-bottom: 10px;
}

.profit-modal-table tr {
  border-bottom: 1px solid #bbbbbb;
  border-style: dotted;
  height: 40px;
}

.profit-modal-table td:nth-child(1) {
  width: 30%;
  color: #bbbbbb;
  font-size: 14px;
}

.profit-modal-table td:nth-child(2) {
  font-size: 14px;
}

.audit-modal-table tr td {
  border-width: 1px;
}

.audit-modal-table td:nth-child(1) {
  color: #868686;
  font-weight: bold;
}

.audit-modal-table td:nth-child(2) {
  color: red;
}

.audit-title {
  font-size: 30px;
  font-weight: bold;
}

.yu-e-bao {
  .balance-box {
    border: 1px solid #E3E3E3;
    padding: 15px 10px;
    width: 50%;
    padding-left: 20px;
  }

  .balance-box-title {
    padding-top: 10px;
  }

  .transfer-box {
    width: 33%;
    font-size: 15px;
    font-weight: bold;
    padding: 30px 10px;
  }

  .transfer-box-title {
    padding-bottom: 15px;
    height: 60px;
  }

  .transfer-box>input {
    border: none;
    border-bottom: 1px solid;
    width: 100%;
  }

  .exchange-box {
    border: 1px solid #cecece;
    color: #666;
    border-radius: 4px;
    height: 40px;
    margin-right: 15px;
    width: 55%;
  }

  .exchange-box::placeholder {
    padding-left: 15px;
  }

  .exchange-button {
    background-color: #e23a3a;
    border-radius: 6px;
    color: white;
    height: 40px;
    width: 40%;
    float: right;
  }

  .submit-button {
    background-color: #17af00;
    color: white;
    width: 100%;
    border-radius: 6px;
    height: 40px;
    margin-top: 15px;
  }

  .remark {
    font-size: 13px;
    color: #c2c2c2;
    text-align: center;
    padding-top: 15px;
  }

  .profit-box {
    float: left;
    width: 30%;
    margin: 0px 5px;
    border: 1px solid #dadada;
    border-radius: 6px;
  }

  .profit-box>div:nth-child(1) {
    background-color: #d9534f;
    border-radius: 5px 5px 0px 0px;
    color: white
  }

  .profit-box>div:nth-child(2) {
    padding: 10px 0px;
  }

  .date-btn {
    list-style: none;
    float: left;
    width: 16%;
    padding-left: 6px;
  }

  .yuebaoTable td {
    font-size: 13px;
  }

  .tab3Table {
    font-size: 13px;
  }

  .tab3Table tbody tr td {
    padding: 15px 0px;
  }

}

.pm-dialog {
  transform: translate(-50%, -50%) !important;
  left: 50%;
  top: 50%;
  position: absolute;
  margin: 0;
  width: 78%;
  max-width: 400px;
  --bs-modal-bg: none;
}

.pm-dialog .modal-content {
  border: none !important;
}

.pm-dialog .notice-header {
  position: absolute;
  text-align: center;
  width: 100%;
  color: #fff;
  font-weight: 400;
  padding: 10px;
  font-size: 17px;
  background-color: #3A3A3A;
}

.pm-dialog .notice-close {
  position: absolute;
  right: 10px;
  top: 5px;
  color: white;
  font-size: 25px;
  z-index: 5;
}

.pm-dialog .notice-title {
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 60px;
}

.pm-dialog .notice-content {
  max-height: 360px;
  overflow: auto;
  padding: 0px 20px;
  height: 100%;
}

.pm-dialog .notice-confirm {
  color: #ff5722;
  text-align: center;
  font-weight: 700;
  padding: 10px 0px 15px 0px;
}

.pm-dialog .pm-img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 15px;
}

.pm-modal .modal-backdrop {
  opacity: 0.85;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.pm-modal .carouselStyle.noticeCarousel .slick-prev {
  left: 0px;
  top: 53%;
  background: url("../../assets/image/2left.png") no-repeat 0 0;
  background-position-y: 10px;
  background-position-x: 1px;
  background-color: #000000bf;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 40px;
  width: 26px;
  position: relative;
}

.pm-modal .carouselStyle.noticeCarousel .slick-next {
  right: 0px;
  top: 53%;
  background: url("../../assets/image/2right.png") no-repeat 0 0;
  background-position-y: 10px;
  background-position-x: 4px;
  background-color: #000000bf;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 40px;
  width: 24px;
}

.pm-modal .bhdLno {
  width: 78vw !important;
  height: 78vw !important;
}

// .pm-modal .slick-track{
//   height: 600px;
//   line-height: 600px;
// }

.pm-modal .slick-slide {
  height: inherit;
}

.pm-modal .slick-slide>div:first-child {
  line-height: normal;
  vertical-align: middle;
  margin-top: 20px;
  display: inline-block;
  width: inherit;
}

.mini-google {
  .abcRioButton {
    background: none;
    box-shadow: none;
  }

  .abcRioButtonContents {
    margin-left: 0;
    padding: 0px !important;
  }

  .abcRioButtonIcon {
    padding: 0px !important;
  }
}

.pm-title-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: 27px;
}

.pm-title {
  background: linear-gradient(180deg, #5c0afb 0%, #9627eb 100%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 8px;
  text-align: center;
  width: fit-content;
  min-width: 180px;
  max-width: 270px;
  min-height: 35px;
}

.theme-blaze-black,
.theme-blaze-white {
  .icon-homeactive:before {
    content: "\e90e";
    color: #4368ff;
  }

  .icon-giftactive:before {
    content: "\e90c";
    color: #4368ff;
  }

  .icon-affiliateactive:before {
    content: "\e93d";
    color: #4368ff;
  }

  .icon-supportactive:before {
    content: "\e918";
    color: #4368ff;
  }

  .icon-mineactive:before {
    content: "\e912";
    color: #4368ff;
  }

  .tabbar a.tab-link.active,
  html:not(.watch-active-state) .tabbar a.tab-link:active,
  .tabbar a.tab-link.active-state {
    color: #4368ff;
  }
}

.toolbarTheme {
  z-index: 2;
}

.blazetheme {
  .download-top .icon {
    font-size: 35px;
  }

  .carouselStyle .slick-dots {
    display: block !important;
    bottom: 5px;
  }

  .paddingTop35 {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 35px 0 0 0;
  }

  .paddingTop35_app {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 0 0 0;
  }

  .top45 {
    top: 45px !important;
  }

  .card-image {
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .provider-label {
    text-align: left;
    font-size: 11px;
  }

  .card {
    border-radius: 5px;
    display: block;
    margin: 0 0 0 5px;
  }

  .card-title {
    font-size: 12px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
    width: 85%;
  }

  .card-body {
    display: inline-flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  .card-body svg {
    position: absolute;
    right: 5px;
  }

  .card-deck {
    margin: 0;
    justify-content: flex-start;
  }

  .slider_padding{
    width: 100%;
    padding: 0px;
  }

  .card-deck .col-4 {
    padding: 10px 2px !important;
  }

  .banner {
    background: linear-gradient(to bottom, #202020, #3d3d3d) !important;
    color: #ffffff;
    justify-content: center;
    align-items: center;
  }

  .close-icon {
    color: rgb(255, 255, 255);
    float: left;
    top: 1%;
    left: 3%;
    position: absolute;
  }

  .inbox-icon {
    color: rgb(255, 255, 255);
    float: right;
    background: rgb(67, 104, 255);
    width: 50px;
    float: right;
    padding: 8px;
    right: 0;
    top: 0;
    position: absolute;
    height: 44px;
  }

  .filter-parent {
    padding-bottom: 15px;
  }

  .filter-box {
    display: inline-flex;
    width: 50%;
    justify-content: center;
    align-items: center;
  }

  .filter-menu .MuiPopover-paper {
    width: 100%;
    top: 240px !important;
  }

  .searchtype-menu .MuiPopover-paper {
    width: 50%;
    top: 105px !important;
  }

  .provider-menu .MuiPopover-paper {
    width: 50% !important;
  }

  .MuiButton-label {
    color: #8E8E8E;
    text-transform: none;
  }

  .MuiButton-text {
    width: 100%;
  }

  .filter-box svg {
    color: #8E8E8E;
    float: right;
    position: absolute;
    right: 0;
  }

  .search {
    padding: 16px 10px 20px;
  }

  .filter-menu .MuiPopover-paper .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover,
  .searchtype-menu .MuiPopover-paper .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background: none;
  }

  .MuiDivider-root {
    margin: 10px;
  }

  .filter-result {
    color: #ffffff;
  }

  .filter-box #fade-button {
    margin: auto 10px;
  }

  .search .search-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 16px;
    cursor: pointer;
    max-width: 100%;
    position: relative;
    margin-bottom: 10px;
    height: 45px;
  }

  .search .search-input input {
    background: transparent;
    height: 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
    padding: 0;
    width: 100%;
    margin-left: 12px;
  }

  .searchBox {
    border: none;
    border-radius: 5px;
    padding: 15px;
    outline: none;
  }

  #home-controller {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: -6px -6px 40px;
    overflow-x: auto;
    margin-bottom: 15px;
    padding: 0 10px;
    height: 65px;
    width: 100vw;
  }

  #home-controller .pill-nav {
    border-radius: 100px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    padding: 0 11px;
    margin: 0 6px;
    cursor: pointer;
    min-width: max-content;
  }

  #listing-controller {
    padding: 27px 10px 0;
    padding-top: 0px;
    margin-bottom: 45px;
  }

  .game-card-headline {
    font-size: 16px;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
  }

  .game-card-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 7px;
  }

  .game-card-option {
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
    margin-left: auto;
    padding-right: 10px;
    padding: 5px 2px;
  }

  .top-win-list {
    border: none;
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
  }

  .top-win-list table thead td {
    font-size: 16px;
  }

  .top-win-list table tbody tr {
    height: 50px;
  }

  .top-win-list table tbody td {
    font-size: 12px;
    font-weight: bold;
  }

  .blaze-toolbar {
    //height: 55px;
    z-index: 2;
  }

  .banner-top {
    border-bottom: 1px solid #333333;
    height: 55px !important;
  }

  .search-header-parent {
    padding-top: 7px;
  }

  .provider-slider {
    padding: 0px 5px;
  }

  .provider-slider .slider-sub {
    border-radius: 5px;
    height: 80px;
    display: flex !important;
    justify-content: center;
    width: 115px !important;
    margin: auto 3px;
  }

  .provider-slider img {
    margin: auto;
    display: block;
    width: 70%;
  }

  .suggested-container {
    font-size: 13px;
    padding: 7px 13px;
  }

  .suggested-title {
    font-size: 20px;
    padding: 13px;
  }

  .sitebar-menuicon {
    color: #494949;
    padding-right: 10x;
  }

  .searchfilter-selected {
    width: 100%;
    margin: 1%;
    text-align: left;
    border: none;
  }

  .searchfilter {
    width: 100%;
    margin: 1%;
    color: #8E8E8E;
    text-align: left;
    border: none;
  }

  .filter-box-left {
    color: white;
    min-width: 50%;
    float: left;
    display: inline-flex;
    width: 40%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 38px;
    margin-left: 12px;
    font-weight: 700;
    font-size: 12px;
  }

  .filter-box-right {
    width: 185px;
    float: right;
    color: white;
    display: inline-flex;
    width: 40%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 38px;
    margin-right: 10px;
    font-weight: 700;
    font-size: 12px;
  }

  .searchfilter-category-selected,
  .searchfilter-category-selected:not(:disabled):not(.disabled):active,
  .searchfilter-category:not(:disabled):not(.disabled):active,
  .searchfilter-category:hover {
    width: 30%;
    margin: 1%;
    text-align: left;
    border: none;
    background: none;
  }

  .searchfilter-category {
    width: 30%;
    margin: 1%;
    color: #8E8E8E;
    text-align: left;
    border: none;
  }

  .amountbox {
    padding: 0 10px;
    height: 32px;
    margin: 2px 5px 0px;
    border-radius: 4px;
    width: 130px;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
  }

  .safe-image {
    padding: 18px;
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.theme-blaze-black,
.theme-sport-blue {
  .top45 {
    background: #202020;
  }

  .provider-label {
    color: #ffffff;
  }

  .card {
    background: #202020;
    border: 1px solid #202020;
    color: #ffffff;
  }

  .card-body {
    border-top: 1px solid #333333;
  }

  .card-body svg {
    color: #ffffff;
  }

  .filter-menu .MuiPopover-paper,
  .searchtype-menu .MuiPopover-paper {
    background: #202020;
    color: #8E8E8E;
    border: 1px solid #333333;
  }

  .filter-menu .MuiPopover-paper .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover,
  .searchtype-menu .MuiPopover-paper .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background: none;
    color: #fff;
  }

  .MuiDivider-root {
    border: 1px solid #ffffff;
    background: #202020;
    margin: 10px;
  }

  .filter-result {
    color: #ffffff;
    padding-left: 10px;
  }

  .filter-box #fade-button {
    margin: auto 10px;
    background: #202020;
    border: 1px solid #333333;
  }

  .search .search-input {
    border: 1px solid #333333;
    background: #202020;
  }

  .search .search-input input {
    color: #ffffff;
  }

  .searchBox {
    background: #202020;
    color: #ffffff;
  }

  #home-controller .pill-nav.selected {
    background: #4368ff;
    color: #ffffff
  }

  #home-controller .pill-nav {
    background: #202020;
    border: 1px solid #333333;
    color: #8E8E8E;
  }

  .game-card-headline {
    color: #5C5C5C;
  }

  .game-card-title {
    color: #FFFFFF;
  }

  .game-card-option {
    color: #FFFFFF;
  }

  .top-win-list table thead td {
    color: #8E8E8E;
  }

  .top-win-list table tbody tr {
    border-bottom: 1px solid #333333;
  }

  .top-win-list table tbody td {
    color: #fff;
  }

  .provider-slider .slider-sub {
    background: #202020;
    border: 1px solid #333333;
  }

  .suggested-container {
    color: #8E8E8E;
  }

  .suggested-container span {
    color: #ffffff;
  }

  .suggested-title {
    color: #ffffff;
  }

  .searchfilter-selected {
    color: #ffffff;
  }

  .filter-box-left {
    background: #202020;
    border: 1px solid #333333;
  }

  .filter-box-right {
    background: #202020;
    border: 1px solid #333333;
  }

  .pipe-filter {
    color: #ffffff;
  }

  .sidebar-menuicon {
    color: #ffffff
  }

  .searchfilter-category-selected {
    color: #ffffff;
  }

  .amountbox {
    border: 1px solid #8E8E8E;
    color: #fff;
    background: #202020;
  }

  .btmNavDeposit {
    font-size: 26px !important;
    display: block !important;
    color: #fff !important;
    background-color: #161616 !important;
  }

  .MuiBottomNavigation-root {
    background: #161616 !important;
  }
}

.carouselStyle.newMiniCarousel {
  .slick-slide {
    filter: blur(0px);
  }

  .slick-slide.slick-active.slick-center.slick-current {
    margin: 0 5px 0 5px;
    filter: blur(0);
  }

  .slick-slide {
    -webkit-filter: 0px !important;
    filter: 0px !important;
    position: relative;
    // width: 335px !important;
    height: 180px;
    overflow: hidden;
    border-radius: 10px;
  }

  .slick-track {
    min-width: 20000px;
  }

  .banner-image {
    display: block;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
    /*zoom: 0.45;
    -moz-transform: scale(0.45);*/
    width: 100%;
    height: auto;
  }

  .slick-list {
    height: 100%;
    border-radius: 0px;
  }
}

.allslottheme {
  background-image: linear-gradient(to bottom, #025461, #0f434c, #133339, #132326, #0e1415);

  .download-top .icon {
    font-size: 35px;
  }

  .carouselStyle .slick-dots {
    display: block !important;
    bottom: 5px;
  }

  .paddingTop35 {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 35px 0 0 0;
  }

  .paddingTop35_app {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 0 0 0;
  }

  .top45 {
    top: 45px !important;
  }

  .card-image {
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .provider-label {
    text-align: left;
    font-size: 11px;
  }

  .card {
    border-radius: 5px;
    display: block;
    margin: 0 0 0 5px;
  }

  .card-title {
    font-size: 12px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
    width: 85%;
  }

  .card-body {
    display: inline-flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  .card-body svg {
    position: absolute;
    right: 5px;
  }

  .card-deck {
    margin: 0;
    justify-content: flex-start;
  }

  .card-deck .col-4 {
    padding: 5px 3px !important;
  }

  .banner {
    background: #062327 !important;
    color: #ffffff;
    justify-content: center;
    align-items: center;
  }

  .close-icon {
    color: rgb(255, 255, 255);
    float: left;
    top: 1%;
    left: 3%;
    position: absolute;
  }

  .inbox-icon {
    color: rgb(255, 255, 255);
    float: right;
    background: #00CB5C;
    width: 50px;
    float: right;
    padding: 8px;
    right: 0;
    top: 0;
    position: absolute;
    height: 44px;
  }

  .filter-parent {
    padding-bottom: 15px;
  }

  .filter-box {
    display: inline-flex;
    width: 50%;
    justify-content: center;
    align-items: center;
  }

  .filter-menu .MuiPopover-paper {
    width: 100%;
    top: 240px !important;
  }

  .searchtype-menu .MuiPopover-paper {
    width: 50%;
    top: 105px !important;
  }

  .provider-menu .MuiPopover-paper {
    width: 50% !important;
  }

  .MuiButton-label {
    color: #8E8E8E;
    text-transform: none;
  }

  .MuiButton-text {
    width: 100%;
  }

  .filter-box svg {
    color: #8E8E8E;
    float: right;
    position: absolute;
    right: 0;
  }

  .search {
    padding: 16px 10px;
  }

  .filter-menu .MuiPopover-paper .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover,
  .searchtype-menu .MuiPopover-paper .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background: none;
  }

  .MuiDivider-root {
    margin: 10px;
  }

  .filter-result {
    color: #ffffff;
  }

  .filter-box #fade-button {
    margin: auto 10px;
  }

  .search .search-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 7px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 5px;
    cursor: pointer;
    max-width: 100%;
    position: relative;
    height: 45px;
  }

  .search .search-input input {
    background: transparent;
    height: 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
    padding: 0;
    width: 100%;
    margin-left: 12px;
  }

  .searchBox {
    border: none;
    border-radius: 5px;
    padding: 15px;
    outline: none;
  }

  #home-controller {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: -6px -6px 40px;
    overflow-x: auto;
    margin-bottom: 0px;
    padding: 0 10px;
    height: 65px;
    width: 100vw;
  }

  #home-controller .pill-nav {
    height: 40px;
    display: inline-flex;
    align-items: center;
    padding: 0 11px;
    cursor: pointer;
    min-width: max-content;
  }

  #listing-controller {
    padding: 27px 10px 0;
    padding-top: 0px;
    margin-bottom: 45px;
  }

  .game-card-headline {
    font-size: 16px;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
  }

  .game-card-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 7px;
  }

  .game-card-option {
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
    margin-left: auto;
    padding-right: 10px;
    padding: 5px 2px;
  }

  .top-win-list {
    border: none;
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
  }

  .top-win-list table thead td {
    font-size: 16px;
  }

  .top-win-list table tbody tr {
    height: 50px;
  }

  .top-win-list table tbody td {
    font-size: 12px;
    font-weight: bold;
  }

  .blaze-toolbar {
    //height: 55px;
    z-index: 2;
  }

  .banner-top {
    border-bottom: 1px solid #333333;
    height: 55px !important;
  }

  .search-header-parent {
    padding-top: 7px;
  }

  .provider-slider {
    padding: 0px 5px;
  }

  .provider-slider .slider-sub {
    border-radius: 5px;
    height: 80px;
    display: flex !important;
    justify-content: center;
    width: 115px !important;
    margin: auto 3px;
  }

  .provider-slider img {
    margin: auto;
    display: block;
    width: 70%;
  }

  .suggested-container {
    font-size: 13px;
    padding: 7px 13px;
  }

  .suggested-title {
    font-size: 20px;
    padding: 13px;
  }

  .sitebar-menuicon {
    color: #494949;
    padding-right: 10x;
  }

  .searchfilter-selected {
    width: 100%;
    margin: 1%;
    text-align: left;
    border: none;
  }

  .searchfilter {
    width: 100%;
    margin: 1%;
    color: #8E8E8E;
    text-align: left;
    border: none;
  }

  .filter-box-left {
    color: white;
    min-width: 50%;
    float: left;
    display: inline-flex;
    width: 40%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 38px;
    margin-left: 12px;
    font-weight: 700;
    font-size: 12px;
  }

  .filter-box-right {
    width: 185px;
    float: right;
    color: white;
    display: inline-flex;
    width: 40%;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 38px;
    margin-right: 10px;
    font-weight: 700;
    font-size: 12px;
  }

  .searchfilter-category-selected,
  .searchfilter-category-selected:not(:disabled):not(.disabled):active,
  .searchfilter-category:not(:disabled):not(.disabled):active,
  .searchfilter-category:hover {
    width: 30%;
    margin: 1%;
    text-align: left;
    border: none;
    background: none;
  }

  .searchfilter-category {
    width: 30%;
    margin: 1%;
    color: #8E8E8E;
    text-align: left;
    border: none;
  }

  .amountbox {
    padding: 0 10px;
    height: 32px;
    margin: 2px 5px 0px;
    border-radius: 10px;
    max-width: 120px;
    font-size: 14px;
    line-height: 30px;
    text-align: right;
  }

  .safe-image {
    padding: 18px;
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .depositbtn {
    margin: 2px 5px 0px;
    border-radius: 10px;
    width: 35px;
    color: rgb(255, 255, 255);
    background: #00cb5c;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
  }

  .withdrawbtn {
    margin: 2px 5px 0px;
    border-radius: 10px;
    width: 35px;
    color: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 10%);
    font-size: 14px;
    line-height: 30px;
    text-align: center;
  }
}

.theme-allslot {
  .zhongheuserface {
    background-image: linear-gradient(to bottom, #025461, #0f434c, #133339, #132326, #0e1415);
  }

  .navbarheaders {
    background: #083238;
  }

  .loginbtn {
    padding: 6px;
    height: 32px;
    margin: 3px;
    border-radius: 5px;
    width: 85px;
    color: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 10%);
    font-size: 14px;
    text-align: center;
  }

  .registerbtn {
    padding: 0px 10px;
    height: 32px;
    margin: 2px 5px 0px;
    border-radius: 5px;
    width: 85px;
    color: rgb(255, 255, 255);
    background: #00cb5c;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
  }

  .allslot-content-block {
    margin-top: 15px;
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 5%);
    margin: 0 auto;
    width: calc(100% - 20px);
    border-radius: 10px;
  }

  .allslot-content-block dl {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .allslot-content-block dt {
    margin: 0;
    padding: 0;
  }

  .allslot-content-block dt span {
    font-size: 25px;
    border-radius: 50%;
    padding: 5px;
    display: block;
  }

  .allslot-content-block dd {
    font-size: 10px;
    margin: 4px 0 0;
    color: #fff;
  }

  .allslot-content-block .rows {
    display: flex;
    flex-wrap: wrap;
  }

  .allslot-content-block .col20 {
    float: left;
  }

  .icon-shixun:before,
  .icon-deposithome:before,
  .icon-withdrawnhome:before,
  .icon-changlong:before,
  .icon-GRl:before,
  .icon-COOP:before,
  .icon-KY:before,
  .icon-autobets:before,
  .icon-SB:before,
  .icon-leaderboard:before,
  .icon-yuebao:before,
  .icon-signin:before,
  .icon-mine:before,
  .icon-zoushi:before,
  .icon-gift:before,
  .icon-affiliate:before,
  .icon-support:before {
    color: #fff;
  }

  .icon-mineactive:before,
  .icon-giftactive:before,
  .icon-affiliateactive:before,
  .icon-supportactive:before,
  .icon-homeactive:before {
    color: #00cb5c;
  }

  .provider-label {
    color: #ffffff;
  }

  .card {
    background: rgba(255, 255, 255, 5%);
    border: 1px solid #202020;
    color: #ffffff;
  }

  .card-body {
    border-top: 1px solid rgba(255, 255, 255, 5%);
  }

  .card-body svg {
    color: #ffffff;
  }

  .my-collapsible_card_body {
    background: rgba(0, 0, 0, 90%);
    border: 0px;
    color: #ffffff;
  }

  .toolbar {
    box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
    border-top: 0px !important;
  }

  .filter-menu .MuiPopover-paper,
  .searchtype-menu .MuiPopover-paper {
    background: #202020;
    color: #8E8E8E;
    border: 1px solid #333333;
  }

  .filter-menu .MuiPopover-paper .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover,
  .searchtype-menu .MuiPopover-paper .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background: none;
    color: #fff;
  }

  .MuiDivider-root {
    border: 1px solid #ffffff;
    background: #202020;
    margin: 10px;
  }

  .filter-result {
    color: #ffffff;
    padding-left: 10px;
  }

  .filter-box #fade-button {
    margin: auto 10px;
    background: #202020;
    border: 1px solid #333333;
  }

  .search .search-input {
    background: rgba(255, 255, 255, 5%);
  }

  .search .search-input input {
    color: #ffffff;
  }

  .search .search-input input::placeholder {
    color: rgba(255, 255, 255, 50%);
  }

  .search .search-input input::-webkit-input-placeholder {
    /* Edge */
    color: rgba(255, 255, 255, 50%);
  }

  .search .search-input input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 50%);
  }

  .search .search-input .search-icon-box {
    padding: 6px;
    background-color: rgba(255, 255, 255, 10%);
    border-radius: 5px;
    width: 35px;
    height: 35px;
  }

  .tabbar a.tab-link.active,
  html:not(.watch-active-state) .tabbar a.tab-link:active,
  .tabbar a.tab-link.active-state {
    color: #00cb5c;
  }

  #home-controller .pill-nav.selected {
    color: #00cb5c;
    border-bottom: 2px solid #00cb5c;
  }

  #home-controller .pill-nav {
    color: #fff;
    border-bottom: 2px solid rgba(255, 255, 255, 10%);
  }

  .game-card-headline {
    color: #5C5C5C;
  }

  .game-card-title {
    color: #FFFFFF;
  }

  .game-card-option {
    color: #FFFFFF;
  }

  .top-win-list table thead td {
    color: #8E8E8E;
  }

  .top-win-list table tbody tr {
    border-bottom: 1px solid #333333;
  }

  .top-win-list table tbody td {
    color: #fff;
  }

  .provider-slider .slider-sub {
    background: #202020;
    border: 1px solid #333333;
  }

  .suggested-container {
    color: #8E8E8E;
  }

  .suggested-container span {
    color: #ffffff;
  }

  .suggested-title {
    color: #ffffff;
  }

  .searchfilter-selected {
    color: #ffffff;
  }

  .filter-box-left {
    background: rgba(255, 255, 255, 10%);
  }

  .filter-box-right {
    background: rgba(255, 255, 255, 10%);
  }

  .pipe-filter {
    color: #ffffff;
  }

  .sidebar-menuicon {
    color: #ffffff
  }

  .searchfilter-category-selected {
    color: #ffffff;
  }

  .amountbox {
    color: #fff;
    background: rgba(0, 0, 0, 30%);
  }

  .btmNavDeposit {
    font-size: 26px !important;
    display: block !important;
    color: #fff !important;
    background-color: #161616 !important;
  }

  .MuiBottomNavigation-root {
    background: #161616 !important;
  }

  .games-rule-page .rules-content-container {
    background-color: #fff !important;
  }

  .games-rule-page .rules-content-container .card {
    color: #000 !important;
  }

  .modal-dialog .btn-outline-secondary {
    color: #fff !important;
  }

  .settingpage .card {
    color: #000 !important;
  }

  .gamecustomsetting .card {
    color: #000 !important;
  }
}

.sidebar-allslot {
  .modal-header {
    border-radius: 0px !important;
    background-color: #083238 !important;
  }

  .sidebar-background {
    padding-top: 5px;
    padding-left: 15px;
    padding-bottom: 5px;
  }

  .modal-body {
    background-image: linear-gradient(to bottom, #025461, #0f434c, #133339, #132326, #0e1415);
  }

  .sidebar-border-bottom {
    border-bottom: 0px;
    padding: 10px 20px !important;
    margin-top: 10px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 5%);
  }

  .sidebar-bottom-logout {
    border-bottom: 0px;
    padding: 10px 20px !important;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #00cb5c;
    color: #fff;
    text-align: center;
  }

  .sidebar-submenu {
    border-bottom: 0px;
    padding: 16px !important;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 25%);
  }

  .sidebar-arrow {
    color: #fff;
    line-height: 30px;
    float: right;
  }

  .sidebar-button {
    background-color: hsla(0, 0%, 100%, .05);
    border-radius: 10px;
  }

  .navbar-container {
    background-color: #083238;
    padding: 0px !important;
    padding-top: 10px;
    font-size: 0;
    justify-content: center;
    text-transform: uppercase;
  }

  .font-title-color {
    color: #FFFFFF;
  }

  .font-color {
    color: #fff;
  }

  .user-section {
    font-size: 15px;
    padding-bottom: 10px;
  }

  .sidebar-active {
    color: #00cb5c;
  }

  .sidebar-underline {
    height: 3px;
    background-color: #00cb5c;
    transition: width 0.2s, background-color 0.5s;
    margin: 0 auto;
  }

  .sidebar-close-btn {
    color: white;
  }
}

.carouselStyle.newOneMiniCarousel {
  .slick-slide.slick-cloned {
    filter: blur(4px);
  }

  .slick-slide.slick-active.slick-center.slick-current {
    margin: 0 5px 0 40px;
    filter: blur(0);
  }

  .slick-slide {
    -webkit-filter: 0px !important;
    filter: 0px !important;
    position: relative;
    // width: 335px !important;
    height: 125px;
    overflow: hidden;
  }

  .slick-track {
    min-width: 6000px;
  }

  .banner-image {
    display: block;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
    /*zoom: 0.45;
    -moz-transform: scale(0.45);*/
    width: 280px;
    height: 125px;
  }

  .slick-list {
    height: 100%;
    border-radius: 0px;
  }
}

.my-collapsible_card_body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.searchFilter {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

.searchResult {
  position: absolute;
  top: 290px;
  // top: 250px;
  height: calc(100% - 310px);
  overflow: scroll;
  width: 100%;
}

.sidebar-light {
  .sidebar-background {
    background-color: #FFFFFF;
    padding-top: 5px;
    padding-left: 15px;
    padding-bottom: 5px;
  }

  .sidebar-border-bottom {
    border-bottom: 2px solid #ECECEC;
  }

  .sidebar-arrow {
    color: #93acd3;
    line-height: 30px;
    float: right;
    margin-top: 4px;
  }

  .sidebar-button {
    background-color: #F5F5F5;
    border-radius: 6px;
  }

  .font-title-color {
    color: #494949;
  }

  .font-color {
    color: #7A7878;
  }

  .user-section {
    font-size: 15px;
    padding-bottom: 10px;
  }

  .sidebar-active {
    color: #4368ff;
  }

  .sidebar-underline {
    height: 3px;
    background-color: #4368ff;
    transition: width 0.2s, background-color 0.5s;
    margin: 0 auto;
  }

  .sidebar-close-btn {
    color: black;
  }


}

.sidebar-dark {
  .sidebar-background {
    background-color: #202020;
    padding-top: 5px;
    padding-left: 15px;
    padding-bottom: 5px;
  }

  .sidebar-border-bottom {
    border-bottom: 2px solid #333333;
  }

  .sidebar-arrow {
    color: #93acd3;
    line-height: 30px;
    float: right;
    margin-top: 4px;
  }

  .sidebar-button {
    background-color: #161616;
    border-radius: 6px;
  }

  .font-title-color {
    color: #FFFFFF;
  }

  .font-color {
    color: #8E8E8E;
  }

  .user-section {
    font-size: 15px;
    padding-bottom: 10px;
  }

  .sidebar-active {
    color: #4368ff;
  }

  .sidebar-underline {
    height: 3px;
    background-color: #4368ff;
    transition: width 0.2s, background-color 0.5s;
    margin: 0 auto;
  }

  .sidebar-close-btn {
    color: white;
  }
}

.sidebar-darkblue {
  .sidebar-background {
    background-color: #1a1b39;
    padding-top: 5px;
    padding-left: 15px;
    padding-bottom: 5px;
  }

  .sidebar-border-bottom {
    border-bottom: 2px solid #333333;
  }

  .sidebar-arrow {
    color: #93acd3;
    line-height: 30px;
    float: right;
    margin-top: 4px;
  }

  .sidebar-button {
    background-color: #161616;
    border-radius: 6px;
  }

  .font-title-color {
    color: #FFFFFF;
  }

  .font-color {
    color: #8E8E8E;
  }

  .user-section {
    font-size: 15px;
    padding-bottom: 10px;
  }

  .sidebar-active {
    color: #4368ff;
  }

  .sidebar-underline {
    height: 3px;
    background-color: #4368ff;
    transition: width 0.2s, background-color 0.5s;
    margin: 0 auto;
  }

  .sidebar-close-btn {
    color: white;
  }
}

.theme-blaze-white,
.theme-sodo-blue,
.theme-bet365-green {
  .top45 {
    background: #ffffff;
  }

  .provider-label {
    color: #494949;
  }

  .card {
    background: #ffffff;
    border: 1px solid #ececec;
    color: #494949
  }

  .card-body {
    border-top: 1px solid #ececec;
  }

  .card-body svg {
    color: #494949;
  }

  .filter-menu .MuiPopover-paper,
  .searchtype-menu .MuiPopover-paper {
    background: #ffffff;
    color: #8E8E8E;
    border: 1px solid #ececec;
  }

  .filter-menu .MuiPopover-paper .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover,
  .searchtype-menu .MuiPopover-paper .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    color: #494949;
  }

  .MuiDivider-root {
    border: 1px solid #ececec;
    background: #000000;
  }

  .filter-result {
    color: #494949;
  }

  .filter-box #fade-button {
    background: #ffffff;
    border: 1px solid #ececec;
  }

  .search .search-input {
    border: 1px solid #ececec;
    background: #ffffff;
  }

  .search .search-input input {
    color: #494949;
  }

  .searchBox {
    background: #ffffff;
    color: #494949;
  }

  #home-controller .pill-nav.selected {
    background: #4368ff;
    color: #ffffff;
  }

  #home-controller .pill-nav {
    background: #ffffff;
    border: 1px solid #ececec;
    color: #8E8E8E;
  }

  .game-card-headline {
    color: #5C5C5C;
  }

  .game-card-title {
    color: #8E8E8E;
  }

  .game-card-option {
    color: #8E8E8E;
  }

  .top-win-list table thead td {
    color: #8E8E8E;
  }

  .top-win-list table tbody tr {
    border-bottom: 1px solid #ececec;
  }

  .top-win-list table tbody td {
    color: #494949;
  }

  .provider-slider .slider-sub {
    background: #ffffff;
    border: 1px solid #ececec;
  }

  .suggested-container {
    color: #8E8E8E;
  }

  .suggested-container span {
    color: #494949;
  }

  .suggested-title {
    color: #494949;
  }

  .navbar-item-title {
    color: #494949 !important;
  }

  .blaze-notice {
    background: #f5f5f5;
  }

  .mini-notices div span {
    color: #494949 !important;
  }

  .searchfilter-selected {
    color: #000000;
  }

  .search-navheader {
    background: #ffffff;
  }

  .filter-box-left {
    background: #ffffff;
    border: 1px solid #ececec;
  }

  .filter-box-right {
    background: #ffffff;
    border: 1px solid #ececec;
  }

  .filter-result {
    color: #494949;
  }

  .pipe-filter {
    color: #494949;
  }

  .sidebar-menuicon {
    color: #494949
  }

  .searchfilter-category-selected {
    color: #000000;
  }

  .amountbox {
    border: 1px solid #ececec;
    color: #494949;
    background: #f5f5f5;
  }

  .btmNavDeposit {
    font-size: 26px !important;
    display: block !important;
    color: #929292 !important;
    background-color: #fff !important;
  }

  .MuiBottomNavigation-root {
    background: #fff !important;
  }
}

.withdrawal-blacklist-center {
  text-align: center;
  margin: 0 auto;
  line-height: 44px;
  font-size: 15px;
  width: auto;
}

.telegramFrame,
.telegramFrame iframe {
  margin: 0 auto;
  display: block;
}

.social-login-parent {
  margin: 16px 0px;
  padding: 0px 30px;
}

.social-login-container {
  display: flex;
  transition: opacity .1s ease-in-out;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
}

.social-login-widget {
  display: inline-block;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  color: white;
  text-align: center;
  white-space: normal;
  margin-right: 8px;
}

.social-login-tooltip {
  pointer-events: none;
  position: relative;
  bottom: calc(100% + 8px);
  font-weight: 500;
  font-size: 12px;
  border-radius: 100px;
  padding: 2px 5px;
  opacity: 1;
  white-space: nowrap;
  transition: transform .1s ease-in-out;
  transform: translateY(0);
  color: black;
}

.social-login-button {
  padding: 0;
  width: 55px;
  height: 44px;
  text-align: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  opacity: 1;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.social-login-content {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: .01;
  width: 40px;
  height: 40px;
}

.social-login-button img {
  width: 30px;
  height: 30px;
}

#slots4ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
  }
}

#slots5ImgContainer {
  height: 167px;

  .imgTitle {
    position: relative;
    top: -48px;
    left: 22px;
  }
}

#slots6ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots7ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots8ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots9ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots10ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots11ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots12ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots13ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots14ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots15ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots16ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots17ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

#slots18ImgContainer .imgTitle {
  position: relative;
  top: -48px;
  left: 22px;
}

.Bet365ImgContainer {
  height: 167px;
  position: relative;
}

.dailyBonus {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.dailyHeader15 {
  padding-top: 15px;
}

.dailyBackground {
  background-image: linear-gradient(to top, #000000, #1b1b1b, #303030, #464646, #5d5d5d);
  border-radius: 18px;
}

.dailyBackgroundImg {
  background-image: url("../../assets/image/dailyBackgroundImg.png");
  background-size: contain;
  background-position-x: right;
  background-repeat: no-repeat;
}

.dailyRow {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
}

.dailyVac {
  -webkit-box-align: center;
  align-items: center;
}

.dailyHeader25 {
  padding-top: 25px;
}

.viewRewards {
  font-size: 12px;
  font-weight: 500;
  color: white;
  padding: 10px 15px;
  background-color: #00000030;
  text-transform: uppercase;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.tal {
  text-align: left;
}

.col-5 {
  -webkit-box-flex: 0;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
  position: relative;
  min-height: 1px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-6 {
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}

.col {
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  min-height: 1px;
  width: 100%;
  padding-right: 25px;
  padding-left: 15px;
}

.tar {
  text-align: right;
}

.carouselStyle.bonusCarousel {
  .slick-track {
    float: left;
  }

  .slick-prev {
    display: block !important;
    z-index: 1;
    left: 0;
  }

  .slick-next {
    display: block !important;
    z-index: 1;
    right: 0;
  }

  .slick-list {
    margin: 0 -5px;
  }
}

.carouselStyle.bonusCarousel .slick-prev {
  left: 0px;
  top: 9%;
  height: 40px;
  width: 26px;
  position: absolute;
}

.carouselStyle.bonusCarousel .slick-next {
  right: 0px;
  top: 9%;
  height: 40px;
  width: 24px;
}

.bonusEnabled {
  background-image: linear-gradient(to right, #04be1c, #29c51c, #3dcc1c, #4dd21c, #5cd91c);
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  border: transparent;
  text-align: center;
  padding: 10px 15px;
  font-family: 'Work Sans', sans-serif;
}

.bonusDisabled {
  background-color: #fff;
  color: #777;
  font-weight: 500;
  border-radius: 10px;
  border: 2px solid #ededed;
  text-align: center;
  padding: 10px 15px;
  font-family: 'Work Sans', sans-serif;
}

.bonusTable {
  width: 100%;
  font-family: 'Work Sans', sans-serif;
  border-color: grey;
  border-collapse: initial;
}

.bonusTable tbody {
  border-color: inherit;
}

.bonusTable tr {
  text-align: center;
}

.bonusTable th {
  color: #fff;
  background-color: #00000024;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
}

.bonusTable td {
  color: #7f7f7f;
  background-color: #0000000a;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 0;
}

.carouselStyle.bonusCarousel.rise .slick-prev:before {
  left: 0px;
  top: 25%;
  height: 40px;
  width: 26px;
  position: absolute;
  color: #000;
}

.carouselStyle.bonusCarousel.rise .slick-next:before {
  right: 0px;
  top: 9%;
  height: 40px;
  width: 24px;
  color: #000;
}

.rise-page {
  .unclickRise {
    color: white;
    font-weight: 500;
    background-image: linear-gradient(to right, #04be1c, #29c51c, #3dcc1c, #4dd21c, #5cd91c);
    border-radius: 5px;
    width: 80%;
    padding: 5px;
  }
}

.money-conversion-button-retrieve {
  line-height: 40px !important;
  height: 40px !important;
  border-radius: 7px !important;
  color: var(--theme-color) !important;
  border: 2px solid var(--theme-color);
  font-size: 30px;
  width: calc(50% - 5px);
  margin-left: 5px;
  margin-bottom: 5px;
  background: #fff;
  // float:left;
  width: 100%;
}

.money-conversion-button-refresh {
  line-height: 40px !important;
  height: 40px !important;
  border-radius: 7px !important;
  color: #fff !important;
  border: 2px solid var(--theme-color);
  font-size: 30px;
  width: calc(50% - 5px);
  margin-left: 5px;
  margin-bottom: 5px;
  // float:left;
  width: 100%;
}

.fav_container {
  position: relative;
}

.fav_image {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 5px;
  display: block;
  height: 25px;
  width: 25px;
  z-index: 1;
}

.imgContainer1 {
  position: relative;
  z-index: 3;
}

.fixed {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 50px;
  background-color: #fff;
  z-index: 9999;
}

// zhonghe user center 
.zhongheuserface {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.zhongheuserface a {
  color: black !important;
}

.zhongheuserface .user-img {
  padding-bottom: 54px;
  background-image: none !important;
  margin-top: 10px;
}

.zhongheuserface .dropdownBtn {
  margin: 5%;
  width: 100%;
}

.zhongheuserface .dropdownBtn span {
  float: left;
  font-weight: bold;
  font-size: 14px;
}

.zhongheuserface .dropdownBtn div {
  width: 90%;
}

.zhongheuserface .dropdownBtn .dropdownArrow {
  line-height: 30px;
  float: right;
  margin-top: 4px;
}

.zhongheBigBox {
  margin: 0px 20px;
  color: white;
  border-radius: 5px;
  padding: 5px 15px;
  background-color: rgba(255, 255, 255, 0.1);
}

.zhongheSmallBox span {
  color: white;
}

.zhongheSmallBox div {
  border-radius: 5px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
}

.zhongheuserface .navbar-container {
  text-transform: none !important;
}

.zhongheuserface .active-link .underline {
  background-color: #178BFF !important;
  width: 20% !important;
}

.zhongheuserface .active-link a {
  color: black !important;
}

.zhongheuserface .navbar-container ul li {
  font-size: 12px !important;
}

.slick-track {
  float: left;
}

#sportDiv {
  #ds88ImgContainerTitle {
    top: -43px !important;
    position: relative;
    left: 22px;
    font-size: 14px;
  }

  .ds88ImgContainer {
    padding-top: 3px;
    height: 167px;
  }
  #walityImgContainerTitle {
    top: -47px !important;
    position: relative;
    left: 22px;
    font-size: 14px;
  }

  .walityImgContainer {
    padding-top: 3px;
    height: 167px;
  }
}

#walivdImgContainerTitle {
  top: -47px !important;
  position: relative;
  left: 22px;
  font-size: 14px;
}

.walivdImgContainer {
  padding-top: 3px;
  height: 167px;
}

#affiliation-controller {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px -6px 0;
  overflow-x: auto;
  margin-bottom: 0px;
  padding: 0 10px;
  height: 65px;
  width: 100vw;
}

#affiliation-controller1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px -6px 0;
  overflow-x: auto;
  margin-bottom: 0px;
  padding: 0 10px;
  height: 65px;
  width: 100vw;
}

#affiliation-controller .pill-nav {
  border-radius: 100px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  padding: 0 11px;
  margin: 0 6px;
  cursor: pointer;
  min-width: max-content;
  background: #202020;
  border: 1px solid #333333;
  color: #8E8E8E;
}

#affiliation-controller1 .pill-nav {
  border-radius: 100px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  margin: 0 5px;
  cursor: pointer;
  min-width: -webkit-max-content;
  min-width: max-content;
  background: #202020;
  border: 1px solid #333;
  color: #8e8e8e;
  font-size: 14px;
}

#affiliation-controller .pill-nav.selected {
  background: #4368ff;
  color: #ffffff;
}

#affiliation-controller1 .pill-nav.selected {
  background: #4368ff;
  color: #ffffff;
}

#invite_paragraph {
  color: #fff;
  text-align: left;
  font-size: 12px;
  padding: 10px 15px;
}

#refferal_link_pic {
  display: flex;
  flex-direction: row;
}

#refferal_link_pic img {
  width: 25px;
  margin-right: 10px;
}

#invite_paragraph2 {
  color: white;
  margin: 25px 10px;
  background-image: linear-gradient(#0f1725, #0f2c51);
  border-radius: 5px;
}

#invite_paragraph2 h4 {
  text-align: center;
  font-weight: bold;
  color: #395ad9;
}

#invite_paragraph2 div {
  text-align: left;
  margin: 25px;
}

#invite_paragraph2 p {
  color: #8E8E8E;
}

#invite_paragraphCommissionModalRules {
  color: #fff;
  margin: 25px 0px 35px 0px;
  border-radius: 5px;
}

#invite_paragraphCommissionModalRules h4 {
  text-align: left;
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
  padding-left: 12px;
}

#invite_paragraphCommissionModalRules div {
  text-align: left;
  margin: 25px 15px;
}

#invite_paragraphCommissionModalRules p {
  color: #8E8E8E;
}

.subTitle {
  font-weight: bold;
  text-align: left;
  font-size: 17px !important;
}

.moreInfo {
  float: right;
  color: #3f63de;
  text-decoration: underline;
  font-size: 17px !important;
}

.commissionModalRulesSubTitle {
  font-size: 17px !important;
  font-weight: 500;
  text-align: left;
}

.commissionModalRulesMoreInfo {
  color: #fff;
  background: #3f63de;
  padding: 10px 15px;
  border-radius: 40px;
  font-size: 13px;
  margin-bottom: 10px;
  text-align: right;
}

#invite_paragraph3 {
  color: white;
  margin: 25px 10px;
  background-image: linear-gradient(#0f1725, #0f2c51);
  border-radius: 5px;
  text-align: center;
}

#invite_paragraphExclusiveAffiliate {
  color: #fff;
  margin: 25px 0px;
  padding: 0px 15px 10px 15px;
  background-image: linear-gradient(#0f1725, #0f2c51);
  border-radius: 5px;
}

#invite_paragraph3 span {
  font-size: 20px;
  font-weight: bold;
}

#invite_paragraphExclusiveAffiliate span {
  font-weight: 400;
  color: #ffffff;
  font-size: 17px;
  text-align: left;
}

#invite_paragraph3 p {
  margin: 25px;
  text-align: left;
}

#invite_paragraphExclusiveAffiliate p {
  font-size: 14px;
  color: #8E8E8E;
  margin: 15px 0px;
  text-align: left;
}

#invite_paragraphExclusiveAffiliate table {
  border: 1px solid #ffffffa3;
  padding: 10px;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 16px;
}

.affiliateProgramTab {
  color: white;
  text-align: center;
  margin: 25px;
}

.affiliateProgramTab span {
  font-size: 20px;
  font-weight: bold;
}

.affiliateProgramTab1 {
  color: #fff;
  margin: 25px 15px;
}

.affiliateProgramTab1 span {
  font-size: 19px;
  font-weight: 400;
  text-align: left;
}

.affiliateProgramTab p {
  color: #8E8E8E;
  text-align: left;
}

.affiliateProgramTab1 p {
  color: #8E8E8E;
  text-align: left;
}

.affiliateProgramTab1MoreInfo {
  color: #fff;
  background: #3f63de;
  padding: 10px 15px;
  border-radius: 40px;
  font-size: 13px;
  margin-bottom: 15px;
}

.affiliateProgramTab1 table {
  border: 1px solid #ffffffa3;
  padding: 10px;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 16px;
}

.affiliateProgramTab div {
  text-align: left;
}

.affiliationDatePicker {
  display: flex;
  align-items: baseline;
}

.affiliationDatePicker1 {
  display: flex;
  align-items: baseline;
}

.affiliationDatePicker>div.react-datepicker-wrapper {
  margin: 10px;
}

.affiliationDatePicker1>div.react-datepicker-wrapper {
  margin: 10px;
}

.affiliationDatePicker .affiliationInput {
  background-color: #1F2938;
  color: #8E8E8E;
  height: 35px;
  border-radius: 8px;
}

.affiliationDatePicker1 .affiliationInput1 {
  background-color: #ffffff1f;
  color: #ffffff;
  height: 35px;
  border-radius: 8px;
  padding: 0px 10px;
  font-size: 15px;
}

.affiliateSearchButton {
  background-image: linear-gradient(#4368FF, #223480);
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 8px;
  margin-right: 5px;
  white-space: nowrap;
}

.affiliateSearchButton1 {
  background-image: linear-gradient(#4368ff, #223480);
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 0 5px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 300;
  width: 25%;
}

.affiliationDateSelect {
  display: flex;
}

.affiliateReportBox {
  background-image: linear-gradient(#09234F, #0F2D53);
  color: white;
  margin: 19px 20px;
  padding: 25px 40px;
  border-radius: 8px;
}

.affiliateReportBox1 {
  background-image: linear-gradient(#09234f, #0f2d53);
  color: #fff;
  margin: 15px 20px;
  padding: 25px 25px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.affiliateReportBox span {
  font-size: 20px;
}

.reportWord {
  color: #4368FF;
}

.reportWord1 {
  color: #ffffff;
  margin-right: 60px;
}

.reportFigure {
  float: right;
}

.affiliateSearchBar {
  width: 95%;
  background-color: #2e2e2e;
  color: #8E8E8E;
  border: none;
  border-radius: 8px;
  height: 45px;
  margin: 0 auto;
}

.dark-list-block {
  margin: 5px 0;
  background-color: #202020;
}

.dark-list-block1 {
  background-color: #202020;
}

.dark-data-table {
  font-size: 14px;
}

.dark-data-table table {
  width: 100%;
  text-align: left;
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 15px;
  text-align: center;
  table-layout: fixed;
}

.dark-data-table th,
.dark-data-table td {
  padding: 15px 5px;
  border-bottom: 1px solid #8E8E8E;
  font-weight: normal;
  color: #8E8E8E;
}

.dark-data-table td {
  padding: 20px 5px;
  color: #fff;
}

.dark-data-table1 {
  font-size: 14px;
}

.dark-data-table1 table {
  width: 100%;
  text-align: left;
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 15px;
  text-align: center;
  table-layout: fixed;
}

.dark-data-table1 th,
.dark-data-table1 td {
  padding: 15px 5px;
  border-bottom: 1px solid #ededed;
  font-weight: 300;
  color: #6b6b6b;
  font-size: 15px;
  text-transform: uppercase;
}

.dark-data-table1 td {
  padding: 20px 5px;
  color: #fff;
}

.dark-data-table1 th {
  text-align: inherit;
  text-align: -webkit-match-parent;
  font-weight: 600 !important;
  background: #f6f6f6;
}

.pixPayType {
  height: 36px;
  padding: 5px;
}

.pixPayType:hover {
  background-color: #eaeff2;
}

.scroll-table-total {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  .agent-team-table{
    width: max-content;
  }

  .agent-team-table th{
    width: 100px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}

.custom-telegram-login{
  width: 40px;
  height: 40px;
}

.redeem-code-module {

  display: flex;
	justify-content: center;
	align-items: center;
	background-image: url("../../assets/image/redeem_mobile_background.jpg");
	background-repeat: no-repeat;
	background-size: cover;
  flex-direction: column;
  height: 100vh;
  margin-top: -30px;

  .redeem-code-title{
    color: #FFE3BB;
    text-align: center;
    font-size: 40px;
    text-shadow: 0px 2px 4px #A56134;
  }

  .redeem-control {
    border: none;
    border-radius: 5px;
    padding: 8px 8px;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 70%;
    color: white;
  }
  
  .redeem-input-control {
    background-color: #4F4F4F;
    text-align: center;
  }

  .redeem-input-control::placeholder {
    color: white;
  }
  
  .redeem-button-control {
    background-color: #4368FF;
  }
}

  .redeem-code-image{
    width: 80%;
  }

.promoHead .modal-title{
    width: 100%;
    display: flex;
  }

.promoContent .modal-content{
     background: #E5E4E4;
  }

.theme-red {
   .navbar-container ul li {
    display: inline-block;
    width: 100%;
    font-size: 11px;
    color: white !important;
  }

  .navbar-container .active-link a {
    color: white!important;
    opacity: 0.6;
  }

  .navbar-container {
    background: var(--theme-color) !important;
  }

  .game-rules-page .mdc-tab__text-label {
    line-height: 16px;    
  }
  
}

.affiliation-tab {
  background: rgb(22, 22, 22) !important;
  padding: 10px;
  text-transform: uppercase;
}
.affiliation-tab ul {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  vertical-align: middle;
  padding: 0 10px;
  align-items: center;
}
.affiliation-tab ul li {
  display: inline-block;
  width: 100%;
  font-size: 11px;
  color: white !important;
}
.affiliation-tab ul li a {
  display: flex;
  padding: 10px;
  transition: color 0.5s;
  justify-content: center;
  font-size: 14px;
}
.affiliation-tab ul li .underline {
  height: 3px;
  background: transparent;
  width: 0%;
  transition: width 0.2s, background-color 0.5s;
  margin: 0 auto;
}
.affiliation-tab .active-link a {
  color: white !important;
}

.affiliation-tab ul .active-link .underline {
  background-color: #4165F5 !important;
  width: 100%;
}
.report_info p {
  margin-bottom: 0.3rem;
}

.bottom-navigation-backlight {
  position: absolute;
  z-index: 1;
  mix-blend-mode: lighten;
}
.icon-home1, .icon-Gift, .icon-Support, .icon-profile {
  z-index: 2;
}

.royal-user-center{
  .DataTotal-top dt {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: bold !important;
  }
  .DataTotal-top dd {
    margin: 6px 0 0;
    padding: 0;
    font-size: 12px;
    color: #999;
    font-size: 14px;
    font-weight: bold !important;
  }
  .DataTotal-sub dd {
    margin: 6px 0 0;
    padding: 0;
    font-size: 12px;
    color: #999;
    height: 30px;
    font-size: 14px;
    font-weight: bold !important;
}
}