@font-face {
	font-family: 'materialIcon2';
	src:  url('../../assets/fonts/materialIcon2.eot?oflil7');
	src:  url('../../assets/fonts/materialIcon2.eot?oflil7#iefix') format('embedded-opentype'),
	  url('../../assets/fonts/materialIcon2.ttf?oflil7') format('truetype'),
	  url('../../assets/fonts/materialIcon2.woff?oflil7') format('woff'),
	  url('../../assets/fonts/materialIcon2.svg?oflil7#materialIcon2') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }
  
  [class^="materialIcon2-"], [class*=" materialIcon2-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'materialIcon2' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
  }
  
  .materialIcon2-icon2-copy-01:before {
	content: "\e900";
  }